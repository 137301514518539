import { styled } from '@mui/system'

export const Banner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.info.light,
  padding: theme.spacing(2)
}))

export const BannerText = styled('span')(({ theme }) => ({
  color: theme.palette.info.dark
}))

export const BannerActions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1)
}))
