import React from 'react'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { shape } from 'prop-types'
import { UnicodeEmojiSelector } from './UnicodeEmojiSelector'
import { TooltipIcon } from '../TooltipIcon'
import { pollProps } from '../prop-types'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
})

export function SliderOptions({ poll, validationErrors }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <TextField
        id="leftText"
        label="Text left"
        sx={{ marginRight: 1 }}
        defaultValue={poll.sliderOptions && poll.sliderOptions.leftText}
        error={!!validationErrors.leftText}
        helperText={validationErrors.leftText}
      />
      <UnicodeEmojiSelector defaultValue={poll.sliderOptions && poll.sliderOptions.emoji} />
      <TextField
        id="rightText"
        label="Text right"
        sx={{ margin: 1 }}
        defaultValue={poll.sliderOptions && poll.sliderOptions.rightText}
        error={!!validationErrors.rightText}
        helperText={validationErrors.rightText}
      />
      <TooltipIcon
        content={
          <>
            The slider emoji is drawn using the emojis in the user&#39;s browser so it may look
            slightly different in different environments.
          </>
        }
      />
    </div>
  )
}

SliderOptions.propTypes = {
  poll: pollProps.isRequired,
  validationErrors: shape({}).isRequired
}
