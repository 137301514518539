import React from 'react'
import { Typography } from '@mui/material'
import { node } from 'prop-types'

export function ErrorTypography({ children, ...props }) {
  return (
    <Typography align="center" sx={{ color: 'error.main', fontSize: '.75rem' }} {...props}>
      {children}
    </Typography>
  )
}

ErrorTypography.propTypes = {
  children: node.isRequired
}
