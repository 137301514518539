import { TextField } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

const Margin = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2)
}))

export function PaddedTextField(props) {
  return (
    <Margin>
      <TextField {...props} variant="filled" />
    </Margin>
  )
}
