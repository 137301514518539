import { cloneDeep } from 'lodash'
import * as actions from '../actions/action-types'
import { POLL } from '../constants/poll-settings-options'

export default function (state, action) {
  switch (action.type) {
    case actions.POLLS_REQUEST: {
      return Object.freeze({
        ...state,
        polls: [],
        isLoading: true
      })
    }
    case actions.POLLS_SUCCESS: {
      return Object.freeze({
        ...state,
        polls: action.polls,
        chatId: action.chatId,
        isLoading: false
      })
    }
    case actions.POLLS_ERROR: {
      return Object.freeze({
        ...state,
        isLoading: false,
        isUpdating: false
      })
    }
    case actions.POLL_CREATE_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_UPDATE_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_CREATE_UPDATE_SUCCESS: {
      return Object.freeze({
        ...state,
        polls: replaceOnePoll(state.polls, action.poll),
        isUpdating: false
      })
    }
    case actions.POLL_OPEN_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_CLOSE_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_OPEN_SUCCESS: {
      return Object.freeze({
        ...state,
        polls: setPollOpenOrClosed(state.polls, action.pollId, true),
        isUpdating: false
      })
    }
    case actions.POLL_CLOSE_SUCCESS: {
      return Object.freeze({
        ...state,
        polls: setPollOpenOrClosed(state.polls, action.pollId, false),
        isUpdating: false
      })
    }
    case actions.POLL_STATS_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_STATS_SUCCESS: {
      return Object.freeze({
        ...state,
        isUpdating: false,
        pollStats: action.resultsResponse
      })
    }
    case actions.POLL_RESULTS_REQUEST: {
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    }
    case actions.POLL_RESULTS_SUCCESS: {
      return Object.freeze({
        ...state,
        isUpdating: false,
        polls: addResultsToPoll(state.polls, action.resultsResponse)
      })
    }
    default:
      return state
  }
}

function setPollOpenOrClosed(polls, pollId, isOpenEvent) {
  const newPolls = cloneDeep(polls)
  const pollIdx = newPolls.findIndex(poll => poll.id === pollId)
  if (isOpenEvent) {
    newPolls[pollIdx].isOpened = true
  } else {
    newPolls[pollIdx].isClosed = true
  }
  return newPolls
}

function replaceOnePoll(polls, newPoll) {
  const newPolls = cloneDeep(polls)
  const replaceIdx = newPolls.findIndex(poll => poll.id === newPoll.id)
  if (replaceIdx >= 0) {
    newPolls[replaceIdx] = newPoll
    return newPolls
  }
  return [...newPolls, newPoll]
}

function addResultsToPoll(polls, resultsResponse) {
  const newPolls = cloneDeep(polls)
  const pollIdx = newPolls.findIndex(poll => poll.id === resultsResponse.pollId)

  if (pollIdx < 0) {
    return newPolls
  }

  const poll = newPolls[pollIdx]
  poll.results =
    poll.pollType === POLL ? convertPollResponseResults(resultsResponse) : resultsResponse
  return newPolls
}

function convertPollResponseResults(resultsResponse) {
  const totalCount = resultsResponse.pollResults.reduce((count, result) => count + result.count, 0)
  return resultsResponse.pollResults.map(result => ({
    option: result.option,
    count: result.count,
    percentage: percentage(result.count, totalCount)
  }))
}

function percentage(count, totalCount) {
  return totalCount > 0 ? ((count / totalCount) * 100).toPrecision(3) : '0'
}
