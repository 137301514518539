import { cloneDeep } from 'lodash'
import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.CHATS_REQUEST:
      return Object.freeze({
        ...state,
        params: action.params,
        isLoading: true
      })
    case actions.CHATS_SUCCESS:
      return Object.freeze({
        ...state,
        isLoading: false,
        chats: addNewChats(state.chats, action.chats, action.params.offset)
      })
    case actions.CHATS_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false,
        chats: []
      })
    case actions.CHAT_CREATE_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.CHAT_UPDATE_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.CHAT_UPDATE_SUCCESS:
      return Object.freeze({
        ...state,
        isUpdating: false,
        chats: updateChats(state.chats, action.chat),
        currentChat: action.chat,
        writersFromDB: action.chat.writers,
        writerEmails: [],
        isTainted: false
      })
    case actions.CHAT_UPDATE_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false
      })
    case actions.CHAT_ARCHIVE_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.CHAT_ARCHIVE_SUCCESS:
      return Object.freeze({
        ...state,
        chats: removeChat(state.chats, action.chatId),
        currentChat: undefined,
        isUpdating: false,
        isTainted: false
      })
    case actions.CHAT_ARCHIVE_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false
      })
    case actions.CHAT_REQUEST:
      return Object.freeze({
        ...state,
        isLoading: true,
        currentChat: undefined,
        writersFromDB: [],
        writerEmails: [],
        isTainted: false
      })
    case actions.CHAT_SUCCESS:
      return Object.freeze({
        ...state,
        isLoading: false,
        currentChat: action.chat,
        writersFromDB: action.chat.writers,
        writerEmails: []
      })
    case actions.CHAT_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false,
        currentChat: undefined,
        writerEmails: [],
        writersFromDB: []
      })
    case actions.CHAT_ADD_WRITER:
      return Object.freeze({
        ...state,
        writerEmails: appendWriters(state.writerEmails, action.email)
      })
    case actions.CHAT_REMOVE_WRITER:
      return Object.freeze({
        ...state,
        writerEmails: removeWriter(state.writerEmails, action.writer),
        writersFromDB: removeWriter(state.writersFromDB, action.writer)
      })
    case actions.CHAT_RESET_WRITERS:
      return Object.freeze({
        ...state,
        writerEmails: [],
        writersFromDB: []
      })
    case actions.CHAT_TAINT:
      return Object.freeze({
        ...state,
        isTainted: true
      })
    case actions.CHAT_UNTAINT:
      return Object.freeze({
        ...state,
        isTainted: false
      })
    default:
      return state
  }
}

export function addNewChats(oldChats, newChats, offset) {
  return offset === 0 ? newChats : [...oldChats, ...newChats]
}

function updateChats(chats, newChat) {
  const updated = cloneDeep(chats)
  const idx = updated.findIndex(chat => chat.id === newChat.id)
  if (idx >= 0) {
    updated[idx] = newChat
  } else {
    updated.push(newChat)
  }
  return updated.sort((x, y) => Date.parse(y.startTime) - Date.parse(x.startTime))
}

function removeChat(chats, chatId) {
  return chats.filter(chat => chat.id !== chatId)
}

function appendWriters(writers, writerEmail) {
  if (!writers) {
    return [{ email: writerEmail }]
  }
  return [...writers, { email: writerEmail }]
}

function removeWriter(writers, writerToRemove) {
  return writers.filter(writer => writer !== writerToRemove)
}
