import { put, select, takeLatest } from 'redux-saga/effects'
import { CHAT_REQUEST, CHAT_STATS_REQUEST, CHATS_REQUEST } from '../actions/action-types'
import {
  chatErrorAction,
  chatSuccessAction,
  chatsErrorAction,
  chatsSuccessAction
} from '../actions/chats-actions'
import { getChat, getChats, getChatStats } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'
import { chatStatsErrorAction, chatStatsSuccessAction } from '../actions/chat-actions'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'

export function* watchGetChats() {
  yield takeLatest(CHATS_REQUEST, getChatsSaga)
}

function* getChatsSaga({ params }) {
  try {
    const config = yield select(configSelector)
    const chats = yield getChats(config, params)
    yield put(chatsSuccessAction({ chats, params }))
    if (params.offset > 0 && chats.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No more chats to load' }))
    } else if (chats.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No chats found with given parameters' }))
    }
  } catch (error) {
    yield put(chatsErrorAction({ error }))
  }
}

export function* watchGetChat() {
  yield takeLatest(CHAT_REQUEST, getChatSaga)
}

function* getChatSaga({ chatId, navigateToChats }) {
  try {
    const config = yield select(configSelector)
    const chat = yield getChat(config, chatId)
    yield put(chatSuccessAction({ chat }))
  } catch (error) {
    yield put(chatErrorAction({ error }))
    navigateToChats()
  }
}

export function* watchGetChatStats() {
  yield takeLatest(CHAT_STATS_REQUEST, getChatStatsSaga)
}

function* getChatStatsSaga({ chatId }) {
  try {
    const config = yield select(configSelector)
    const stats = yield getChatStats(config, chatId)
    yield put(chatStatsSuccessAction({ chatId, stats }))
  } catch (error) {
    yield put(chatStatsErrorAction({ error }))
  }
}
