import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tab, Tabs, TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Search } from '@mui/icons-material'
import { styled } from '@mui/system'
import startOfDay from 'date-fns/startOfDay'
import addDays from 'date-fns/addDays'
import { func, instanceOf, string } from 'prop-types'
import {
  bannedUsersSelector,
  isLoadingSelector,
  isUpdatingSelector
} from '../selectors/banned-selector'
import { Navigation } from '../components/Navigation/Navigation'
import { BannedUsersList } from '../components/BannedUsersList/BannedUsersList'
import { bannedUsersRequestAction } from '../actions/banned-users-actions'
import { datesToApiFormat } from '../utils/date-utils'

const TAB_ALL = 'all'
const TAB_1_DAY = '1d'
const TAB_3_WEEKS = '3w'

const tabToDate = tab => {
  switch (tab) {
    case TAB_1_DAY:
      return startOfDay(new Date())
    case TAB_3_WEEKS:
      return startOfDay(addDays(new Date(), -20))
    default:
      return null
  }
}

const SearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap'
})

const SearchField = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(1),
  width: '170px'
}))

function SearchBar({ nick, bannedAfter, bannedBefore, onSearch }) {
  const [nickLocal, setNickLocal] = useState(null)
  const [bannedAfterLocal, setBannedAfterLocal] = useState(null)
  const [bannedBeforeLocal, setBannedBeforeLocal] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    setBannedAfterLocal(bannedAfter)
  }, [bannedAfter])

  useEffect(() => {
    setBannedBeforeLocal(bannedBefore)
  }, [bannedBefore])

  useEffect(() => {
    if (
      bannedAfterLocal !== bannedAfter ||
      bannedBeforeLocal !== bannedBefore ||
      nickLocal !== nick
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [bannedAfter, bannedBefore, nick, bannedAfterLocal, bannedBeforeLocal, nickLocal])

  return (
    <SearchContainer>
      <SearchField placeholder="Nick" onChange={e => setNickLocal(e.target.value)} size="small" />
      <DatePicker
        id="banned-after"
        label="Banned after"
        value={bannedAfterLocal}
        disableMaskedInput
        onChange={timestamp => setBannedAfterLocal(timestamp ? startOfDay(timestamp) : null)}
        renderInput={inputParams => <SearchField size="small" {...inputParams} />}
      />
      <DatePicker
        id="banned-before"
        label="Banned before"
        value={bannedBeforeLocal}
        disableMaskedInput
        onChange={timestamp => setBannedBeforeLocal(timestamp ? startOfDay(timestamp) : null)}
        renderInput={inputParams => <SearchField size="small" {...inputParams} />}
      />
      <Button
        variant="contained"
        onClick={() =>
          onSearch({
            nick: nickLocal,
            bannedAfter: bannedAfterLocal,
            bannedBefore: bannedBeforeLocal
          })
        }
        startIcon={<Search />}
        disabled={isDisabled}
      >
        Search
      </Button>
    </SearchContainer>
  )
}

SearchBar.propTypes = {
  nick: string,
  bannedAfter: instanceOf(Date),
  bannedBefore: instanceOf(Date),
  onSearch: func.isRequired
}

SearchBar.defaultProps = {
  nick: null,
  bannedAfter: null,
  bannedBefore: null
}

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2)
}))

export function BannedUsersPage() {
  const bannedUsers = useSelector(bannedUsersSelector)
  const isLoading = useSelector(isLoadingSelector)
  const isUpdating = useSelector(isUpdatingSelector)
  const [tab, setTab] = useState(TAB_ALL)
  const [params, setParams] = useState({
    offset: 0,
    limit: 20,
    nick: null,
    moderator: null,
    bannedAfter: null,
    bannedBefore: null
  })
  const dispatch = useDispatch()

  const onLoadBannedUsers = p => dispatch(bannedUsersRequestAction({ params: p }))
  const onLoadMore = more =>
    setParams({ ...params, offset: params.offset + (more ? params.limit : -params.limit) })
  const onSearch = ({ nick, bannedAfter, bannedBefore }) => {
    if (bannedBefore || bannedAfter) {
      setTab(TAB_ALL)
    }
    setParams({ ...params, offset: 0, nick, bannedAfter, bannedBefore })
  }
  const onSetTab = (_, val) => {
    setTab(val)
    setParams({ ...params, offset: 0, bannedBefore: tabToDate(val), bannedAfter: null })
  }

  useEffect(() => {
    onLoadBannedUsers(datesToApiFormat(params))
  }, [params])

  return (
    <Navigation titleText="Banned users">
      <Row>
        <Tabs value={tab} onChange={onSetTab}>
          <Tab value={TAB_ALL} label="All" />
          <Tab value={TAB_1_DAY} label={'\u2265 1 Day ago'} />
          <Tab value={TAB_3_WEEKS} label={'\u2265 3 Weeks ago'} />
        </Tabs>
        <SearchBar
          nick={params.nick}
          bannedBefore={params.bannedBefore}
          bannedAfter={params.bannedAfter}
          onSearch={onSearch}
        />
      </Row>
      <BannedUsersList
        bannedUsers={bannedUsers}
        isLoading={isLoading}
        isUpdating={isUpdating}
        tableActions={{
          onPrev: () => onLoadMore(false),
          onNext: () => onLoadMore(true),
          isPrevDisabled: params.offset === 0,
          isNextDisabled: (bannedUsers || []).length < params.limit
        }}
      />
    </Navigation>
  )
}
