import React from 'react'
import { FormSectionTitle } from './FormSectionTitle'
import { chatProps } from '../../prop-types'
import { PaddedTextField } from './PaddedTextField'

export function SharingFields({ chat }) {
  const tooltipContent = (
    <>
      <b>Social media title: </b>
      Title to show in social media previews. If empty, will use chat title.
      <br />
      <b>Social media description: </b>
      Description to show in social media previews. If empty, will use chat description.
      <br />
    </>
  )

  return (
    <>
      <FormSectionTitle text="Social media settings" tooltipContent={tooltipContent} />
      <PaddedTextField
        id="ogTitle"
        label="Social media title"
        fullWidth
        defaultValue={chat.ogTitle}
      />
      <PaddedTextField
        id="ogDescription"
        label="Social media description"
        fullWidth
        multiline
        rows={3}
        defaultValue={chat.ogDescription}
        variant="outlined"
      />
    </>
  )
}

SharingFields.propTypes = {
  chat: chatProps.isRequired
}
