import React, { useState } from 'react'
import { IconButton, Tooltip, Snackbar } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { string } from 'prop-types'

const CopyToClipboard = ({ text }) => {
  const [open, setOpen] = useState(false)
  const copyToClipboard = txt => {
    setOpen(true)
    navigator.clipboard.writeText(txt)
  }

  return (
    <>
      <Tooltip title="Copy message id to clipboard">
        <IconButton size="small" onClick={() => copyToClipboard(text)}>
          <ContentCopy />
        </IconButton>
      </Tooltip>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard!"
      />
    </>
  )
}

CopyToClipboard.propTypes = {
  text: string.isRequired
}
export default CopyToClipboard
