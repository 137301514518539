import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_ERROR,
  EDIT_USER_REQUEST,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR
} from './action-types'

export function getUsersRequestAction({ params, userType }) {
  return {
    type: GET_USERS_REQUEST,
    params,
    userType
  }
}

export function getUsersSuccessAction({ users }) {
  return {
    type: GET_USERS_SUCCESS,
    users
  }
}

export function getUsersErrorAction({ error }) {
  return {
    type: GET_USERS_ERROR,
    users: [],
    error
  }
}

export function addUserRequestAction({ email, isOperator, isModerator, onCloseDialog }) {
  return {
    type: ADD_USER_REQUEST,
    email,
    isOperator,
    isModerator,
    onCloseDialog
  }
}

export function addUserSuccessAction({ user }) {
  return {
    type: ADD_USER_SUCCESS,
    user
  }
}

export function addUserErrorAction({ error }) {
  return {
    type: ADD_USER_ERROR,
    error
  }
}

export function removeUserRequestAction({ users, userType, onCloseDialog }) {
  return {
    type: REMOVE_USER_REQUEST,
    users,
    userType,
    onCloseDialog
  }
}

export function removeUserSuccessAction({ ids }) {
  return {
    type: REMOVE_USER_SUCCESS,
    ids
  }
}

export function removeUserErrorAction({ error }) {
  return {
    type: REMOVE_USER_ERROR,
    error
  }
}

export function editUserRequestAction({ user, isOperator, isModerator, onCloseDialog }) {
  return {
    type: EDIT_USER_REQUEST,
    user,
    isOperator,
    isModerator,
    onCloseDialog
  }
}

export function editUserSuccessAction({ id, isOperator, isModerator }) {
  return {
    type: EDIT_USER_SUCCESS,
    id,
    isOperator,
    isModerator
  }
}

export function editUserErrorAction({ error }) {
  return {
    type: EDIT_USER_ERROR,
    error
  }
}
