import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.EMOJI_REQUEST:
      return Object.freeze({
        ...state,
        isLoading: true
      })
    case actions.EMOJI_SUCCESS:
      return Object.freeze({
        ...state,
        isLoading: false,
        emojis: action.emojis
      })
    case actions.EMOJI_ADD:
      return Object.freeze({
        ...state,
        emojis: addEmoji(state.emojis, action.emoji)
      })
    case actions.EMOJI_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false,
        emojis: []
      })
    default:
      return state
  }
}

function addEmoji(emojis, emoji) {
  const newEmojis = emojis.filter(e => e.imsId !== emoji.imsId)
  newEmojis.push(emoji)
  return newEmojis.sort((a, b) => a.name.localeCompare(b.name))
}
