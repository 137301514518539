import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { func, bool } from 'prop-types'
import { commentProps } from '../prop-types'

const useStyles = makeStyles(({ palette, spacing }) => ({
  authorText: {
    fontWeight: 600
  },
  quote: {
    borderLeft: [[spacing(0.5), 'solid', palette.primary.main]],
    paddingLeft: spacing(2),
    marginBottom: spacing(1)
  }
}))

export function QuoteModal({ isOpen, handleClose, comment }) {
  const classes = useStyles()
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Comment & quote</DialogTitle>
      <DialogContent className={classes.quote}>
        <DialogContentText className={classes.authorText}>
          {comment && comment.quote.author}
        </DialogContentText>
        <DialogContentText>{comment && comment.quote.content}</DialogContentText>
        <Divider />
      </DialogContent>
      <DialogContent>
        <DialogContentText className={classes.authorText}>
          {comment && comment.author}
        </DialogContentText>
        <DialogContentText>{comment && comment.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

QuoteModal.defaultProps = {
  comment: null
}

QuoteModal.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  comment: commentProps
}
