import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'
import { func, bool } from 'prop-types'
import { commentProps } from '../prop-types'

export function ConfirmationModal({ reject, isOpen, handleSubmit, handleClose, comment }) {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{reject ? 'Reject comment?' : 'Accept comment?'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{comment && comment.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {reject ? 'Reject' : 'Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationModal.defaultProps = {
  comment: null
}

ConfirmationModal.propTypes = {
  reject: bool.isRequired,
  isOpen: bool.isRequired,
  handleSubmit: func.isRequired,
  handleClose: func.isRequired,
  comment: commentProps
}
