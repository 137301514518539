export const userSelector = state => state.user.user
export const isModeratorSelector = state =>
  Boolean(
    state.user.user &&
      (state.user.user.isModerator || state.user.user.isOperator || state.user.user.isSuperOperator)
  )
export const isOperatorSelector = state =>
  Boolean(state.user.user && (state.user.user.isOperator || state.user.user.isSuperOperator))
export const isSuperOperatorSelector = state =>
  Boolean(state.user.user && state.user.user.isSuperOperator)
export const isLoadingSelector = state => state.user.isLoading
