import React, { cloneElement, useCallback, useState } from 'react'
import { node, string, arrayOf } from 'prop-types'
import { Menu } from '@mui/material'

export function PopupMenu({ children, id, items }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const onOpen = useCallback(
    event => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget)
      }
    },
    [setAnchorEl]
  )
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl])

  const childrenProps = {
    ...children.props,
    'aria-owns': anchorEl ? id : undefined,
    'aria-haspopup': true,
    onClick: onOpen,
    onMouseOver: onOpen
  }
  return (
    <>
      {cloneElement(children, childrenProps)}
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        MenuListProps={{ onMouseLeave: onClose }}
      >
        {items}
      </Menu>
    </>
  )
}

PopupMenu.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  items: arrayOf(node).isRequired
}
