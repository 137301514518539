import React, { useState } from 'react'
import { Button, Card, CardContent, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { pollProps } from '../prop-types'
import { BarChart } from './BarChart'

const useStyles = makeStyles(theme => ({
  withMargin: {
    marginBottom: theme.spacing(3)
  },
  rawData: {
    maxWidth: '23.5em',
    overflow: 'scroll'
  }
}))

export function SliderResults({ poll }) {
  const classes = useStyles()

  const [isRawDataVisible, setIsRawDataVisible] = useState(false)

  return (
    <div>
      <Card className={classes.withMargin}>
        <CardContent>
          <BarChart poll={poll} />
        </CardContent>
      </Card>
      <Card className={classes.withMargin}>
        <CardContent>
          <Typography>
            {`Responses: ${poll.results.pollResults.count}`}
            <br />
            {`Mean: ${poll.results.pollResults.average}`}
          </Typography>
        </CardContent>
      </Card>
      <Button
        className="showHide"
        variant="outlined"
        color="primary"
        onClick={() => setIsRawDataVisible(!isRawDataVisible)}
      >
        {isRawDataVisible ? 'Hide raw data' : 'Show raw data'}
      </Button>
      {isRawDataVisible && (
        <Card className={classes.rawData}>
          <CardContent>
            <Typography>{JSON.stringify(poll.results.pollResults.results)}</Typography>
          </CardContent>
        </Card>
      )}
    </div>
  )
}

SliderResults.propTypes = {
  poll: pollProps.isRequired
}
