const GRAVITY = 'faces:auto'
const CROP = 'fill'
const ASPECT_RATIO = '16:9'
const AVATAR_ASPECT_RATIO = '1:1'
const HEIGHT = '232'
const AVATAR_HEIGHT = '40'
const MENU_HEIGHT = '108'
const FORMAT = 'jpg'

export function parseAltText(altTexts) {
  if (!altTexts || altTexts.length === 0) {
    return 'IMS image has no alt text'
  }
  return altTexts.map(alt => `${alt.language}: ${alt.value}`).join(', ')
}

function buildUrl(imagesApiUrl, imageId, height, crop, aspectRatio, gravity, format) {
  const fitting = `h_${height},c_${crop},ar_${aspectRatio},g_${gravity}`
  return imageId && `${imagesApiUrl}/${fitting}/f_${format}/${imageId}`
}

export function buildAvatarUrl(imagesApiUrl, imageId) {
  return buildUrl(imagesApiUrl, imageId, AVATAR_HEIGHT, CROP, AVATAR_ASPECT_RATIO, GRAVITY, FORMAT)
}

export function buildMenuAvatarUrl(imagesApiUrl, imageId) {
  return buildUrl(imagesApiUrl, imageId, MENU_HEIGHT, CROP, AVATAR_ASPECT_RATIO, GRAVITY, FORMAT)
}

export function buildImageUrl(imagesApiUrl, imageId) {
  return buildUrl(imagesApiUrl, imageId, HEIGHT, CROP, ASPECT_RATIO, GRAVITY, FORMAT)
}

export function buildEmojiPath(imageId, version, crop) {
  const cropping = `c_crop,x_${crop.x},y_${crop.y},w_${crop.width},h_${crop.height}`
  const sizing = `h_72,w_72,ar_1:1`
  const format = `f_png`
  return `${cropping}/${sizing}/${format}/v${version}/${imageId}`
}
