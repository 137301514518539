import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.CHAT_STATS_REQUEST: {
      return Object.freeze({
        ...state,
        isLoading: true,
        stats: {}
      })
    }
    case actions.CHAT_STATS_SUCCESS: {
      return Object.freeze({
        ...state,
        isLoading: false,
        stats: action.stats,
        chatId: action.chatId
      })
    }
    case actions.CHAT_STATS_ERROR: {
      return Object.freeze({
        ...state,
        isLoading: false,
        stats: {}
      })
    }
    default:
      return state
  }
}
