import React from 'react'
import { Card, Table, TableBody, TableContainer, TableRow, TableCell } from '@mui/material'
import { oneOfType, number, string } from 'prop-types'
import { chatStatsProps } from '../prop-types'
import { GridItemTitle } from './GridItemTitle'

export function CommentsStats({ stats }) {
  return (
    <Card>
      <GridItemTitle text="Comments" />
      <TableContainer>
        <Table>
          <TableBody>
            <StatsRow title="Total" value={stats.commentsTotal} />
            <StatsRow title="Accepted" value={stats.commentsAcceptedTotal} />
            <StatsRow title="Rejected" value={stats.commentsRejectedTotal} />
            <StatsRow title="Queued" value={stats.commentsQueued} />
            <StatsRow title="Commenters" value={stats.commentersTotal} />
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

function StatsRow({ title, value }) {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell align="right">{value}</TableCell>
    </TableRow>
  )
}

CommentsStats.propTypes = {
  stats: chatStatsProps.isRequired
}

StatsRow.defaultProps = {
  value: '\u2013'
}

StatsRow.propTypes = {
  title: string.isRequired,
  value: oneOfType([string, number])
}
