import React, { forwardRef, useState } from 'react'
import { Box, IconButton } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { func, string } from 'prop-types'
import { styled } from '@mui/system'
import { emojiProps } from '../../prop-types'

const Img = styled('img')`
  width: 36px;
  height: 36px;
`

export const Emoji = forwardRef(({ emoji, imagesApiUrl, onClick, onDelete, ...props }, ref) => {
  const src = `${imagesApiUrl}/${emoji.imsPath}`
  const [showDelete, setShowDelete] = useState(false)
  const sx = { p: '2px', width: '40px', height: '40px' }
  if (onClick) {
    return (
      <IconButton ref={ref} {...props} sx={sx} onClick={onClick}>
        <Img src={src} alt={emoji.name} />
      </IconButton>
    )
  }
  if (onDelete) {
    return (
      <Box
        ref={ref}
        {...props}
        sx={{ ...sx, position: 'relative' }}
        onMouseOver={() => setShowDelete(true)}
        onMouseLeave={() => setShowDelete(false)}
      >
        <Img src={src} alt={emoji.name} />
        {showDelete && (
          <IconButton
            size="small"
            sx={{ position: 'absolute', top: '-20px', right: '-20px' }}
            onClick={onDelete}
          >
            <Cancel />
          </IconButton>
        )}
      </Box>
    )
  }
  return (
    <Box ref={ref} {...props} sx={sx}>
      <Img src={src} alt={emoji.name} />
    </Box>
  )
})

Emoji.propTypes = {
  emoji: emojiProps.isRequired,
  imagesApiUrl: string.isRequired,
  onClick: func,
  onDelete: func
}

Emoji.defaultProps = {
  onClick: null,
  onDelete: null
}
