import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { Card } from '@mui/material'
import {
  isUpdatingSelector,
  writersFromDBSelector,
  writerEmailsSelector,
  isTaintedSelector
} from '../../selectors/chats-selector'
import { ChatEditForm } from './ChatEditForm'
import {
  chatUpdateRequestAction,
  chatArchiveRequestAction,
  chatTaint
} from '../../actions/chat-actions'
import { chatProps } from '../prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  card: {
    minWidth: '10em',
    maxWidth: '55em',
    marginTop: theme.spacing(2)
  }
}))

export function ChatEditTab({ chat }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isUpdating = useSelector(isUpdatingSelector)
  const isTainted = useSelector(isTaintedSelector)
  const writersFromDB = useSelector(writersFromDBSelector)
  const writerEmails = useSelector(writerEmailsSelector)

  const onSubmit = updatedChat => {
    dispatch(chatUpdateRequestAction({ chat: updatedChat }))
  }

  const onArchive = (chatId, navigateToChats) => {
    dispatch(chatArchiveRequestAction({ chatId, navigateToChats }))
  }

  const onTaint = () => {
    if (!isTainted) {
      dispatch(chatTaint())
    }
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <ChatEditForm
          onSubmit={onSubmit}
          onArchive={onArchive}
          dispatch={dispatch}
          isUpdating={isUpdating}
          isNew={false}
          chat={chat}
          writersFromDB={writersFromDB}
          writerEmails={writerEmails}
          onChange={onTaint}
        />
        <Prompt
          when={isTainted}
          message="You may have unsaved modifications. Are you sure you want to leave?"
        />
      </Card>
    </div>
  )
}

ChatEditTab.propTypes = {
  chat: chatProps.isRequired
}
