import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { styled } from '@mui/system'
import {
  moderateStartStatePollingAction,
  moderateStopStatePollingAction,
  moderateStopAction,
  moderateGetComments
} from '../../actions/moderate-actions'
import { CommentList } from './CommentList'
import { TopBar } from './TopBar'
import { commentsSelector, moderationStateSelector } from '../../selectors/moderate-selector'
import { chatProps } from '../prop-types'
import { ResetDialog } from './ResetButton'

const Content = styled('div')({
  display: 'flex',
  maxWidth: '110rem',
  alignItems: 'flex-start',
  flexDirection: 'column'
})

export function Moderate({ chat }) {
  const dispatch = useDispatch()
  const comments = useSelector(commentsSelector, shallowEqual)
  const moderationState = useSelector(moderationStateSelector, shallowEqual)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isModerating = useSelector(state => state.moderate.moderationState?.userIsModerating)

  useEffect(() => {
    if (chat.id) {
      dispatch(moderateStartStatePollingAction({ chatId: chat.id }))
      dispatch(moderateGetComments({ chatId: chat.id }))
    }
    return () => {
      if (chat.id) {
        dispatch(moderateStopStatePollingAction())
        dispatch(moderateStopAction({ chatId: chat.id }))
      }
    }
  }, [])

  return (
    <>
      <Content>
        <TopBar
          state={moderationState}
          chatId={chat.id}
          setIsModalOpen={setIsModalOpen}
          numberOfComments={comments.length}
        />
        {isModerating ? <CommentList comments={comments} chatId={chat.id} /> : null}
        <ResetDialog chatId={chat.id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Content>
    </>
  )
}

Moderate.propTypes = {
  chat: chatProps.isRequired
}
