/* istanbul ignore file */
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CssBaseline } from '@mui/material'

import { store } from './store'
import { Routes } from './Routes'
import { initializeUserAction } from './actions/user-actions'
import { SnackbarProvider } from './components/SnackBar/SnackbarProvider'
import { Snackbar } from './components/SnackBar/snackbar'
import { MuiThemeProvider } from './components/MuiThemeProvider'
import { APP_LOCALE, DateFnsLocales } from './utils/localization'
import { chatUntaint } from './actions/chat-actions'

const getConfirmation = (message, callback) => {
  // eslint-disable-next-line no-alert
  if (window.confirm(message)) {
    store.dispatch(chatUntaint())
    callback(true)
  } else {
    callback(false)
  }
}

export function App() {
  useEffect(() => {
    store.dispatch(initializeUserAction())
  }, [])

  return (
    <Provider store={store}>
      <MuiThemeProvider>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={DateFnsLocales[APP_LOCALE]}>
          <SnackbarProvider>
            <BrowserRouter getUserConfirmation={getConfirmation}>
              <Routes />
              <Snackbar />
            </BrowserRouter>
          </SnackbarProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </Provider>
  )
}
