/* istanbul ignore file */

export const routes = {
  chatList: '/chat-list',
  chat: '/:chatId',
  users: '/users',
  bannedUsersList: '/banned-users'
}

export const chatRoutes = {
  settings: '/settings',
  moderation: '/moderation',
  polls: '/polls',
  stats: '/stats',
  messages: '/messages'
}
