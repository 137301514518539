import React from 'react'
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography
} from '@mui/material'
import { number, string } from 'prop-types'
import { makeStyles } from '@mui/styles'
import { chatStatsProps } from '../prop-types'
import { GridItemTitle } from './GridItemTitle'
import { TableHeaderCell } from '../TableHeaderCell'

const useStyles = makeStyles(theme => ({
  commentCell: {
    maxWidth: theme.spacing(50)
  }
}))

export function MostLikedComments({ stats }) {
  return (
    <Card>
      <GridItemTitle text="Most liked comments" />
      {!stats.mostLikedComments || stats.mostLikedComments.length === 0 ? (
        <Typography sx={{ padding: 1 }} color="textSecondary">
          No likes
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Nick</TableHeaderCell>
                <TableHeaderCell>Likes</TableHeaderCell>
                <TableHeaderCell>Comment</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stats.mostLikedComments.map(comment => (
                <CommentRow
                  key={comment.nick + comment.content.substring(1, 8)}
                  nick={comment.nick}
                  comment={comment.content}
                  likesCount={comment.likesCount}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  )
}

function CommentRow({ nick, comment, likesCount }) {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell>{nick}</TableCell>
      <TableCell align="right">{likesCount}</TableCell>
      <TableCell className={classes.commentCell}>{comment}</TableCell>
    </TableRow>
  )
}

MostLikedComments.propTypes = {
  stats: chatStatsProps.isRequired
}

CommentRow.propTypes = {
  nick: string.isRequired,
  comment: string.isRequired,
  likesCount: number.isRequired
}
