import React from 'react'
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { func } from 'prop-types'
import { makeStyles } from '@mui/styles'
import { writerProps } from '../../prop-types'
import { AvatarImsPicker } from '../ImsPicker/AvatarImsPicker'

const useStyles = makeStyles(() => ({
  unsavedWriter: {
    fontStyle: 'italic'
  },
  unsavedTooltip: {
    fontSize: 14
  }
}))

export function WriterListItem({ writer, removeWriter }) {
  const classes = useStyles()
  const isUnsaved = writer.email !== undefined
  const className = isUnsaved ? classes.unsavedWriter : null
  const identifier = isUnsaved ? writer.email : writer.nick

  return (
    <ListItem>
      <ListItemAvatar>
        {isUnsaved ? (
          <Tooltip
            title={<span className={classes.unsavedTooltip}>Save chat before adding avatar</span>}
          >
            <Avatar>?</Avatar>
          </Tooltip>
        ) : (
          <AvatarImsPicker
            image={writer.avatar && { id: writer.avatar }}
            id={writer.id}
            alt={writer.nick}
          />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={identifier}
        secondary={isUnsaved ? 'unsaved' : ''}
        className={className}
      />
      <ListItemSecondaryAction>
        <IconButton
          className="removeWriter"
          edge="end"
          aria-label="Remove writer"
          onClick={() => removeWriter(writer)}
        >
          <ClearIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

WriterListItem.propTypes = {
  writer: writerProps.isRequired,
  removeWriter: func.isRequired
}
