/* istanbul ignore file */

import { reactionIcons } from './reaction-icons'

export const chatThemes = [
  { id: 'chat', label: 'Chat' },
  { id: 'umk', label: 'UMK' },
  { id: 'abitreenit', label: 'Abitreenit' }
]

export const reactionEmojis = [
  {
    id: 'raised-hands',
    src: reactionIcons.raisedHands
  },
  {
    id: 'clap',
    src: reactionIcons.clap
  },
  {
    id: 'muscle',
    src: reactionIcons.muscle
  },
  {
    id: 'metal',
    src: reactionIcons.metal
  },
  {
    id: 'like',
    src: reactionIcons.like
  },
  {
    id: 'fire',
    src: reactionIcons.fire
  },
  {
    id: 'party',
    src: reactionIcons.party
  },
  {
    id: 'hundred',
    src: reactionIcons.hundred
  },
  {
    id: 'heart',
    src: reactionIcons.heart
  },
  {
    id: 'partying-face',
    src: reactionIcons.partyingFace
  },
  {
    id: 'sparkles',
    src: reactionIcons.sparkles
  },
  {
    id: 'medal',
    src: reactionIcons.medal
  },
  {
    id: 'football',
    src: reactionIcons.football
  },
  {
    id: 'ice-hockey',
    src: reactionIcons.iceHockey
  },
  {
    id: 'trophy',
    src: reactionIcons.trophy
  },
  {
    id: 'crown',
    src: reactionIcons.crown
  },
  {
    id: 'peace',
    src: reactionIcons.peace
  },
  {
    id: 'world',
    src: reactionIcons.world
  },
  {
    id: 'pride',
    src: reactionIcons.pride
  },
  {
    id: 'finland',
    src: reactionIcons.finland
  },
  {
    id: 'zzz',
    src: reactionIcons.zzz
  },
  {
    id: 'bingo',
    src: reactionIcons.bingo
  },
  {
    id: 'twelve',
    src: reactionIcons.twelve
  },
  {
    id: 'kuumotus',
    src: reactionIcons.kuumotus
  },
  {
    id: 'tonni',
    src: reactionIcons.tonni
  },
  {
    id: 'duck',
    src: reactionIcons.duck
  },
  {
    id: 'kalle',
    src: reactionIcons.kalle
  },
  {
    id: 'kjell',
    src: reactionIcons.kjell
  },
  {
    id: 'shiba',
    src: reactionIcons.shiba
  },
  {
    id: 'tunna',
    src: reactionIcons.tunna
  },
  {
    id: 'kaarija',
    src: reactionIcons.kaarija
  },
  {
    id: 'aleksib',
    src: reactionIcons.aleksib
  },
  {
    id: 'whippet',
    src: reactionIcons.whippet
  },
  {
    id: 'jacklumber',
    src: reactionIcons.jackLumber
  },
  {
    id: 'piamia',
    src: reactionIcons.piaMia
  },
  {
    id: 'wyslowskij',
    src: reactionIcons.wyslowskij
  },
  {
    id: 'niinisto',
    src: reactionIcons.niinisto
  }
]
