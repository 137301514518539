import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogContent } from '@mui/material'
import { bool, func } from 'prop-types'
import { configSelector } from '../../../selectors/config-selector'
import { getImageMetadata } from '../../../api/json-storage-api'
import { getImageError } from '../../../actions/image-actions'

export function ImsDialog({ isOpen, onImage, onClose }) {
  const config = useSelector(configSelector)
  const dispatch = useDispatch()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    async function messageListener(message) {
      if (message.origin === config.imsUrl) {
        const { images_api_id: id } = message.data
        if (id) {
          const metadata = await getImageMetadata(config, id)
          if (metadata.error) {
            onImage(id, {})
            dispatch(getImageError({ message: 'Saved image without metadata' }))
          } else {
            onImage(id, metadata)
          }
        } else {
          onImage(id, {})
          dispatch(getImageError({ message: 'Could not fetch image from IMS' }))
        }
      }
    }

    if (isOpen) {
      window.addEventListener('message', messageListener, false)
      const imsWindow = window.open(config.imsUrl)
      const checker = setInterval(() => {
        if (!imsWindow || imsWindow.closed) {
          clearInterval(checker)
        }
      }, 100)
      return () => {
        imsWindow.close()
        clearInterval(checker)
        window.removeEventListener('message', messageListener)
      }
    }
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent>IMS was opened to a new window.</DialogContent>
    </Dialog>
  )
}

ImsDialog.propTypes = {
  isOpen: bool.isRequired,
  onImage: func.isRequired,
  onClose: func.isRequired
}
