import React, { useState } from 'react'
import { shape } from 'prop-types'
import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FormSectionTitle } from './FormSectionTitle'
import { chatProps } from '../../prop-types'
import { PaddedTextField } from './PaddedTextField'
import { landerOptions } from '../../../constants/lander-options'
import { FullWidthContainer } from './FullWidthContainer'

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  select: {
    marginBottom: theme.spacing(2)
  }
}))

export function LanderFields({ chat, validationErrors }) {
  const classes = useStyles()

  const [lander, setLander] = useState(chat.lander || landerOptions[0].id)

  const toolTipContent = (
    <>
      <b>Lander: </b>
      On which chat lander page (if any) this chat should be displayed
      <br />
      <b>URL Path: </b>
      The chat will be available at chat.yle.fi/path
      <br />
      <b>Program ID: </b>
      Program id of the Areena video to be shown in the chat in the format 1-1234567
      <br />
    </>
  )

  return (
    <>
      <FormSectionTitle text="Chat page settings" tooltipContent={toolTipContent} />
      <FullWidthContainer>
        <FormControl className={classes.input} variant="filled">
          <InputLabel id="lander-select-label">Lander</InputLabel>
          <Select
            name="lander"
            labelId="lander-select-label"
            value={lander}
            placeholder="Choose landing page"
            className={classes.select}
            onChange={event => setLander(event.target.value)}
          >
            {landerOptions.map(landerOption => (
              <MenuItem key={landerOption.id} value={landerOption.id}>
                {landerOption.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <PaddedTextField
          id="path"
          placeholder="your_custom_url_here"
          label="Url path"
          fullWidth
          className={classes.input}
          defaultValue={chat.path}
          error={!!validationErrors.path}
          helperText={validationErrors.path}
          InputProps={{
            startAdornment: <InputAdornment position="start">chat.yle.fi/</InputAdornment>
          }}
        />
        <PaddedTextField
          id="programId"
          placeholder="Add areena video program id"
          label="Program id"
          fullWidth
          defaultValue={chat.programId || ''}
          error={!!validationErrors.programId}
          helperText={validationErrors.programId}
          className={classes.input}
        />
      </FullWidthContainer>
    </>
  )
}

LanderFields.defaultProps = {
  validationErrors: {}
}

LanderFields.propTypes = {
  chat: chatProps.isRequired,
  validationErrors: shape({})
}
