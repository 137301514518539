import React, { useState } from 'react'
import { bool, func } from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material'
import { Add, Cancel, Save } from '@mui/icons-material'
import { useDispatch, useSelector } from 'react-redux'
import { useImsPicker } from './ImsPicker'
import { buildEmojiPath } from './utils'
import { ChatFormButton } from '../FieldSets/ChatFormButton'
import { Emoji } from '../FieldSets/Emoji'
import { saveEmoji } from '../../../api/backend-api'
import { configSelector } from '../../../selectors/config-selector'
import { imageProps } from '../../prop-types'
import { emojiAddAction } from '../../../actions/emoji-actions'
import { enqueueErrorSnackbarAction } from '../../../actions/snackbar-actions'

function EmojiEditor({ image, open, onCancel, onSave }) {
  const { imagesApiUrl } = useSelector(configSelector)
  const [name, setName] = useState('')
  const emoji = image && {
    id: '',
    name,
    imsId: image.id,
    imsPath: buildEmojiPath(image.id, image.version, image.crop)
  }
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Add emoji</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {emoji && <Emoji emoji={emoji} imagesApiUrl={imagesApiUrl} />}
        <TextField label="Name" value={name} onChange={e => setName(e.currentTarget.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" startIcon={<Cancel />}>
          Cancel
        </Button>
        <Button
          onClick={() => onSave(emoji)}
          color="primary"
          variant="contained"
          startIcon={<Save />}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EmojiEditor.defaultProps = {
  image: undefined
}

EmojiEditor.propTypes = {
  image: imageProps,
  open: bool.isRequired,
  onCancel: func.isRequired,
  onSave: func.isRequired
}

export function EmojiImsPicker({ selectEmoji }) {
  const dispatch = useDispatch()
  const [currentImage, setCurrentImage] = useState()

  const onPickImage = image => {
    if (image.crop) {
      setCurrentImage(image)
    } else {
      dispatch(enqueueErrorSnackbarAction({ error: { message: "Image doesn't have a 1:1 crop." } }))
    }
  }

  const { dialog, openPicker } = useImsPicker(onPickImage)
  const config = useSelector(configSelector)

  const onSave = async emoji => {
    const savedEmoji = await saveEmoji(config, emoji)
    dispatch(emojiAddAction({ emoji: savedEmoji }))
    selectEmoji(savedEmoji.id)
    setCurrentImage(undefined)
  }

  return (
    <Box sx={{ pb: 2 }}>
      <ChatFormButton variant="contained" onClick={openPicker} startIcon={<Add />}>
        Add reaction emoji from IMS
      </ChatFormButton>

      <EmojiEditor
        open={Boolean(currentImage)}
        image={currentImage}
        onSave={onSave}
        onCancel={() => setCurrentImage(undefined)}
      />
      {dialog}
    </Box>
  )
}

EmojiImsPicker.propTypes = {
  selectEmoji: func.isRequired
}
