/* istanbul ignore file */

/**
 * See unified (?) Yle News Lab Colors:
 * - https://www.figma.com/file/wQxLoponMcSViZy5xkbYUP/Ukko-style-guide?node-id=7%3A0
 * - https://ukko.test.yle.fi/storybook/?path=/story/fundamentals-colors--color-palette
 */

export const BLACK = '#110f24'
export const WHITE = '#ffffff'
export const LIVE_PINK = '#DF1663'

export const colorTones = {
  normal: 'normal',
  light: 'light',
  lighter: 'lighter',
  dark: 'dark',
  darker: 'darker'
}

export const palette = {
  black: {
    normal: BLACK,
    dark: BLACK,
    darker: BLACK,
    light: BLACK,
    lighter: BLACK,
    contrast: WHITE
  },
  white: {
    normal: WHITE,
    dark: WHITE,
    darker: WHITE,
    light: WHITE,
    lighter: WHITE,
    contrast: BLACK
  },
  grey: {
    normal: '#d3d7da',
    dark: '#3b3e48',
    darker: BLACK,
    light: '#edf0f3',
    lighter: '#f6f6f9',
    contrast: WHITE
  },
  turquoise: {
    normal: '#05bdd0',
    dark: '#03a0c1',
    darker: '#146b94',
    light: '#6fdde7',
    lighter: '#d8f2f1',
    contrast: WHITE
  },
  blue: {
    normal: '#2b7ac8',
    dark: '#1c5b98',
    darker: '#0c3a66',
    light: '#4ba6ff',
    lighter: '#bbdeff',
    contrast: WHITE
  },
  purple: {
    normal: '#6531e9',
    dark: '#3325a7',
    darker: '#221a64',
    light: '#8879fc',
    lighter: '#cac4ff',
    contrast: WHITE
  },
  red: {
    normal: '#df1663',
    dark: '#a30d43',
    darker: '#59091a',
    light: '#e95c7b',
    lighter: '#ffcfd9',
    contrast: WHITE
  },
  green: {
    normal: '#18a972',
    dark: '#3B873E',
    darker: '#0a4423',
    light: '#5dc389',
    lighter: '#bbf6d4',
    contrast: WHITE
  },
  yellow: {
    normal: '#ffbb38',
    dark: '#b38327',
    darker: '#8e6403',
    light: '#ffcf74',
    lighter: '#ffe4af',
    contrast: WHITE
  }
}
