import NotFoundError from '../errors/NotFoundError'

export function getJson(config, endpoint, extraParams = '') {
  return fetchJson(config, endpoint, { method: 'GET' }, extraParams)
}

export function fetchJsonWithBody(config, endpoint, body, method = 'POST') {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if (body) {
    options.body = JSON.stringify(body)
  }
  return fetchJson(config, endpoint, options)
}

function isJSON(response) {
  return (
    response.headers.has('content-type') &&
    response.headers.get('content-type').startsWith('application/json')
  )
}

async function getMessage(response) {
  try {
    if (isJSON(response)) {
      const body = await response.json()
      return body.notifications[0].message
    }
    return await response.text()
  } catch (e) {
    return undefined
  }
}

export async function fetchJson(
  { apiAppId, apiAppKey, livefeedBackendUrl },
  endpoint,
  options,
  extraParameters = ''
) {
  const url = `${livefeedBackendUrl}/${endpoint}?app_id=${apiAppId}&app_key=${apiAppKey}${extraParameters}`

  const request = {
    ...options,
    credentials: 'include'
  }

  let response
  try {
    response = await fetch(url, request)
  } catch (e) {
    throw new Error('Could not connect to Chat Backend. Check your network connection.', {
      cause: e
    })
  }
  if (response.status === 404) {
    throw new NotFoundError(`${endpoint} not found`)
  } else if (response.status === 401) {
    throw new Error(
      `Call to ${endpoint} failed: unauthorized. Your session might be expired, try reloading the page.`
    )
  } else if (response.status >= 400) {
    const message = (await getMessage(response)) || '[No error message]'
    throw new Error(`Call to ${endpoint} failed with ${response.status}: ${message}`)
  }
  return response.json()
}
