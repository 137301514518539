import { put, select, takeLatest } from 'redux-saga/effects'
import {
  CHAT_CREATE_REQUEST,
  CHAT_UPDATE_REQUEST,
  CHAT_ARCHIVE_REQUEST
} from '../actions/action-types'
import { configSelector } from '../selectors/config-selector'
import {
  chatUpdateErrorAction,
  chatUpdateSuccessAction,
  chatArchiveErrorAction,
  chatArchiveSuccessAction
} from '../actions/chat-actions'
import { createChat, updateChat, archiveChat } from '../api/backend-api'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'

export function* watchUpdateChat() {
  yield takeLatest([CHAT_CREATE_REQUEST, CHAT_UPDATE_REQUEST], updateChatSaga)
}

function* updateChatSaga({ chat, type }) {
  try {
    const isNew = type === CHAT_CREATE_REQUEST
    const config = yield select(configSelector)
    const updatedChat = isNew ? yield createChat(config, chat) : yield updateChat(config, chat)
    yield put(chatUpdateSuccessAction({ chat: updatedChat }))
    const successMessage = isNew ? 'Chat created' : 'Chat info updated'
    yield put(enqueueSuccessSnackbarAction({ message: successMessage }))
  } catch (error) {
    yield put(chatUpdateErrorAction({ error }))
  }
}

export function* watchArchiveChat() {
  yield takeLatest([CHAT_ARCHIVE_REQUEST], archiveChatSaga)
}

function* archiveChatSaga({ chatId, navigateToChats }) {
  try {
    const config = yield select(configSelector)
    yield archiveChat(config, chatId)
    yield put(chatArchiveSuccessAction({ chatId }))
    const successMessage = 'Chat archived'
    yield put(enqueueSuccessSnackbarAction({ message: successMessage }))
    navigateToChats()
  } catch (error) {
    yield put(chatArchiveErrorAction({ error }))
  }
}
