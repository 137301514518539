import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Menu } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { node, string, func } from 'prop-types'
import { useSelector } from 'react-redux'
import { userSelector } from '../../selectors/user-selector'
import { GoogleAvatar } from './GoogleAvatar'

const useStyles = makeStyles({
  grow: {
    flexGrow: 1
  }
})

export function TopBar({ titleText, titleItem, onClickMenu }) {
  const user = useSelector(userSelector)
  const classes = useStyles()

  return (
    <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' } }}
          color="inherit"
          size="large"
          onClick={onClickMenu}
          aria-label="Open drawer"
        >
          <Menu />
        </IconButton>
        {titleText && <Typography className={classes.grow}>{titleText}</Typography>}
        {titleItem}
        <GoogleAvatar user={user} />
      </Toolbar>
    </AppBar>
  )
}

TopBar.defaultProps = {
  titleText: '',
  titleItem: null
}

TopBar.propTypes = {
  titleText: string,
  titleItem: node,
  onClickMenu: func.isRequired
}
