import React, { useEffect, useState } from 'react'
import { bool, func, number } from 'prop-types'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { chatCreateRequestAction, chatResetWritersAction } from '../../actions/chat-actions'
import { ChatAddDialog } from '../ChatEditor/ChatAddDialog'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

export function ChatAddButton({ isUpdating, dispatch, chatsCount }) {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Close add dialog on successful save (i.e. when a new chat is added to list)
  useEffect(() => {
    setIsDialogOpen(false)
  }, [chatsCount])

  const handleSubmit = chat => {
    dispatch(chatCreateRequestAction({ chat }))
  }

  const addChat = () => {
    setIsDialogOpen(true)
    dispatch(chatResetWritersAction())
  }

  return (
    <>
      <Button variant="contained" className={classes.button} startIcon={<Add />} onClick={addChat}>
        NEW CHAT
      </Button>
      <ChatAddDialog
        handleSubmit={handleSubmit}
        handleClose={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
        isUpdating={isUpdating}
      />
    </>
  )
}

ChatAddButton.propTypes = {
  isUpdating: bool.isRequired,
  dispatch: func.isRequired,
  chatsCount: number.isRequired
}
