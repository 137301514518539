// Convert chat update form data (from a submit event) to the format expected by backend call
import { dateToApiFormat } from '../../utils/date-utils'
import { pathBlacklist } from '../../constants/path-blacklist'

const alphaNumericWithHyphenRegex = /^[a-z0-9-_]+$/
const programIdRegex = /^1-[0-9]+|^yle-[a-zA-Z0-9-_]+$/
const areenaProgramIdRegex = /^1-[0-9]+/
const areenaProgramIdError = 'Invalid program id (should be like 1-12345678)'
const programIdError = 'Invalid program id (should be like 1-12345678 or yle-something)'

export function eventToChat(event, writerEmails, writers, chatId) {
  const modified = [
    'title',
    'description',
    'programId',
    'path',
    'ogTitle',
    'ogDescription',
    'lander',
    'image',
    'language'
  ].reduce((obj, field) => {
    // eslint-disable-next-line no-param-reassign
    obj[field] = event.currentTarget[field].value.trim()
    return obj
  }, {})

  modified.isLocked = !event.currentTarget.isActive.checked

  modified.settings = {
    theme: event.currentTarget.theme.value,
    bestView: event.currentTarget.bestView.checked,
    feedReactions: getFeedReactions(event)
  }

  if (event.currentTarget.startTime.value) {
    modified.startTime = dateToApiFormat(new Date(event.currentTarget.startTime.value))
  }

  if (event.currentTarget.endTime.value) {
    modified.endTime = dateToApiFormat(new Date(event.currentTarget.endTime.value))
  } else {
    modified.endTime = null
  }

  if (modified.language === 'none') {
    modified.language = null
  }

  if (!modified.programId) {
    // Can't be an empty string or undefined, so set explicitly to null.
    modified.programId = null
  }

  if (chatId) {
    modified.id = chatId
  }

  const image = JSON.parse(event.currentTarget.image.value)
  if (image.id) {
    modified.image = image
  } else {
    delete modified.image
  }
  modified.writers = writers.map(writer => ({
    ...writer,
    avatar: event.currentTarget[`avatar-${writer.id}`].value || null
  }))
  modified.writerEmails = writerEmails.map(writer => writer.email.toLowerCase())

  modified.areenaProgramIds = getAreenaProgramIds(event)

  return modified
}

export function validate(chat) {
  const validation = {}
  if (!chat.title) {
    validation.title = "Title can't be empty"
  }
  if (!chat.description) {
    validation.description = "Description can't be empty"
  }
  if (chat.programId && !programIdRegex.test(chat.programId)) {
    validation.programId = programIdError
  }
  if (chat.path && !alphaNumericWithHyphenRegex.test(chat.path)) {
    validation.path = 'Can only contain lowercase letters, numbers and hyphens'
  }
  if (pathBlacklist.includes(chat.path)) {
    validation.path = 'This path name is reserved, please choose another'
  }
  if (!chat.startTime) {
    validation.startTime = 'Select start time'
  }
  if (chat.endTime && chat.startTime && new Date(chat.endTime) <= new Date(chat.startTime)) {
    validation.endTime = "End time can't be before start time"
  }
  if (chat.settings && chat.settings.feedReactions && chat.settings.feedReactions.length > 5) {
    validation.feedReactions = 'Maximum of 5 emojis allowed'
  }

  const areenaProgramIdsError = validateAreenaProgramIds(chat)
  if (areenaProgramIdsError && areenaProgramIdsError.filter(err => err !== null).length > 0) {
    validation.areenaProgramIds = areenaProgramIdsError
  }

  return validation
}

function getFeedReactions(event) {
  const rawValue = event.currentTarget.feedReactions.value
  return rawValue === '' ? [] : rawValue.split(',')
}

function getAreenaProgramIds(event) {
  function getIds(idx, acc) {
    const val = event.currentTarget[`areenaId_${idx}`]
    if (val !== undefined) {
      return getIds(idx + 1, [...acc, val.value.trim()])
    }
    return acc
  }

  return getIds(0, []).filter(val => val.length > 0)
}

function validateAreenaProgramIds(chat) {
  if (!chat.areenaProgramIds) {
    return null
  }

  return chat.areenaProgramIds.map(programId => {
    if (!areenaProgramIdRegex.test(programId)) {
      return areenaProgramIdError
    }
    if (chat.areenaProgramIds.filter(id => id === programId).length > 1) {
      return 'Duplicate id'
    }
    return null
  })
}
