import React from 'react'
import { Button } from '@mui/material'
import { bool, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { moderateStartAction, moderateStopAction } from '../../actions/moderate-actions'

export function StartStop({ chatId, isModerating }) {
  const dispatch = useDispatch()

  const handleStart = () => {
    dispatch(moderateStartAction({ chatId }))
  }

  const handleStop = () => {
    dispatch(moderateStopAction({ chatId }))
  }

  return isModerating ? (
    <Button onClick={handleStop} variant="contained">
      Stop moderating
    </Button>
  ) : (
    <Button onClick={handleStart} variant="contained">
      Start moderating
    </Button>
  )
}

StartStop.propTypes = {
  chatId: string.isRequired,
  isModerating: bool.isRequired
}
