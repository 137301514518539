/* istanbul ignore file */

import PropTypes from 'prop-types'
import React from 'react'
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'
import { IconButton, useTheme } from '@mui/material'
import { Close } from '@mui/icons-material'

const notistackRef = React.createRef()
const onButtonClick = key => () => {
  notistackRef.current.closeSnackbar(key)
}

export function SnackbarProvider({ children }) {
  const theme = useTheme()
  const textColor = theme.palette.primary.contrastText

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  }

  return (
    <NotistackSnackbarProvider
      ref={notistackRef}
      anchorOrigin={anchorOrigin}
      action={key => (
        <IconButton onClick={onButtonClick(key)}>
          <Close fontSize="small" style={{ color: textColor }} />
        </IconButton>
      )}
    >
      {children}
    </NotistackSnackbarProvider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired
}
