import { put, select, takeLatest } from 'redux-saga/effects'
import { configSelector } from '../selectors/config-selector'
import { pollCloseSuccess, pollOpenSuccess, pollsErrorAction } from '../actions/polls-actions'
import { closePoll, openPoll } from '../api/backend-api'
import { POLL_CLOSE_REQUEST, POLL_OPEN_REQUEST } from '../actions/action-types'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'

export function* watchUpdatePollStatus() {
  yield takeLatest([POLL_CLOSE_REQUEST, POLL_OPEN_REQUEST], updatePollStatusSaga)
}

function* updatePollStatusSaga({ poll, chatId, type }) {
  try {
    const config = yield select(configSelector)
    const updated =
      type === POLL_CLOSE_REQUEST
        ? yield closePoll(config, chatId, poll.id)
        : yield openPoll(config, chatId, poll.id)
    if (updated.opened) {
      yield put(pollOpenSuccess({ pollId: poll.id, chatId }))
      yield put(enqueueSuccessSnackbarAction({ message: 'Poll opened' }))
    }
    if (updated.closed) {
      yield put(pollCloseSuccess({ pollId: poll.id, chatId }))
      yield put(enqueueSuccessSnackbarAction({ message: 'Poll closed' }))
    }
  } catch (error) {
    yield put(pollsErrorAction({ error }))
  }
}
