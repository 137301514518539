import PropTypes from 'prop-types'
import React from 'react'
import { Dialog, DialogTitle } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ChatEditForm } from './ChatEditForm'
import { writersFromDBSelector, writerEmailsSelector } from '../../selectors/chats-selector'
import { usersSelector } from '../../selectors/user-list-selector'

export function ChatAddDialog({ isOpen, isUpdating, handleClose, handleSubmit }) {
  const dispatch = useDispatch()

  const writerOptions = useSelector(usersSelector)
  const writersFromDB = useSelector(writersFromDBSelector)
  const writerEmails = useSelector(writerEmailsSelector)

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Create new chat</DialogTitle>
      <ChatEditForm
        onSubmit={handleSubmit}
        isUpdating={isUpdating}
        isNew
        onCancel={handleClose}
        cancelText="Close"
        writerOptions={writerOptions}
        writersFromDB={writersFromDB}
        writerEmails={writerEmails}
        dispatch={dispatch}
      />
    </Dialog>
  )
}

ChatAddDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired
}
