import { put, select, takeLatest } from 'redux-saga/effects'
import { BANNED_USERS_REQUEST, UNBAN_USER_REQUEST } from '../actions/action-types'
import {
  bannedUsersSuccessAction,
  bannedUsersErrorAction,
  unbanUserSuccessAction,
  unbanUserErrorAction
} from '../actions/banned-users-actions'
import { getBannedUsers, unbanUser } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'

export function* watchGetBannedUsers() {
  yield takeLatest(BANNED_USERS_REQUEST, getBannedUsersSaga)
}

function* getBannedUsersSaga({ params }) {
  try {
    const config = yield select(configSelector)
    const bannedUsers = yield getBannedUsers(config, params)
    yield put(bannedUsersSuccessAction({ bannedUsers, params }))
    if (params.offset > 0 && bannedUsers.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No more banned users to load' }))
    } else if (bannedUsers.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No banned users found' }))
    }
  } catch (error) {
    yield put(bannedUsersErrorAction({ error }))
  }
}

export function* watchUnbanUser() {
  yield takeLatest(UNBAN_USER_REQUEST, unbanUserSaga)
}

function* unbanUserSaga({ userNicks, closeDialog }) {
  try {
    const config = yield select(configSelector)
    // eslint-disable-next-line no-restricted-syntax
    for (const userNick of userNicks) {
      yield unbanUser(config, userNick)
    }
    closeDialog()
    yield put(unbanUserSuccessAction({ userNicks }))
    const successMessage = `Users ${userNicks.join(', ')} unbanned`
    yield put(enqueueSuccessSnackbarAction({ message: successMessage }))
  } catch (error) {
    yield put(unbanUserErrorAction({ error }))
  }
}
