import { put, select, takeLatest } from 'redux-saga/effects'
import { POLL_CREATE_REQUEST, POLL_UPDATE_REQUEST } from '../actions/action-types'
import { configSelector } from '../selectors/config-selector'
import { pollCreateUpdateSuccess, pollsErrorAction } from '../actions/polls-actions'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'
import { createPoll, updatePoll } from '../api/backend-api'

export function* watchUpdatePoll() {
  yield takeLatest([POLL_CREATE_REQUEST, POLL_UPDATE_REQUEST], updatePollSaga)
}

function* updatePollSaga({ type, poll, chatId }) {
  try {
    const isNew = type === POLL_CREATE_REQUEST
    const config = yield select(configSelector)
    const updatedPoll = isNew
      ? yield createPoll(config, chatId, poll)
      : yield updatePoll(config, chatId, poll)
    yield put(pollCreateUpdateSuccess({ poll: updatedPoll, chatId }))
    const successMessage = isNew ? 'Poll created' : 'Poll updated'
    yield put(enqueueSuccessSnackbarAction({ message: successMessage }))
  } catch (error) {
    yield put(pollsErrorAction({ error }))
  }
}
