import React from 'react'
import { Delete } from '@mui/icons-material'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'
import { arrayOf, func, shape, string } from 'prop-types'
import { configSelector } from '../../../selectors/config-selector'
import { buildImageUrl, parseAltText } from './utils'
import { ChatFormButton } from '../FieldSets/ChatFormButton'

const ImageContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const Image = styled('img')(() => ({
  display: 'block',
  marginBottom: '16px'
}))

export function ImsImage({ imageId, altTexts, onDelete }) {
  const { imagesApiUrl } = useSelector(configSelector)

  return (
    <ImageContainer>
      <Image src={buildImageUrl(imagesApiUrl, imageId)} alt={parseAltText(altTexts)} />
      <div>
        <ChatFormButton
          onClick={onDelete}
          aria-label="Remove image"
          color="primary"
          startIcon={<Delete />}
        >
          Delete image
        </ChatFormButton>
      </div>
    </ImageContainer>
  )
}

ImsImage.defaultProps = {
  altTexts: null
}

ImsImage.propTypes = {
  imageId: string.isRequired,
  altTexts: arrayOf(
    shape({
      language: string,
      value: string
    })
  ),
  onDelete: func.isRequired
}
