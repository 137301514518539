import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.BANNED_USERS_REQUEST:
      return Object.freeze({
        ...state,
        isLoading: true
      })
    case actions.BANNED_USERS_SUCCESS:
      return Object.freeze({
        ...state,
        isLoading: false,
        bannedUsers: action.bannedUsers
      })
    case actions.BANNED_USERS_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false,
        error: action.error
      })
    case actions.UNBAN_USER_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.UNBAN_USER_SUCCESS:
      return Object.freeze({
        ...state,
        bannedUsers: state.bannedUsers.filter(user => !action.userNicks.includes(user.nick)),
        isUpdating: false
      })
    case actions.UNBAN_USER_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false
      })
    default:
      return state
  }
}
