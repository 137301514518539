import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Tabs, Tab, Button } from '@mui/material'
import { styled } from '@mui/system'
import { Add } from '@mui/icons-material'
import {
  usersSelector,
  isLoadingSelector,
  isUpdatingSelector
} from '../selectors/user-list-selector'
import {
  getUsersRequestAction,
  addUserRequestAction,
  removeUserRequestAction,
  editUserRequestAction
} from '../actions/user-list-actions'
import {
  isOperatorSelector,
  isSuperOperatorSelector,
  userSelector
} from '../selectors/user-selector'
import { Navigation } from '../components/Navigation/Navigation'
import { ConfirmDialog } from '../components/ConfirmDialog/ConfirmDialog'
import { UserTable } from '../components/UserTable/UserTable'
import { UserDialog } from '../components/UserTable/UserDialog'

const OPERATOR = 'operator'
const MODERATOR = 'moderator'

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap-reverse',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2)
}))

export function UsersPage() {
  const users = useSelector(usersSelector)
  const isLoading = useSelector(isLoadingSelector)
  const isUpdating = useSelector(isUpdatingSelector)
  const loggedInUser = useSelector(userSelector)
  const isOperator = useSelector(isOperatorSelector)
  const isSuperOperator = useSelector(isSuperOperatorSelector)
  const [editUser, setEditUser] = useState(null)
  const [removeUsers, setRemoveUsers] = useState(null)
  const [isAddingUser, setIsAddingUser] = useState(false)
  const [tab, setTab] = useState(OPERATOR)
  const [params, setParams] = useState({ offset: 0, limit: 20, userType: OPERATOR })
  const dispatch = useDispatch()

  const onSetTab = (_, val) => {
    setTab(val)
    setParams({
      offset: 0,
      limit: 20,
      userType: val
    })
  }

  const onLoadUsers = ({ offset, limit, userType }) =>
    dispatch(getUsersRequestAction({ params: { offset, limit }, userType }))

  const onLoadMore = more => {
    setParams({ ...params, offset: params.offset + (more ? params.limit : -params.limit) })
  }

  const onAddUser = user => {
    dispatch(addUserRequestAction({ ...user, onCloseDialog: () => setIsAddingUser(false) }))
  }

  const onRemoveRole = () => {
    dispatch(
      removeUserRequestAction({
        users: removeUsers,
        userType: tab,
        onCloseDialog: () => setRemoveUsers(null)
      })
    )
  }

  const onEditUser = user => {
    dispatch(
      editUserRequestAction({
        isOperator: user.isOperator,
        isModerator: user.isModerator,
        user: editUser,
        onCloseDialog: () => setEditUser(null)
      })
    )
  }

  useEffect(() => {
    onLoadUsers(params)
  }, [params])

  return (
    <Navigation titleText="Users">
      <Row>
        <Tabs value={tab} onChange={onSetTab}>
          <Tab value={OPERATOR} label="Operators" />
          <Tab value={MODERATOR} label="Moderators" />
        </Tabs>
        <Button variant="contained" onClick={() => setIsAddingUser(true)} startIcon={<Add />}>
          Add new user
        </Button>
      </Row>
      <UserTable
        users={users}
        hasRights={tab === MODERATOR ? isOperator : isSuperOperator}
        isLoading={isLoading}
        tableActions={{
          onPrev: () => onLoadMore(false),
          onNext: () => onLoadMore(true),
          isPrevDisabled: params.offset === 0,
          isNextDisabled: (users || []).length < params.limit
        }}
        onEdit={setEditUser}
        onDelete={setRemoveUsers}
      />
      <UserDialog
        title="Add new user"
        confirmText="Add"
        isOpen={Boolean(isAddingUser)}
        isUpdating={isUpdating}
        onClose={() => setIsAddingUser(false)}
        onConfirm={onAddUser}
        loggedInUser={loggedInUser}
      />
      <UserDialog
        title="Edit user"
        confirmText="Edit"
        isOpen={Boolean(editUser)}
        isUpdating={isUpdating}
        onClose={() => setEditUser(null)}
        onConfirm={onEditUser}
        user={editUser}
        loggedInUser={loggedInUser}
      />
      <ConfirmDialog
        open={!!removeUsers}
        onCancel={() => setRemoveUsers(null)}
        onConfirm={onRemoveRole}
        title={`Remove role ${tab} from users ${
          removeUsers && removeUsers.map(u => u.nick).join(', ')
        }?`}
        confirmText="Remove"
        cancelText="Cancel"
      />
    </Navigation>
  )
}
