import { isEmpty } from 'lodash'
import { arrayOf, bool, func, string, shape } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { routes } from '../../constants/routes'
import { chatProps, writerProps } from '../prop-types'
import { eventToChat, validate } from './utils'
import { ButtonWithSpinner } from '../ButtonWithSpinner'
import { enqueueErrorSnackbarAction } from '../../actions/snackbar-actions'
import { GeneralFields } from './FieldSets/GeneralFields'
import { LanderFields } from './FieldSets/LanderFields'
import { AppearanceFields } from './FieldSets/AppearanceFields'
import { SharingFields } from './FieldSets/SharingFields'
import { WriterFields } from './FieldSets/WriterFields'
import { ProgramFields } from './FieldSets/ProgramFields'
import { emojiRequestAction } from '../../actions/emoji-actions'

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '512px'
  },
  withPadding: {
    paddingBottom: theme.spacing(3)
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  topButtonContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

export function ChatEditForm({
  isUpdating,
  isNew,
  onSubmit,
  onArchive,
  onChange,
  onCancel,
  dispatch,
  chat,
  writersFromDB,
  writerEmails
}) {
  const classes = useStyles()
  const history = useHistory()

  const [validationErrors, setValidationErrors] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    const newChat = eventToChat(event, writerEmails, writersFromDB, chat.id)
    const validation = validate(newChat)
    setValidationErrors(validation)
    if (isEmpty(validation)) {
      onSubmit(newChat)
    } else {
      dispatch(
        enqueueErrorSnackbarAction({ error: { message: 'Could not save, invalid input data' } })
      )
    }
  }

  const navigateToChats = () => {
    history.push(routes.chatList)
  }

  const archiveChat = () => {
    onArchive(chat.id, navigateToChats)
    setIsModalOpen(false)
  }

  useEffect(() => {
    dispatch(emojiRequestAction())
  }, [])

  return (
    <div className={classes.container}>
      <form onChange={onChange} onSubmit={handleSubmit}>
        <CardContent>
          {!isNew && (
            <div className={classes.topButtonContainer}>
              <ButtonWithSpinner
                color="primary"
                variant="contained"
                text={isNew ? 'Create' : 'Save'}
                type="submit"
                isUpdating={isUpdating}
              />
            </div>
          )}
          <GeneralFields chat={chat} validationErrors={validationErrors} />
          <WriterFields writersFromDB={writersFromDB} writerEmails={writerEmails} />
          <ProgramFields chat={chat} validationErrors={validationErrors} />
          <AppearanceFields chat={chat} validationErrors={validationErrors} />
          <LanderFields chat={chat} validationErrors={validationErrors} />
          <SharingFields chat={chat} />
          <div className={classes.buttonsContainer}>
            {!isNew && (
              <ButtonWithSpinner
                text="Archive"
                onClick={() => setIsModalOpen(true)}
                isUpdating={isUpdating}
              />
            )}
            {onCancel && (
              <Button onClick={onCancel} color="primary">
                Cancel
              </Button>
            )}
            <ButtonWithSpinner
              color="primary"
              variant="contained"
              text={isNew ? 'Create' : 'Save'}
              type="submit"
              isUpdating={isUpdating}
            />
          </div>
          <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <DialogTitle>Archive chat?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Archived chats are removed from chat list and cannot be accessed by users.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button color="primary" onClick={archiveChat}>
                Archive
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </form>
    </div>
  )
}

ChatEditForm.defaultProps = {
  chat: {},
  onCancel: null,
  onArchive: null,
  onChange: null,
  writersFromDB: [],
  writerEmails: []
}

ChatEditForm.propTypes = {
  onSubmit: func.isRequired,
  onArchive: func,
  isUpdating: bool.isRequired,
  isNew: bool.isRequired,
  chat: chatProps,
  onCancel: func,
  dispatch: func.isRequired,
  writersFromDB: arrayOf(writerProps),
  writerEmails: arrayOf(
    shape({
      email: string
    })
  ),
  onChange: func
}
