import {
  COMMENT_POST_MODERATE_ERROR,
  COMMENT_POST_MODERATE_REQUEST,
  COMMENT_POST_MODERATE_SUCCESS,
  COMMENT_TOGGLE_STARRED_REQUEST,
  COMMENT_TOGGLE_STARRED_SUCCESS,
  COMMENTS_GET_MODERATED_ERROR,
  COMMENTS_GET_MODERATED_REQUEST,
  COMMENTS_GET_MODERATED_SUCCESS,
  COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON
} from './action-types'

export function getModeratedCommentsAction({ chatId, params }) {
  return {
    type: COMMENTS_GET_MODERATED_REQUEST,
    chatId,
    params
  }
}

export function getModeratedCommentsSuccessAction({ comments, params }) {
  return {
    type: COMMENTS_GET_MODERATED_SUCCESS,
    comments,
    params
  }
}

export function getModeratedCommentsErrorAction({ error }) {
  return {
    type: COMMENTS_GET_MODERATED_ERROR,
    error
  }
}

export function getPostModerateCommentRequestAction({ chatId, commentId, action }) {
  return {
    type: COMMENT_POST_MODERATE_REQUEST,
    chatId,
    commentId,
    action
  }
}

export function getPostModerateCommentSuccessAction({ commentId }) {
  return {
    type: COMMENT_POST_MODERATE_SUCCESS,
    commentId
  }
}

export function getToggleStarredCommentRequestAction({ chatId, commentId, isStarred }) {
  return {
    type: COMMENT_TOGGLE_STARRED_REQUEST,
    chatId,
    commentId,
    isStarred
  }
}

export function getToggleStarredCommentSuccessAction({ comment }) {
  return {
    type: COMMENT_TOGGLE_STARRED_SUCCESS,
    comment
  }
}

export function getPostModerateCommentErrorAction({ error, commentId }) {
  return {
    type: COMMENT_POST_MODERATE_ERROR,
    error,
    commentId
  }
}

export function getToggleAcceptedRadioButtonAction({ accepted }) {
  return {
    type: COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON,
    accepted
  }
}
