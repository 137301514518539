import React from 'react'
import { Button } from '@mui/material'
import { bool, string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { moderateHandleBatchAction } from '../../actions/moderate-actions'

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '16px'
  }
}))

export function RejectAll({ chatId, isDisabled }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleReject = () => {
    dispatch(moderateHandleBatchAction({ chatId, action: 'reject' }))
  }

  return (
    <Button
      onClick={handleReject}
      disabled={isDisabled}
      variant="outlined"
      className={classes.button}
    >
      Reject all in moderation
    </Button>
  )
}

RejectAll.propTypes = {
  chatId: string.isRequired,
  isDisabled: bool.isRequired
}
