import React, { useEffect, useState } from 'react'
import { bool, func, number, string } from 'prop-types'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { pollCreateRequest } from '../../actions/polls-actions'
import { PollEditDialog } from './PollEditDialog'
import { palette as ylePalette } from '../../styles/colors'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.main,
    color: ylePalette.white.normal,
    height: '36px',
    margin: '10px'
  }
}))
export function PollAddButton({ dispatch, chatId, isUpdating, pollsCount }) {
  const classes = useStyles()
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Close add dialog on successful save (i.e. when a new poll is added to list)
  useEffect(() => {
    setIsDialogOpen(false)
  }, [pollsCount])

  const handleSubmit = poll => {
    dispatch(pollCreateRequest({ poll, chatId }))
  }

  return (
    <>
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<Add />}
        onClick={() => setIsDialogOpen(true)}
      >
        Add new poll
      </Button>
      <PollEditDialog
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        handleSubmit={handleSubmit}
        isUpdating={isUpdating}
      />
    </>
  )
}

PollAddButton.defaultProps = {
  isUpdating: false
}

PollAddButton.propTypes = {
  dispatch: func.isRequired,
  chatId: string.isRequired,
  pollsCount: number.isRequired,
  isUpdating: bool
}
