import { EMOJI_ADD, EMOJI_ERROR, EMOJI_REQUEST, EMOJI_SUCCESS } from './action-types'

export function emojiAddAction({ emoji }) {
  return {
    type: EMOJI_ADD,
    emoji
  }
}

export function emojiErrorAction({ error }) {
  return {
    type: EMOJI_ERROR,
    error
  }
}

export function emojiRequestAction() {
  return {
    type: EMOJI_REQUEST
  }
}

export function emojiSuccessAction({ emojis }) {
  return {
    type: EMOJI_SUCCESS,
    emojis
  }
}
