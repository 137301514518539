import React from 'react'
import { styled } from '@mui/system'
import { arrayOf } from 'prop-types'
import { commentProps } from '../prop-types'
import { Comment } from './Comment'

const List = styled('div')(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '672px',
  width: '100%',
  margin: theme.spacing(1)
}))

export function CommentList({ comments }) {
  return (
    <List>
      {comments.map(c => (
        <Comment key={c.id} comment={c} />
      ))}
    </List>
  )
}

CommentList.propTypes = {
  comments: arrayOf(commentProps).isRequired
}
