import React, { useState } from 'react'
import { TextField, FormControlLabel, Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { shape } from 'prop-types'
import { DateTimePicker } from '@mui/x-date-pickers'
import { FormSectionTitle } from './FormSectionTitle'
import { chatProps } from '../../prop-types'
import { PaddedTextField } from './PaddedTextField'
import { FullWidthContainer } from './FullWidthContainer'
import { ChatImageImsPicker } from '../ImsPicker/ChatImageImsPicker'

const useStyles = makeStyles(theme => ({
  datePickerInput: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: theme.spacing(2)
  },
  openSwitch: {
    marginBottom: theme.spacing(2)
  }
}))

export function GeneralFields({ chat, validationErrors }) {
  const classes = useStyles()

  const [isActive, setIsActive] = useState(!chat.isLocked)
  const [title, setTitle] = useState(chat.title || '')
  const [description, setDescription] = useState(chat.description || '')
  // Explicit null because undefined will lead to default input value being a new Date
  const [startTime, setStartTime] = useState(chat.startTime || null)
  const [endTime, setEndTime] = useState(chat.endTime || null)

  const onSwitchChange = event => {
    setIsActive(event.target.checked)
  }

  const changeTitle = ttl => {
    setTitle(ttl.slice(0, 140))
  }

  const changeDescription = desc => {
    setDescription(desc.slice(0, 280))
  }

  const toolTipContent = (
    <>
      <b>Chat title: </b>
      Title of chat, visible publicly, maximum length is 140 characters. Mandatory.
      <br />
      <b>Description: </b>
      Description of chat, visible publicly, maximum length is 280 characters. Mandatory.
      <br />
      <b>Open: </b>
      Use this to start and stop the chat.
      <br />
      <b>Start time: </b>
      The approximate start time shown in chat list. Audience can enter the chat at this time, but
      in order to write the chat needs to also be open. Mandatory.
      <br />
      <b>End time: </b>
      The approximate end time shown in chat list. The chat is not automatically closed at this
      time.
      <br />
      <b>Add cover image from IMS: </b>
      Use the button to attach an image from IMS. The image is shown on the lander chat list page.
      <br />
    </>
  )

  return (
    <>
      <FormSectionTitle text="General info" tooltipContent={toolTipContent} />
      <FullWidthContainer>
        <PaddedTextField
          autoFocus={!chat.id}
          id="title"
          label={`Chat title (${title ? title.length : 0}/140)`}
          required
          fullWidth
          error={!!validationErrors.title}
          helperText={validationErrors.title}
          value={title}
          onChange={event => changeTitle(event.target.value)}
        />
        <PaddedTextField
          id="description"
          label={`Description (${description ? description.length : 0}/280)`}
          multiline
          required
          fullWidth
          rows={3}
          variant="outlined"
          error={!!validationErrors.description}
          helperText={validationErrors.description}
          value={description}
          onChange={event => changeDescription(event.target.value)}
        />
        <FormControlLabel
          className={classes.openSwitch}
          control={
            <Switch
              color="primary"
              id="isActive"
              checked={isActive}
              onChange={onSwitchChange}
              inputProps={{ checked: isActive }}
            />
          }
          label="Open"
          labelPlacement="start"
        />
        <DateTimePicker
          id="startTimePicker"
          label="Start time"
          disableMaskedInput
          value={startTime}
          onChange={date => setStartTime(date)}
          InputProps={{ className: classes.datePickerInput }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!validationErrors.startTime}
              helperText={validationErrors.startTime}
              fullWidth
              required
              variant="filled"
            />
          )}
        />
        <DateTimePicker
          id="endTimePicker"
          label="End time"
          disableMaskedInput
          value={endTime}
          onChange={date => setEndTime(date)}
          InputProps={{ className: classes.datePickerInput }}
          renderInput={params => (
            <TextField
              {...params}
              error={!!validationErrors.endTime}
              helperText={validationErrors.endTime}
              fullWidth
              variant="filled"
            />
          )}
        />
        <input type="hidden" id="startTime" value={startTime || ''} />
        <input type="hidden" id="endTime" value={endTime || ''} />
      </FullWidthContainer>
      <ChatImageImsPicker image={chat.image} />
    </>
  )
}

GeneralFields.defaultProps = {
  validationErrors: {}
}

GeneralFields.propTypes = {
  chat: chatProps.isRequired,
  validationErrors: shape({})
}
