import { TableCell, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { makeStyles, withStyles } from '@mui/styles'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { string } from 'prop-types'
import { chatProps } from '../prop-types'
import { formatTimestamp } from '../../utils/date-utils'

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer'
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  }
}))

const RedTypography = withStyles({
  root: {
    color: '#a83232'
  }
})(Typography)

const GreenTypography = withStyles({
  root: {
    color: '#38a838'
  }
})(Typography)

export function ChatTableRow({ chat, imageUrl, imageAlt }) {
  const classes = useStyles()
  const history = useHistory()

  const onClick = () => {
    history.push(`/${chat.id}`)
  }

  const Image = styled('img')(() => ({
    display: 'block',
    height: 42,
    width: 42,
    objectFit: 'cover',
    marginRight: 10
  }))

  return (
    <TableRow onClick={onClick} className={classes.row}>
      <TableCell align="left">
        <div className={classes.title}>
          <Image src={imageUrl} alt={imageAlt} />
          <Typography variant="body1">{chat.title}</Typography>
        </div>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body1">
          {chat.startTime ? formatTimestamp(chat.startTime) : ''}
          {chat.endTime ? ` - ${formatTimestamp(chat.endTime)}` : ''}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="body1">{chat.createdBy}</Typography>
      </TableCell>
      <TableCell align="right">
        {chat.isLocked ? (
          <RedTypography variant="body1">Closed</RedTypography>
        ) : (
          <GreenTypography variant="body1">Open</GreenTypography>
        )}
      </TableCell>
    </TableRow>
  )
}

ChatTableRow.propTypes = {
  chat: chatProps.isRequired,
  imageUrl: string.isRequired,
  imageAlt: string.isRequired
}
