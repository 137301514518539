/* istanbul ignore file */
export const pollsSelector = (state, chatId) =>
  state.polls.chatId === chatId ? state.polls.polls : []
export const isLoadingSelector = state => state.polls.isLoading
export const isUpdatingSelector = state => state.polls.isUpdating
export const pollStatsSelector = (state, pollId) => {
  if (!state.polls.pollStats) {
    return undefined
  }
  return state.polls.pollStats.find(x => {
    if (x.length === 0) return false
    return x.pollId === pollId
  })
}
