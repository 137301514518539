import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Chip, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { chatRequestAction } from '../actions/chats-actions'
import { Navigation } from '../components/Navigation/Navigation'
import { currentChatSelector, isLoadingSelector } from '../selectors/chats-selector'
import { CenteredSpinner } from '../components/CenteredSpinner'
import {
  isOperatorSelector,
  isLoadingSelector as isLoadingUserSelector
} from '../selectors/user-selector'
import { ChatTabs } from '../components/ChatTabs'
import { routes } from '../constants/routes'
import { formatTimestamp } from '../utils/date-utils'
import { WHITE } from '../styles/colors'

const TitleItem = styled('div')({
  flex: 1,
  display: 'flex',
  alignItems: 'center'
})

const TitleItemField = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: props => props.fontSize || '1rem'
}))

export function ChatPage() {
  const { chatId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const chat = useSelector(currentChatSelector)
  const isLoading = useSelector(isLoadingSelector)
  const isLoadingUser = useSelector(isLoadingUserSelector)
  const isOperator = useSelector(isOperatorSelector)

  const navigateToChats = () => {
    history.push(routes.chatList)
  }

  useEffect(() => {
    dispatch(chatRequestAction({ chatId, navigateToChats }))
  }, [chatId])

  return (
    <Navigation
      titleItem={
        <TitleItem>
          {chat && (
            <>
              <TitleItemField>{chat.title}</TitleItemField>
              <TitleItemField fontSize=".75rem">
                {formatTimestamp(chat.startTime, { weekday: undefined })}
                {chat.endTime ? ` - ${formatTimestamp(chat.endTime, { weekday: undefined })}` : ''}
              </TitleItemField>
              <Chip
                sx={{
                  backgroundColor: WHITE,
                  color: chat.isLocked ? 'error.dark' : 'success.dark'
                }}
                size="small"
                label={chat.isLocked ? 'Closed' : 'Open'}
              />
            </>
          )}
        </TitleItem>
      }
      chat={chat}
    >
      {isLoadingUser || isLoading ? (
        <CenteredSpinner />
      ) : (
        chat && <ChatTabs chat={chat} isOperator={isOperator} />
      )}
    </Navigation>
  )
}
