import React from 'react'
import { styled } from '@mui/system'
import { func, number, string } from 'prop-types'
import { Paper, Typography } from '@mui/material'
import { moderationStateProps } from '../prop-types'
import { StartStop } from './StartStop'
import { RejectAll } from './RejectAll'
import { ResetButton } from './ResetButton'
import { ModerationStatus } from './ModerationStatus'
import { AcceptAll } from './AcceptAll'

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  flexWrap: 'wrap'
})

const Margin = styled('div')(({ theme }) => ({
  margin: theme.spacing(0.5)
}))

const Actions = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2)
}))

const Caption = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1)
}))

export function TopBar({ state, chatId, setIsModalOpen, numberOfComments }) {
  const isModerating = state?.userIsModerating ?? false
  if (state) {
    return (
      <Row>
        <Margin>
          <ModerationStatus moderationStatus={state} />
        </Margin>
        <Margin>
          <Actions>
            <Caption variant="caption">Actions</Caption>
            <Row>
              <Margin>
                <StartStop chatId={chatId} isModerating={isModerating} />
              </Margin>
              <Margin>
                <RejectAll chatId={chatId} isDisabled={numberOfComments === 0 || !isModerating} />
              </Margin>
              <Margin>
                <AcceptAll chatId={chatId} isDisabled={numberOfComments === 0 || !isModerating} />
              </Margin>
              <Margin>
                <ResetButton setIsModalOpen={setIsModalOpen} />
              </Margin>
            </Row>
          </Actions>
        </Margin>
      </Row>
    )
  }
  return <Row />
}

TopBar.defaultProps = {
  state: null,
  chatId: null
}

TopBar.propTypes = {
  state: moderationStateProps,
  chatId: string,
  setIsModalOpen: func.isRequired,
  numberOfComments: number.isRequired
}
