import jwtDecode from 'jwt-decode'

/* eslint-disable no-empty */
const STORAGE_KEY = 'google_token'

export const isExpired = token => {
  const { exp } = jwtDecode(token)
  const now = Date.now()
  if (exp * 1000 < now) {
    return true
  }
  return false
}

export const removeToken = () => {
  try {
    sessionStorage.removeItem(STORAGE_KEY)
  } catch (_) {}
}

/**
 * Save token into sessionStorage.
 * May throw `SecurityError` e.g. if user has prevented persisting data in browser.
 */
export const setToken = token => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(token))
}

export const getToken = () => {
  try {
    const item = sessionStorage.getItem(STORAGE_KEY)
    if (item) {
      return JSON.parse(item)
    }
  } catch (_) {}
  return null
}
