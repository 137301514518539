import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { bool, func, string, arrayOf, shape } from 'prop-types'

const useStyles = makeStyles(({ palette }) => ({
  confirmButton: {
    backgroundColor: palette.error.main,
    color: palette.error.contrastText,
    '&:hover': {
      backgroundColor: palette.error.dark
    }
  },
  field: {
    marginTop: '10px !important',
    width: '100%'
  }
}))

export const ConfirmDialogFields = {
  textArea: ({ title, key, maxLength }) => ({
    component: ({ onChange, value, className }) => (
      <TextField
        className={className}
        label={title}
        multiline
        onChange={e =>
          maxLength ? onChange(e.target.value.slice(0, maxLength)) : onChange(e.target.value)
        }
        value={value}
        minRows={5}
      />
    ),
    key,
    defaultValue: ''
  })
}

export function ConfirmDialog({
  open,
  onConfirm,
  onCancel,
  title,
  text,
  confirmText,
  cancelText,
  fields
}) {
  const classes = useStyles()
  const [fieldValues, setFieldValues] = useState({})

  useEffect(() => {
    if (fields) {
      setFieldValues(fields.reduce((a, b) => ({ ...a, [b.key]: b.defaultValue }), {}))
    }
  }, [])

  return (
    <Dialog open={open} disablePortal>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text || null}</DialogContentText>
        {fields &&
          fields.map(f => (
            <f.component
              className={classes.field}
              key={f.key}
              onChange={value => setFieldValues({ ...fieldValues, [f.key]: value })}
              value={fieldValues[f.key] || f.defaultValue}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">
          {cancelText}
        </Button>
        <Button
          className="confirmButton"
          onClick={() => onConfirm(fieldValues)}
          variant="contained"
          classes={{ root: classes.confirmButton }}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  open: bool.isRequired,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
  title: string.isRequired,
  text: string,
  confirmText: string.isRequired,
  cancelText: string.isRequired,
  fields: arrayOf(
    shape({
      component: func,
      key: string,
      defaultValue: string
    })
  )
}

ConfirmDialog.defaultProps = {
  fields: [],
  text: ''
}
