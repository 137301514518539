import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { number, string } from 'prop-types'
import { SearchBar } from './SearchBar'
import { CommentList } from './CommentList'
import { getModeratedCommentsAction } from '../../actions/comments-actions'
import {
  commentsSelector,
  isLoadingSelector,
  updatingIdsSelector
} from '../../selectors/comments-selector'
import { moderationStateSelector } from '../../selectors/moderate-selector'

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export function Messages({ chatId, limit }) {
  const classes = useStyles()

  const dispatch = useDispatch()

  const comments = useSelector(commentsSelector)
  const counts = useSelector(moderationStateSelector)
  const updatingIds = useSelector(updatingIdsSelector)
  const isLoading = useSelector(isLoadingSelector)

  const [params, setParams] = useState({
    offset: 0,
    limit,
    searchTerm: '',
    filter: '' // empty (accepted), 'rejected' or 'starred'
  })

  useEffect(() => {
    dispatch(getModeratedCommentsAction({ chatId, params }))
  }, [params])

  const onLoadMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit
    })
  }

  return (
    <div className={classes.container}>
      <SearchBar params={params} setParams={setParams} commentTotals={counts} />
      <CommentList
        comments={comments}
        isLoading={isLoading}
        updatingIds={updatingIds}
        accepted={params.filter !== 'rejected'}
        dispatch={dispatch}
        chatId={chatId}
        setParams={setParams}
        onLoadMore={onLoadMore}
      />
    </div>
  )
}

Messages.defaultProps = {
  limit: 50
}

Messages.propTypes = {
  chatId: string.isRequired,
  // Limit is a separate prop mostly to allow testing with a smaller number of components.
  limit: number
}
