import React, { useEffect } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography
} from '@mui/material'
import { bool, func, string } from 'prop-types'
import { pollProps } from '../prop-types'
import { pollPublishResultsRequest, pollResultsRequest } from '../../actions/polls-actions'
import { POLL } from '../../constants/poll-settings-options'
import { PollResultTable } from './PollResultTable'
import { SliderResults } from './SliderResults'
import { countResults } from './utils'

export function PollResultsDialog({ isOpen, handleClose, dispatch, poll, chatId, isUpdating }) {
  useEffect(() => {
    if (isOpen) {
      dispatch(pollResultsRequest({ chatId, pollId: poll.id }))
    }
  }, [isOpen])

  const onPublishResults = () => {
    dispatch(pollPublishResultsRequest({ chatId, pollId: poll.id }))
  }

  const resultsElement =
    poll.pollType === POLL ? <PollResultTable poll={poll} /> : <SliderResults poll={poll} />

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle sx={{ paddingBottom: 0, fontSize: '24px' }}>{poll.title}</DialogTitle>
      {poll.results && (
        <Typography sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)', padding: '0 24px' }}>
          {`${countResults(poll.results)} responses`}
        </Typography>
      )}
      <DialogContent>{isUpdating ? <CircularProgress size={20} /> : resultsElement}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={onPublishResults} variant="contained" color="primary">
          Publish results to chat
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PollResultsDialog.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  poll: pollProps.isRequired,
  chatId: string.isRequired,
  dispatch: func.isRequired,
  isUpdating: bool.isRequired
}
