import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  COMMENT_POST_MODERATE_REQUEST,
  COMMENT_TOGGLE_STARRED_REQUEST,
  COMMENTS_GET_MODERATED_REQUEST
} from '../actions/action-types'
import { configSelector } from '../selectors/config-selector'
import {
  getModeratedCommentsErrorAction,
  getModeratedCommentsSuccessAction,
  getPostModerateCommentErrorAction,
  getPostModerateCommentSuccessAction,
  getToggleStarredCommentSuccessAction
} from '../actions/comments-actions'
import { getModeratedComments, handleComment } from '../api/backend-api'
import { enqueueSuccessSnackbarAction } from '../actions/snackbar-actions'
import { starredLabel } from '../constants/comment-labels'

export function* watchGetModeratedComments() {
  yield takeLatest(COMMENTS_GET_MODERATED_REQUEST, getModeratedCommentsSaga)
}

export function* getModeratedCommentsSaga({ chatId, params }) {
  try {
    const config = yield select(configSelector)
    const comments = yield getModeratedComments(config, chatId, params)
    yield put(getModeratedCommentsSuccessAction({ comments, params }))
    if (params.offset > 0 && comments.length === 0) {
      yield put(enqueueSuccessSnackbarAction({ message: 'No more comments to load' }))
    }
  } catch (error) {
    yield put(getModeratedCommentsErrorAction({ error }))
  }
}

/* Performs the same backend call as moderating a comment in the moderation page,
   but side effects are different.
*/
export function* watchPostModerateCommentSaga() {
  yield takeEvery(
    [COMMENT_POST_MODERATE_REQUEST, COMMENT_TOGGLE_STARRED_REQUEST],
    postModerateCommentSaga
  )
}

function* postModerateCommentSaga({ type, chatId, commentId, action, isStarred = null }) {
  try {
    const config = yield select(configSelector)
    if (type === COMMENT_POST_MODERATE_REQUEST) {
      yield handleComment(config, chatId, commentId, action)
      yield put(getPostModerateCommentSuccessAction({ commentId }))
    } else {
      const labels = isStarred ? [starredLabel] : []
      const comment = yield handleComment(config, chatId, commentId, 'label', { labels })
      yield put(getToggleStarredCommentSuccessAction({ comment }))
    }
  } catch (error) {
    yield put(getPostModerateCommentErrorAction({ error, commentId }))
  }
}
