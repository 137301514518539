import React, { useState } from 'react'
import { Dialog, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { string } from 'prop-types'
import Picker from 'emoji-picker-react'

const defaultEmoji = '⚪'

const useStyles = makeStyles(theme => ({
  emojiField: {
    marginRight: theme.spacing(1),
    width: theme.spacing(10)
  },
  innerInput: {
    fontSize: '1.5rem',
    textAlign: 'center'
  }
}))

export function UnicodeEmojiSelector({ defaultValue }) {
  const classes = useStyles()
  const [emoji, setEmoji] = useState(defaultValue || defaultEmoji)
  const [isPickerOpen, setIsPickerOpen] = useState(false)
  const onEmojiClick = emojiObject => {
    setEmoji(emojiObject.emoji)
    setIsPickerOpen(false)
  }

  return (
    <div>
      <TextField
        value={emoji}
        id="emoji"
        label="Emoji"
        onClick={() => setIsPickerOpen(!isPickerOpen)}
        className={classes.emojiField}
        InputProps={{
          classes: {
            input: classes.innerInput
          }
        }}
      />
      <Dialog open={isPickerOpen} onClose={() => setIsPickerOpen(false)}>
        <Picker onEmojiClick={onEmojiClick} />
      </Dialog>
    </div>
  )
}

UnicodeEmojiSelector.defaultProps = {
  defaultValue: null
}

UnicodeEmojiSelector.propTypes = {
  defaultValue: string
}
