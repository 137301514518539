import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { Button, CircularProgress } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import { chatStatsRequestAction } from '../../actions/chat-actions'
import { ChatStatsGrid } from './ChatStatsGrid'
import { chatProps } from '../prop-types'
import { isLoadingSelector, statsSelector } from '../../selectors/stats-selector'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4)
  },
  gridContainer: {
    maxWidth: '100em'
  }
}))

export function ChatStats({ chat }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const stats = useSelector(state => statsSelector(state, chat.id))
  const isLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    dispatch(chatStatsRequestAction({ chatId: chat.id }))
  }, [chat.id])

  const onButtonClick = () => {
    dispatch(chatStatsRequestAction({ chatId: chat.id }))
  }

  return (
    <>
      <div className={classes.container}>
        {isLoading || !stats ? (
          <CircularProgress />
        ) : (
          <div className={classes.gridContainer}>
            <Button
              onClick={onButtonClick}
              startIcon={<Refresh />}
              variant="outlined"
              color="primary"
              sx={{ marginBottom: 2 }}
            >
              Reload
            </Button>
            <ChatStatsGrid stats={stats} />
          </div>
        )}
      </div>
    </>
  )
}

ChatStats.propTypes = {
  chat: chatProps.isRequired
}
