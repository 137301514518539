import React, { useState } from 'react'
import { Button, Tab, Tabs, TextField } from '@mui/material'
import { Search } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { func, number, shape, string } from 'prop-types'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap-reverse'
  },
  inputContainer: {
    marginRight: theme.spacing(1)
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }
  },
  wrap: {
    flexWrap: 'wrap'
  }
}))

export function SearchBar({ params, setParams, commentTotals }) {
  const classes = useStyles()
  const [searchTerm, setSearchTerm] = useState('')

  const search = () => {
    setParams({
      searchTerm,
      filter: params.filter,
      offset: 0,
      limit: params.limit
    })
  }

  const handleTabChange = (event, newValue) => {
    setParams({
      ...params,
      filter: newValue,
      offset: 0,
      limit: params.limit
    })
  }

  const onEnterPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      search()
    }
  }

  return (
    <div className={classes.container}>
      <Tabs
        value={params.filter}
        onChange={handleTabChange}
        classes={{
          indicator: classes.indicator,
          flexContainer: classes.wrap
        }}
        indicatorColor="primary"
        TabIndicatorProps={{ children: <span /> }}
        textColor="primary"
      >
        <Tab label={`ACCEPTED (${commentTotals.accepted})`} value="" className={classes.tab} />
        <Tab
          label={`REJECTED (${commentTotals.rejected})`}
          value="rejected"
          className={classes.tab}
        />
        <Tab label={`STARRED (${commentTotals.starred})`} value="starred" className={classes.tab} />
      </Tabs>
      <div className={classes.rightContainer}>
        <div className={classes.inputContainer}>
          <TextField
            value={searchTerm}
            id="search_term"
            placeholder="Author or content"
            autoFocus
            onChange={event => setSearchTerm(event.target.value)}
            onKeyPress={onEnterPress}
            size="small"
          />
        </div>
        <Button variant="contained" color="primary" onClick={search} startIcon={<Search />}>
          Search
        </Button>
      </div>
    </div>
  )
}

SearchBar.defaultProps = {
  commentTotals: { accepted: 0, rejected: 0, starred: 0 }
}

SearchBar.propTypes = {
  setParams: func.isRequired,
  params: shape({
    searchTerm: string,
    filter: string,
    offset: number,
    limit: number
  }).isRequired,
  commentTotals: shape({
    accepted: number,
    rejected: number,
    starred: number
  })
}
