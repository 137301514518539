import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.MODERATE_GET_SUCCESS: {
      return Object.freeze({
        ...state,
        comments: action.comments
      })
    }
    case actions.MODERATE_HANDLE_BATCH_SUCCESS: {
      return Object.freeze({
        ...state,
        comments: []
      })
    }
    case actions.MODERATE_HANDLE_SUCCESS: {
      return Object.freeze({
        ...state,
        comments: state.comments.filter(({ id }) => id !== action.commentId)
      })
    }
    case actions.MODERATE_STATE_SUCCESS: {
      return Object.freeze({
        ...state,
        moderationState: action.data
      })
    }
    case actions.MODERATE_RESET_MODERATION_SUCCESS: {
      return Object.freeze({
        ...state,
        moderationState: action.moderationState
      })
    }
    case actions.MODERATE_RESET_MODERATION_ERROR: {
      return Object.freeze({
        ...state
      })
    }
    default:
      return state
  }
}
