import React from 'react'
import { FormControl, FormGroup, FormLabel, TextField } from '@mui/material'
import { number, shape } from 'prop-types'
import { pollProps } from '../prop-types'

export function PollOptions({ poll, maxOptions, validationErrors }) {
  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel>Options</FormLabel>
      <FormGroup>
        {[...Array(maxOptions)].map((_, i) => {
          const id = `option_${i}`
          return (
            <TextField
              // eslint-disable-next-line react/no-array-index-key
              key={id}
              id={id}
              sx={{ marginBottom: 1 }}
              defaultValue={(poll.options && poll.options[i]) || ''}
              error={!!validationErrors[id]}
              helperText={validationErrors[id]}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

PollOptions.propTypes = {
  poll: pollProps.isRequired,
  maxOptions: number.isRequired,
  validationErrors: shape({}).isRequired
}
