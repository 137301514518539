import React, { useState, useEffect } from 'react'
import { arrayOf, bool, func } from 'prop-types'
import { IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { PrevNextButtons, Table, TableColumn } from '../Table/Table'
import { listUserProps, tableActionsProps } from '../prop-types'
import { Banner, BannerText, BannerActions } from '../Banner/Banner'

export function UserTable({ users, hasRights, isLoading, tableActions, onEdit, onDelete }) {
  const [selectableUsers, setSelectableUsers] = useState([])

  useEffect(() => {
    setSelectableUsers(users.map(u => ({ ...u, isSelected: false })))
  }, [users])

  const onSelectUser = user => {
    setSelectableUsers(
      selectableUsers.map(u => (u.id === user.id ? { ...u, isSelected: !u.isSelected } : u))
    )
  }

  const onSelectUsers = isSelected => {
    setSelectableUsers(selectableUsers.map(u => ({ ...u, isSelected })))
  }

  const selectedUsers = selectableUsers.filter(u => u.isSelected)

  return (
    <>
      <Banner>
        <BannerText>{`${selectedUsers.length} items selected`}</BannerText>
        <BannerActions>
          <IconButton
            aria-label="Edit"
            disabled={!hasRights || selectedUsers.length !== 1}
            onClick={() => onEdit(selectedUsers.map(({ isSelected, ...u }) => u)[0])}
          >
            <Edit />
          </IconButton>
          <IconButton
            aria-label="Delete"
            disabled={!hasRights || selectedUsers.length < 1}
            onClick={() => onDelete(selectedUsers.map(({ isSelected, ...u }) => u))}
          >
            <Delete />
          </IconButton>
        </BannerActions>
      </Banner>
      <Table
        data={selectableUsers}
        columns={[
          TableColumn.checkbox({
            onChange: onSelectUser,
            onChangeHeader: onSelectUsers,
            key: 'isSelected'
          }),
          TableColumn.userNick,
          TableColumn.userRoles
        ]}
        isLoading={isLoading}
      />
      <PrevNextButtons {...tableActions} />
    </>
  )
}

UserTable.propTypes = {
  users: arrayOf(listUserProps).isRequired,
  hasRights: bool.isRequired,
  isLoading: bool.isRequired,
  tableActions: tableActionsProps.isRequired,
  onEdit: func.isRequired,
  onDelete: func.isRequired
}
