import React from 'react'
import { useSelector } from 'react-redux'
import {
  Avatar,
  Card,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Typography
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { number, string } from 'prop-types'
import { GridItemTitle } from './GridItemTitle'
import { chatStatsProps } from '../prop-types'
import { reactionEmojis } from '../../constants/chat-settings-options'
import { Emoji } from '../ChatEditor/FieldSets/Emoji'
import { configSelector } from '../../selectors/config-selector'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

export function FeedReactions({ stats }) {
  return (
    <Card>
      <GridItemTitle text="Reactions" />
      {!stats.reactions || stats.reactions.length === 0 ? (
        <Typography sx={{ padding: 1 }} color="textSecondary">
          No reactions
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableBody>
              {stats.reactions.map(reaction => (
                <FeedReactionRow
                  key={reaction.reactionType}
                  reactionType={reaction.reactionType}
                  count={reaction.reactionCount}
                  name={reaction.name}
                  imsPath={reaction.imsPath}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  )
}

function FeedReactionRow({ reactionType, count, name, imsPath }) {
  const classes = useStyles()
  const { imagesApiUrl } = useSelector(configSelector)
  const emoji = reactionEmojis.find(e => e.id === reactionType)

  return (
    <TableRow>
      <TableCell>
        {
          // eslint-disable-next-line no-nested-ternary
          imsPath != null ? (
            <Emoji emoji={{ id: reactionType, name, imsPath }} imagesApiUrl={imagesApiUrl} />
          ) : emoji != null ? (
            <Avatar alt={emoji.id} src={emoji.src} className={classes.avatar} />
          ) : (
            reactionType
          )
        }
      </TableCell>
      <TableCell>{count}</TableCell>
    </TableRow>
  )
}

FeedReactions.propTypes = {
  stats: chatStatsProps.isRequired
}

FeedReactionRow.defaultProps = {
  name: undefined,
  imsPath: undefined
}

FeedReactionRow.propTypes = {
  reactionType: string.isRequired,
  count: number.isRequired,
  name: string,
  imsPath: string
}
