import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { arrayOf, shape, string } from 'prop-types'
import { List } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormSectionTitle } from './FormSectionTitle'
import { writerProps } from '../../prop-types'
import { WriterListItem } from './WriterListItem'
import { chatAddWriterAction, chatRemoveWriterAction } from '../../../actions/chat-actions'
import { ChatFormButton } from './ChatFormButton'
import { PaddedTextField } from './PaddedTextField'

export function WriterFields({ writersFromDB, writerEmails }) {
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const tooltipContent = (
    <>
      <b>Commentator: </b>
      Enter Yle Tunnus emails to add users who can write comments in the chat bypassing moderation.
      <br />
    </>
  )

  const addWriter = () => {
    if (!email) {
      setError('Enter email')
      setTimeout(() => setError(''), 3000)
    } else if (!email.includes('@')) {
      setError('Not a valid email')
    } else {
      dispatch(chatAddWriterAction({ email }))
      setEmail('')
    }
  }
  const onSetEmail = e => {
    setEmail(e.target.value)
    setError('')
  }
  const removeWriter = writer => {
    dispatch(chatRemoveWriterAction({ writer }))
  }
  const onEnterPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      addWriter()
    }
  }

  return (
    <>
      <FormSectionTitle text="Commentators" tooltipContent={tooltipContent} />
      <PaddedTextField
        id="writerEmail"
        label="Commentator email"
        fullWidth
        value={email}
        onChange={onSetEmail}
        onKeyPress={onEnterPress}
        error={!!error}
        helperText={error}
      />
      <ChatFormButton startIcon={<Add />} id="addWriter" onClick={addWriter}>
        Add commentator
      </ChatFormButton>
      <List>
        {writerEmails.map(writer => (
          <WriterListItem key={writer.email} writer={writer} removeWriter={removeWriter} />
        ))}
        {writersFromDB.map(writer => (
          <WriterListItem key={writer.id} writer={writer} removeWriter={removeWriter} />
        ))}
      </List>
    </>
  )
}

WriterFields.propTypes = {
  writersFromDB: arrayOf(writerProps),
  writerEmails: arrayOf(
    shape({
      email: string
    })
  )
}

WriterFields.defaultProps = {
  writersFromDB: [],
  writerEmails: []
}
