import React from 'react'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { Navigation } from '../components/Navigation/Navigation'
import { PageInstructions } from '../components/PageInstructions'
import { isLoadingSelector } from '../selectors/user-selector'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: theme.spacing(2)
}))

function LoginButton() {
  const renderButton = elem => {
    if (elem !== null) {
      window.google.accounts.id.renderButton(elem, { type: 'standard', size: 'large' })
    }
  }
  return <div ref={renderButton} />
}

export function LoginPage() {
  const isLoadingUser = useSelector(isLoadingSelector)

  return (
    <Navigation titleText="Chat Admin Pro">
      <Container>
        {isLoadingUser ? (
          <CircularProgress />
        ) : (
          <>
            <PageInstructions text="Log in with your Yle Google account to use the chat admin" />
            <LoginButton />
          </>
        )}
      </Container>
    </Navigation>
  )
}
