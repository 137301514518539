import React from 'react'
import { bool, func, arrayOf } from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  DialogContentText
} from '@mui/material'

import { topicModerator } from '../prop-types'

export function ActiveModeratorsDialog({ activeModerators, isModalOpen, setIsModalOpen }) {
  const handleCloseDialog = () => {
    setIsModalOpen(false)
  }

  return (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>Active Moderators</DialogTitle>
      <DialogContent>
        {activeModerators.length ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nick</TableCell>
                <TableCell>In moderation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeModerators.map(mod => (
                <TableRow key={mod.id}>
                  <TableCell>{mod.nick}</TableCell>
                  <TableCell>{mod.inModeration}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <DialogContentText>No active moderators</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ActiveModeratorsDialog.propTypes = {
  activeModerators: arrayOf(topicModerator).isRequired,
  isModalOpen: bool.isRequired,
  setIsModalOpen: func.isRequired
}
