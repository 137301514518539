import React, { useState } from 'react'
import { node, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Container, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { chatProps } from '../prop-types'
import { TopBar } from './TopBar'
import { SideDrawer } from './SideDrawer'
import { userSelector } from '../../selectors/user-selector'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  content: {
    padding: theme.spacing(2)
  }
}))

export function Navigation({ titleText, titleItem, chat, children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const user = useSelector(userSelector)
  const classes = useStyles()

  // The empty <Toolbar /> results in automagic positioning of components below the bar.
  // See https://mui.com/components/app-bar/#fixed-placement
  return (
    <Box className={classes.flex}>
      <TopBar
        onClickMenu={() => setIsDrawerOpen(!isDrawerOpen)}
        titleItem={titleItem}
        titleText={titleText}
      />
      {user && user.loggedIn && <SideDrawer isOpen={isDrawerOpen} chat={chat} />}
      <Container maxWidth="xl" className={classes.content}>
        <Toolbar />
        {children}
      </Container>
    </Box>
  )
}

Navigation.defaultProps = {
  titleText: '',
  titleItem: null,
  chat: null,
  children: []
}

Navigation.propTypes = {
  titleText: string,
  titleItem: node,
  chat: chatProps,
  children: node
}
