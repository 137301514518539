import React, { useCallback, useState } from 'react'
import { ImsDialog } from './ImsDialog'

export function useImsPicker(setImage) {
  const [isOpen, setIsOpen] = useState(false)

  const onImage = useCallback(
    (id, { alt, blurhash, crop, version }) => {
      setImage({ id, alt, blurhash, crop, version })
      setIsOpen(false)
    },
    [setImage, setIsOpen]
  )

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen])

  return {
    dialog: <ImsDialog isOpen={isOpen} onImage={onImage} onClose={onClose} />,
    openPicker: () => setIsOpen(true)
  }
}
