import {
  CHAT_REQUEST,
  CHAT_SUCCESS,
  CHAT_ERROR,
  CHATS_ERROR,
  CHATS_REQUEST,
  CHATS_SUCCESS
} from './action-types'

export function chatsRequestAction({ params }) {
  return {
    type: CHATS_REQUEST,
    params
  }
}

export function chatsSuccessAction({ chats, params }) {
  return {
    type: CHATS_SUCCESS,
    chats,
    params
  }
}

export function chatsErrorAction({ error }) {
  return {
    type: CHATS_ERROR,
    error
  }
}

export function chatRequestAction({ chatId, navigateToChats }) {
  return {
    type: CHAT_REQUEST,
    chatId,
    navigateToChats
  }
}

export function chatSuccessAction({ chat }) {
  return {
    type: CHAT_SUCCESS,
    chat
  }
}

export function chatErrorAction({ error }) {
  return {
    type: CHAT_ERROR,
    error
  }
}
