import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '../actions/action-types'

const errorAutoHideDuration = 40000
const successAutoHideDuration = 3000

export default function (state, action) {
  switch (action.type) {
    case ENQUEUE_ERROR_SNACKBAR:
      return Object.freeze(errorToSnackbar(action.error))
    case ENQUEUE_SUCCESS_SNACKBAR:
      return Object.freeze({
        message: action.message,
        options: {
          variant: 'success',
          autoHideDuration: successAutoHideDuration
        }
      })
    default:
      return state
  }
}

function errorToSnackbar(error) {
  return {
    message: error.message,
    options: {
      variant: 'error',
      autoHideDuration: errorAutoHideDuration
    }
  }
}
