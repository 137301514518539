import React from 'react'
import { Grid } from '@mui/material'
import { CommentsStats } from './CommentsStats'
import { chatStatsProps } from '../prop-types'
import { MostActiveCommenters } from './MostActiveCommenters'
import { MostLikedComments } from './MostLikedComments'
import { FeedReactions } from './FeedReactions'

export function ChatStatsGrid({ stats }) {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <CommentsStats stats={stats} />
      </Grid>
      <Grid item>
        <MostActiveCommenters stats={stats} />
      </Grid>
      <Grid item>
        <MostLikedComments stats={stats} />
      </Grid>
      <Grid item>
        <FeedReactions stats={stats} />
      </Grid>
    </Grid>
  )
}

ChatStatsGrid.propTypes = {
  stats: chatStatsProps.isRequired
}
