import { bool, string } from 'prop-types'
import { makeStyles } from '@mui/styles'
import { CircularProgress, Typography } from '@mui/material'
import React from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(10)
  },
  spinner: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.text.disabled
  }
}))

export function PageInstructions({ text, showSpinner }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {showSpinner && <CircularProgress className={classes.spinner} />}
      <Typography variant="body1" color="textSecondary">
        {text}
      </Typography>
    </div>
  )
}

PageInstructions.defaultProps = {
  showSpinner: false
}
PageInstructions.propTypes = {
  text: string.isRequired,
  showSpinner: bool
}
