import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from './action-types'

export function enqueueErrorSnackbarAction({ error }) {
  return {
    type: ENQUEUE_ERROR_SNACKBAR,
    error
  }
}

export function enqueueSuccessSnackbarAction({ message }) {
  return {
    type: ENQUEUE_SUCCESS_SNACKBAR,
    message
  }
}
