import { put, select, takeLatest } from 'redux-saga/effects'
import { POLLS_REQUEST } from '../actions/action-types'
import { configSelector } from '../selectors/config-selector'
import { getPolls } from '../api/backend-api'
import { pollsErrorAction, pollsSuccessAction } from '../actions/polls-actions'

export function* watchGetPolls() {
  yield takeLatest(POLLS_REQUEST, getPollsSaga)
}

function* getPollsSaga({ chatId, filter }) {
  try {
    const config = yield select(configSelector)
    const polls = yield getPolls(config, chatId, filter)
    yield put(pollsSuccessAction({ chatId, polls }))
  } catch (error) {
    yield put(pollsErrorAction({ error }))
  }
}
