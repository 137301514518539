/* istanbul ignore file */

import { fetchJson, fetchJsonWithBody, getJson } from './fetch-methods'

/**
 * Example
 * input: { bestParameter: 3, myDate: '2022-03-31T11:10:12.616Z' }
 * output: &best_parameter=3&my_date=2022-03-31T11%3A10%3A12%2B00%3A00
 */
function encodeURIParameters(params) {
  const toSnakeCase = param => param.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  return Object.entries(params)
    .map(([param, value]) =>
      value != null ? `&${toSnakeCase(param)}=${encodeURIComponent(value)}` : ''
    )
    .join('')
}

const topicsEndpoint = 'v1/topics'
const userEndpoint = 'v1/users'
const banEndpoint = 'v1/block-and-reject-comment-author'
const pollsEndpoint = chatId => `v1/topics/${chatId}/polls`
const resetEndpoint = chatId => `v1/topics/${chatId}/reset-moderation-queues`
const bannedUsersEndpoint = 'v1/blocked-users'
const unbanEndpoint = userNick => `v1/block/${userNick}`
const emojiEndpoint = 'v1/emoji'

export function getChats(config, params) {
  let queryParams = `&offset=${params.offset}&limit=${params.limit}`
  if (params.filter) {
    queryParams = queryParams.concat(`&filter=${params.filter}`)
  }
  if (params.searchTerm) {
    queryParams = queryParams.concat(`&search_term=${params.searchTerm}`)
  }
  return getJson(config, topicsEndpoint, queryParams)
}

export function getChat(config, chatId) {
  return getJson(config, `${topicsEndpoint}/${chatId}`)
}

export function archiveChat(config, chatId) {
  return fetchJsonWithBody(config, `${topicsEndpoint}/${chatId}/archive`, {}, 'PATCH')
}

export function getChatStats(config, chatId) {
  return getJson(config, `${topicsEndpoint}/${chatId}/stats`)
}

export function getUser(config) {
  return getJson(config, `${userEndpoint}/me`)
}

export function checkToken(config, token) {
  return fetchJson(config, 'v2/login', { headers: { Authorization: `Bearer ${token}` } })
}

export function createChat(config, chat) {
  return fetchJsonWithBody(config, topicsEndpoint, chat)
}

export async function updateChat(config, chat) {
  const { id, ...updated } = chat
  return fetchJsonWithBody(config, `${topicsEndpoint}/${id}`, updated, 'PATCH')
}

export function startModerating(config, chatId) {
  return fetchJsonWithBody(config, `${topicsEndpoint}/${chatId}/start-moderating`, null, 'POST')
}
export function stopModerating(config, chatId) {
  return fetchJsonWithBody(config, `${topicsEndpoint}/${chatId}/stop-moderating`, null, 'DELETE')
}

export function claimComments(config, chatId) {
  return fetchJsonWithBody(
    config,
    `${topicsEndpoint}/${chatId}/take-comments-to-moderation`,
    null,
    'PUT'
  )
}

export function getComments(config, chatId) {
  return getJson(config, `${topicsEndpoint}/${chatId}/my-moderation-list`)
}

export function handleComment(config, chatId, commentId, action, body = null) {
  return fetchJsonWithBody(
    config,
    `${topicsEndpoint}/${chatId}/comments/${commentId}/${action}`,
    body,
    'PUT'
  )
}

export function handleComments(config, chatId, comments, action) {
  return fetchJsonWithBody(
    config,
    `${topicsEndpoint}/${chatId}/${action}-batch`,
    {
      ids: comments.map(c => c.id)
    },
    'PUT'
  )
}

export function banAuthorOfComment(config, { commentId, reason }) {
  return fetchJsonWithBody(config, `${banEndpoint}/${commentId}`, {
    reasonDescription: reason
  })
}

export function getModerationState(config, chatId) {
  return getJson(config, `${topicsEndpoint}/${chatId}/moderation-state`)
}

export function getModeratedComments(config, chatId, params) {
  const searchTerm = params.searchTerm
    ? `&search_term=${encodeURIComponent(params.searchTerm)}`
    : ''
  const queryParams = `&offset=${params.offset}&limit=${params.limit}&filter=${params.filter}${searchTerm}`

  const endpoint = `${topicsEndpoint}/${chatId}/comments/moderated`
  return getJson(config, endpoint, queryParams)
}

export async function getPolls(config, chatId, filter) {
  return getJson(config, pollsEndpoint(chatId), filter ? `&filter=${filter}` : '')
}

export async function createPoll(config, chatId, poll) {
  return fetchJsonWithBody(config, pollsEndpoint(chatId), poll)
}

export async function updatePoll(config, chatId, poll) {
  return fetchJsonWithBody(config, `${pollsEndpoint(chatId)}/${poll.id}`, poll, 'PUT')
}

export async function openPoll(config, chatId, pollId) {
  return fetchJsonWithBody(config, `${pollsEndpoint(chatId)}/${pollId}/open`)
}

export async function closePoll(config, chatId, pollId) {
  return fetchJsonWithBody(config, `${pollsEndpoint(chatId)}/${pollId}/close`)
}

export async function getPollResults(config, chatId, pollId) {
  return getJson(config, `${pollsEndpoint(chatId)}/${pollId}/results`)
}

export function getPollStats(config, chatId) {
  return getJson(config, `${topicsEndpoint}/${chatId}/stats/polls`)
}

export function publishPollResults(config, chatId, pollId) {
  return fetchJsonWithBody(
    config,
    `${pollsEndpoint(chatId)}/${pollId}/publish-results`,
    null,
    'PUT'
  )
}

export function addUser(config, type, email) {
  return fetchJsonWithBody(config, `${userEndpoint}/${encodeURIComponent(email)}/${type}`, null)
}

export function resetModeration(config, chatId) {
  const endpoint = resetEndpoint(chatId)
  return fetchJsonWithBody(config, endpoint, null, 'PUT')
}

export function getBannedUsers(config, params) {
  return getJson(config, bannedUsersEndpoint, encodeURIParameters(params))
}

export function unbanUser(config, userNick) {
  return fetchJsonWithBody(config, unbanEndpoint(userNick), null, 'DELETE')
}

export function getUsers(config, userType, params) {
  return getJson(config, `${userEndpoint}/${userType}s`, encodeURIParameters(params))
}

export function updateUser(config, externalIdSource, externalId, user) {
  return fetchJsonWithBody(config, `${userEndpoint}/${externalIdSource}/${externalId}`, user, 'PUT')
}

export function saveEmoji(config, emoji) {
  return fetchJsonWithBody(config, emojiEndpoint, emoji)
}

export function getAllEmojis(config) {
  return getJson(config, emojiEndpoint)
}
