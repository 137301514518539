import React from 'react'
import {
  Card,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography
} from '@mui/material'
import { number, string } from 'prop-types'
import { chatStatsProps } from '../prop-types'
import { GridItemTitle } from './GridItemTitle'
import { TableHeaderCell } from '../TableHeaderCell'

export function MostActiveCommenters({ stats }) {
  return (
    <Card>
      <GridItemTitle text="Most active commenters" />
      {!stats.mostActiveCommenters || stats.mostActiveCommenters.length === 0 ? (
        <Typography sx={{ padding: 1 }} color="textSecondary">
          No comments
        </Typography>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Nick</TableHeaderCell>
                <TableHeaderCell>Comments</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stats.mostActiveCommenters.map(commenter => (
                <CommenterRow
                  key={commenter.nick}
                  nick={commenter.nick}
                  commentsCount={commenter.commentsCount}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Card>
  )
}

function CommenterRow({ nick, commentsCount }) {
  return (
    <TableRow>
      <TableCell>{nick}</TableCell>
      <TableCell align="right">{commentsCount}</TableCell>
    </TableRow>
  )
}

MostActiveCommenters.propTypes = {
  stats: chatStatsProps.isRequired
}

CommenterRow.propTypes = {
  nick: string.isRequired,
  commentsCount: number.isRequired
}
