import React from 'react'
import { Button } from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import { object, string } from 'prop-types'

export function RejectButton({ text, buttonProps }) {
  return (
    <Button
      variant="text"
      className="reject"
      color="error"
      startIcon={<CancelOutlined />}
      {...buttonProps}
      style={{ fontSize: '16px' }}
    >
      {text}
    </Button>
  )
}

RejectButton.defaultProps = {
  buttonProps: {}
}

RejectButton.propTypes = {
  text: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  buttonProps: object
}
