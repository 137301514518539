import {
  BANNED_USERS_REQUEST,
  BANNED_USERS_SUCCESS,
  BANNED_USERS_ERROR,
  UNBAN_USER_REQUEST,
  UNBAN_USER_SUCCESS,
  UNBAN_USER_ERROR
} from './action-types'

export function bannedUsersRequestAction({ params }) {
  return {
    type: BANNED_USERS_REQUEST,
    params
  }
}

export function bannedUsersSuccessAction({ bannedUsers }) {
  return {
    type: BANNED_USERS_SUCCESS,
    bannedUsers
  }
}

export function bannedUsersErrorAction({ error }) {
  return {
    type: BANNED_USERS_ERROR,
    bannedUsers: [],
    error
  }
}

export function unbanUserRequestAction({ userNicks, closeDialog }) {
  return {
    type: UNBAN_USER_REQUEST,
    userNicks,
    closeDialog
  }
}

export function unbanUserSuccessAction({ userNicks }) {
  return {
    type: UNBAN_USER_SUCCESS,
    userNicks
  }
}

export function unbanUserErrorAction({ error }) {
  return {
    type: UNBAN_USER_ERROR,
    error
  }
}
