/* istanbul ignore file */

import { SvgIcon } from '@mui/material'
import React from 'react'

export function UserIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0c2.162 0 4.162.54 6 1.621A12.119 12.119 0 0122.379 6C23.46 7.839 24 9.839 24 12s-.54 4.161-1.621 6A12.126 12.126 0 0118 22.379C16.162 23.459 14.162 24 12 24a11.64 11.64 0 01-6-1.621A12.134 12.134 0 011.621 18 11.64 11.64 0 010 12c0-2.161.541-4.161 1.621-6A12.126 12.126 0 016 1.621C7.839.54 9.839 0 12 0zm6.194 20.419v-.677c0-.839-.307-1.565-.92-2.178-.612-.613-1.338-.919-2.177-.919-.129 0-.371.048-.726.145a7.759 7.759 0 01-2.371.387 7.759 7.759 0 01-2.371-.387c-.355-.097-.597-.145-.726-.145-.839 0-1.564.306-2.177.919s-.919 1.339-.919 2.178v.677C7.646 21.774 9.71 22.451 12 22.451c2.291 0 4.355-.677 6.194-2.032zm1.5-1.355a10.222 10.222 0 002.032-3.242c.484-1.225.726-2.5.726-3.822 0-1.903-.468-3.653-1.404-5.25a10.458 10.458 0 00-3.798-3.799c-1.597-.935-3.347-1.403-5.25-1.403s-3.653.468-5.25 1.403A10.458 10.458 0 002.952 6.75C2.016 8.347 1.548 10.097 1.548 12c0 1.322.243 2.597.726 3.822a10.207 10.207 0 002.033 3.242 4.754 4.754 0 011.572-2.831c.855-.757 1.863-1.136 3.024-1.136.291 0 .678.08 1.161.242.678.193 1.323.29 1.936.29s1.258-.097 1.936-.29c.483-.162.871-.242 1.161-.242 1.161 0 2.169.379 3.024 1.136a4.76 4.76 0 011.573 2.831zM12 5.419c1.162 0 2.162.42 3 1.258.839.839 1.258 1.839 1.258 3 0 1.162-.419 2.162-1.258 3-.838.839-1.838 1.259-3 1.259-1.161 0-2.161-.42-3-1.259-.838-.838-1.258-1.838-1.258-3 0-1.161.42-2.161 1.258-3 .839-.838 1.839-1.258 3-1.258zm0 6.968c.742 0 1.379-.266 1.912-.798a2.61 2.61 0 00.798-1.912c0-.741-.266-1.379-.798-1.911A2.607 2.607 0 0012 6.968c-.742 0-1.379.265-1.911.798a2.609 2.609 0 00-.798 1.911c0 .742.266 1.38.798 1.912a2.607 2.607 0 001.911.798z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.637 4.636a3.5 3.5 0 11-6.999.001 3.5 3.5 0 017-.001z"
      />
    </SvgIcon>
  )
}
