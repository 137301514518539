import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@mui/material'
import { bool, func, string } from 'prop-types'

export function RemoveUserDialog({
  isOpen,
  handleClose,
  handleRemove,
  nick,
  isUpdating,
  title,
  removeText
}) {
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{`Are you sure you want to ${removeText} ${nick}?`}</DialogContent>
      {isUpdating && <CircularProgress size={20} />}
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={isUpdating}>
          Cancel
        </Button>
        <Button onClick={handleRemove} color="primary" disabled={isUpdating}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveUserDialog.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  handleRemove: func.isRequired,
  nick: string.isRequired,
  isUpdating: bool.isRequired,
  title: string.isRequired,
  removeText: string.isRequired
}
