import React, { useEffect, useState } from 'react'
import { shape } from 'prop-types'
import { Add } from '@mui/icons-material'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PaddedTextField } from './PaddedTextField'
import { chatProps } from '../../prop-types'
import { FormSectionTitle } from './FormSectionTitle'
import { ChatFormButton } from './ChatFormButton'

const areenaFieldIdPrefix = 'areenaId_'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  selectContainer: {
    alignSelf: 'stretch'
  }
})

const toolTipContent = (
  <>
    <b>Areena program ids: </b>
    Program ids of the Areena videos where this chat should be shown, in the format 1-1234567.
    <br />
    <b>Multiaudio language: </b>
    Language of the multiaudio stream in Areena where this chat should be shown. Only needed when
    there are two different chats in one multiaudio stream.
    <br />
  </>
)

export function ProgramFields({ chat, validationErrors }) {
  const classes = useStyles()

  const [areenaProgramIds, setAreenaProgramIds] = useState(chat.areenaProgramIds || [])
  const [language, setLanguage] = useState(chat.language || 'none')

  const onChange = (idx, event) => {
    setAreenaProgramIds(Object.assign([], areenaProgramIds, { [idx]: event.target.value }))
  }

  useEffect(() => {
    setAreenaProgramIds(chat.areenaProgramIds || [])
  }, [chat])

  const onAddClick = () => {
    setAreenaProgramIds([...areenaProgramIds, ''])
  }

  return (
    <>
      <FormSectionTitle text="Show chat in Areena" tooltipContent={toolTipContent} />
      <div className={classes.container}>
        {(areenaProgramIds.length > 0 ? areenaProgramIds : ['']).map((programId, idx) => (
          <PaddedTextField
            id={`${areenaFieldIdPrefix}${idx}`}
            label={`Areena program ID ${idx + 1}`}
            fullWidth
            idx={idx}
            onChange={event => onChange(idx, event)}
            /* eslint-disable-next-line react/no-array-index-key */
            key={idx}
            value={programId}
            autoFocus={idx > (chat.areenaProgramIds && chat.areenaProgramIds.length)}
            error={Boolean(
              validationErrors.areenaProgramIds && validationErrors.areenaProgramIds[idx]
            )}
            helperText={validationErrors.areenaProgramIds && validationErrors.areenaProgramIds[idx]}
          />
        ))}
        <ChatFormButton
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onAddClick}
        >
          Add more
        </ChatFormButton>
        <FormControl margin="normal" className={classes.selectContainer} variant="filled">
          <InputLabel id="language-select-label">Multiaudio language</InputLabel>
          <Select
            name="language"
            labelId="language-select-label"
            value={language}
            fullWidth
            onChange={event => setLanguage(event.target.value)}
          >
            <MenuItem value="none">&#8211;</MenuItem>
            <MenuItem value="fi">Finnish</MenuItem>
            <MenuItem value="sv">Swedish</MenuItem>
          </Select>
        </FormControl>
      </div>
    </>
  )
}

ProgramFields.defaultProps = {
  validationErrors: {}
}

ProgramFields.propTypes = {
  chat: chatProps.isRequired,
  validationErrors: shape({})
}
