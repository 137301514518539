import {
  CHAT_CREATE_REQUEST,
  CHAT_ARCHIVE_REQUEST,
  CHAT_ARCHIVE_SUCCESS,
  CHAT_ARCHIVE_ERROR,
  CHAT_STATS_ERROR,
  CHAT_STATS_REQUEST,
  CHAT_STATS_SUCCESS,
  CHAT_UPDATE_ERROR,
  CHAT_UPDATE_REQUEST,
  CHAT_UPDATE_SUCCESS,
  CHAT_ADD_WRITER,
  CHAT_REMOVE_WRITER,
  CHAT_RESET_WRITERS,
  CHAT_TAINT,
  CHAT_UNTAINT
} from './action-types'

export function chatCreateRequestAction({ chat }) {
  return {
    type: CHAT_CREATE_REQUEST,
    chat
  }
}

export function chatUpdateRequestAction({ chat }) {
  return {
    type: CHAT_UPDATE_REQUEST,
    chat
  }
}

export function chatUpdateSuccessAction({ chat }) {
  return {
    type: CHAT_UPDATE_SUCCESS,
    chat
  }
}

export function chatUpdateErrorAction({ error }) {
  return {
    type: CHAT_UPDATE_ERROR,
    error
  }
}

export function chatStatsRequestAction({ chatId }) {
  return {
    type: CHAT_STATS_REQUEST,
    chatId
  }
}

export function chatStatsSuccessAction({ chatId, stats }) {
  return {
    type: CHAT_STATS_SUCCESS,
    stats,
    chatId
  }
}

export function chatStatsErrorAction({ error }) {
  return {
    type: CHAT_STATS_ERROR,
    error
  }
}

export function chatAddWriterAction({ email }) {
  return {
    type: CHAT_ADD_WRITER,
    email
  }
}
export function chatRemoveWriterAction({ writer }) {
  return {
    type: CHAT_REMOVE_WRITER,
    writer
  }
}

export function chatResetWritersAction() {
  return {
    type: CHAT_RESET_WRITERS
  }
}

export function chatArchiveRequestAction({ chatId, navigateToChats }) {
  return {
    type: CHAT_ARCHIVE_REQUEST,
    chatId,
    navigateToChats
  }
}

export function chatArchiveSuccessAction({ chatId }) {
  return {
    type: CHAT_ARCHIVE_SUCCESS,
    chatId
  }
}

export function chatArchiveErrorAction({ error }) {
  return {
    type: CHAT_ARCHIVE_ERROR,
    error
  }
}

export function chatTaint() {
  return {
    type: CHAT_TAINT
  }
}

export function chatUntaint() {
  return {
    type: CHAT_UNTAINT
  }
}
