import { cloneDeep } from 'lodash'
import {
  COMMENT_POST_MODERATE_ERROR,
  COMMENT_POST_MODERATE_SUCCESS,
  COMMENT_POST_MODERATE_REQUEST,
  COMMENT_TOGGLE_STARRED_REQUEST,
  COMMENT_TOGGLE_STARRED_SUCCESS,
  COMMENTS_GET_MODERATED_ERROR,
  COMMENTS_GET_MODERATED_REQUEST,
  COMMENTS_GET_MODERATED_SUCCESS,
  COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON
} from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case COMMENTS_GET_MODERATED_REQUEST:
      return Object.freeze({
        ...state,
        isLoading: true
      })
    case COMMENTS_GET_MODERATED_SUCCESS:
      return Object.freeze({
        ...state,
        comments: addNewComments(state.comments, action.comments, action.params.offset),
        isLoading: false
      })
    case COMMENTS_GET_MODERATED_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false
      })
    case COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON:
      return Object.freeze({
        ...state,
        radioButtonAccepted: action.accepted
      })
    case COMMENT_POST_MODERATE_REQUEST:
      return Object.freeze({
        ...state,
        updatingIds: [...state.updatingIds, action.commentId]
      })
    case COMMENT_POST_MODERATE_SUCCESS:
      return Object.freeze({
        ...state,
        updatingIds: removeFromUpdating(state.updatingIds, action.commentId),
        comments: state.comments.filter(({ id }) => id !== action.commentId)
      })
    case COMMENT_TOGGLE_STARRED_REQUEST:
      return Object.freeze({
        ...state,
        updatingIds: [...state.updatingIds, action.commentId]
      })
    case COMMENT_TOGGLE_STARRED_SUCCESS:
      return Object.freeze({
        ...state,
        comments: replaceComment(state.comments, action.comment),
        updatingIds: removeFromUpdating(state.updatingIds, action.comment.id)
      })
    case COMMENT_POST_MODERATE_ERROR:
      return Object.freeze({
        ...state,
        updatingIds: removeFromUpdating(state.updatingIds, action.commentId)
      })
    default:
      return state
  }
}

function addNewComments(oldComments, newComments, offset) {
  return offset === 0 ? newComments : [...oldComments, ...newComments]
}

function replaceComment(comments, newComment) {
  const c = cloneDeep(comments)
  const idx = c.findIndex(comment => comment.id === newComment.id)
  c[idx] = newComment
  return c
}

function removeFromUpdating(updatingIds, id) {
  const c = cloneDeep(updatingIds)
  const idx = c.indexOf(id)
  c.splice(idx, 1)
  return c
}
