import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import { bool, shape, string } from 'prop-types'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  buttonText: {
    paddingLeft: theme.spacing(0.6)
  }
}))

export function ButtonWithSpinner({ type, text, isUpdating, ...props }) {
  const classes = useStyles()

  return (
    <Button type={type} color="primary" disabled={isUpdating} {...props}>
      <>
        {isUpdating && <CircularProgress size={18} />}
        <div className={classes.buttonText}>{text}</div>
      </>
    </Button>
  )
}

ButtonWithSpinner.defaultProps = {
  type: 'button',
  isUpdating: false,
  props: {}
}

ButtonWithSpinner.propTypes = {
  type: string,
  isUpdating: bool,
  text: string.isRequired,
  props: shape({})
}
