import React from 'react'
import { func, string, bool } from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'

const PaddedButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1)
}))

export function PollActionButton({ text, action, isUpdating }) {
  return (
    <PaddedButton
      startIcon={isUpdating ? <CircularProgress size={12} /> : null}
      disabled={isUpdating}
      variant="contained"
      onClick={() => action()}
    >
      {text}
    </PaddedButton>
  )
}

PollActionButton.defaultProps = {
  isUpdating: false
}

PollActionButton.propTypes = {
  action: func.isRequired,
  text: string.isRequired,
  isUpdating: bool
}
