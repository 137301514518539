export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const INITIALIZE_USER = 'INITIALIZE_USER'

export const CHAT_REQUEST = 'CHAT_REQUEST'
export const CHAT_SUCCESS = 'CHAT_SUCCESS'
export const CHAT_ERROR = 'CHAT_ERROR'

export const CHAT_STATS_REQUEST = 'CHAT_STATS_REQUEST'
export const CHAT_STATS_SUCCESS = 'CHAT_STATS_SUCCESS'
export const CHAT_STATS_ERROR = 'CHAT_STATS_ERROR'

export const CHATS_REQUEST = 'CHATS_REQUEST'
export const CHATS_SUCCESS = 'CHATS_SUCCESS'
export const CHATS_ERROR = 'CHATS_ERROR'

export const CHAT_CREATE_REQUEST = 'CHAT_CREATE_REQUEST'
export const CHAT_UPDATE_REQUEST = 'CHAT_UPDATE_REQUEST'
export const CHAT_UPDATE_SUCCESS = 'CHAT_UPDATE_SUCCESS'
export const CHAT_UPDATE_ERROR = 'CHAT_UPDATE_ERROR'

export const CHAT_ARCHIVE_REQUEST = 'CHAT_ARCHIVE_REQUEST'
export const CHAT_ARCHIVE_SUCCESS = 'CHAT_ARCHIVE_SUCCESS'
export const CHAT_ARCHIVE_ERROR = 'CHAT_ARCHIVE_ERROR'

export const CHAT_ADD_WRITER = 'CHAT_ADD_WRITER'
export const CHAT_REMOVE_WRITER = 'CHAT_REMOVE_WRITER'
export const CHAT_RESET_WRITERS = 'CHAT_RESET_WRITERS'

export const CHAT_TAINT = 'CHAT_TAINT'
export const CHAT_UNTAINT = 'CHAT_UNTAINT'

export const USER_ERROR = 'USER_ERROR'

export const POLLS_REQUEST = 'POLLS_REQUEST'
export const POLLS_SUCCESS = 'POLLS_SUCCESS'

export const POLL_CREATE_REQUEST = 'POLL_CREATE_REQUEST'
export const POLL_UPDATE_REQUEST = 'POLL_UPDATE_REQUEST'
export const POLL_CREATE_UPDATE_SUCCESS = 'POLL_CREATE_UPDATE_SUCCESS'

export const POLL_OPEN_REQUEST = 'POLL_OPEN_REQUEST'
export const POLL_CLOSE_REQUEST = 'POLL_CLOSE_REQUEST'
export const POLL_OPEN_SUCCESS = 'POLL_OPEN_SUCCESS'
export const POLL_CLOSE_SUCCESS = 'POLL_CLOSE_SUCCESS'

export const POLL_STATS_REQUEST = 'POLL_STATS_REQUEST'
export const POLL_STATS_SUCCESS = 'POLL_STATS_SUCCESS'

export const POLL_RESULTS_REQUEST = 'POLL_RESULTS_REQUEST'
export const POLL_RESULTS_SUCCESS = 'POLL_RESULTS_SUCCESS'

export const POLL_PUBLISH_RESULTS_REQUEST = 'POLL_PUBLISH_RESULTS_REQUEST'

export const POLLS_ERROR = 'POLLS_ERROR'

export const ENQUEUE_ERROR_SNACKBAR = 'ENQUEUE_ERROR_SNACKBAR'
export const ENQUEUE_SUCCESS_SNACKBAR = 'ENQUEUE_SUCCESS_SNACKBAR'

export const MODERATE_START = 'MODERATE_START'
export const MODERATE_STOP = 'MODERATE_STOP'
export const MODERATE_GET_SUCCESS = 'MODERATE_GET_SUCCESS'
export const MODERATE_HANDLE_REQUEST = 'MODERATE_HANDLE_REQUEST'
export const MODERATE_HANDLE_SUCCESS = 'MODERATE_HANDLE_SUCCESS'
export const MODERATE_HANDLE_BATCH_REQUEST = 'MODERATE_HANDLE_BATCH_REQUEST'
export const MODERATE_HANDLE_BATCH_SUCCESS = 'MODERATE_HANDLE_BATCH_SUCCESS'
export const MODERATE_START_STATE_POLLING = 'MODERATE_START_STATE_POLLING'
export const MODERATE_STOP_STATE_POLLING = 'MODERATE_STOP_STATE_POLLING'
export const MODERATE_STATE_REQUEST = 'MODERATE_STATE_REQUEST'
export const MODERATE_STATE_SUCCESS = 'MODERATE_STATE_SUCCESS'
export const MODERATE_BAN_USER = 'MODERATE_BAN_USER'
export const MODERATE_ERROR = 'MODERATE_ERROR'
export const MODERATE_RESET_MODERATION = 'MODERATE_RESET_MODERATION'
export const MODERATE_RESET_MODERATION_SUCCESS = 'MODERATE_RESET_MODERATION_SUCCESS'
export const MODERATE_RESET_MODERATION_ERROR = 'MODERATE_RESET_MODERATION_ERROR'
export const MODERATE_GET_COMMENTS = 'MODERATE_GET_COMMENTS'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'
export const ADD_USER_REQUEST = 'ADDUSERR_REQUEST'
export const ADD_USER_SUCCESS = 'ADDUSERR_SUCCESS'
export const ADD_USER_ERROR = 'ADDUSERR_ERROR'
export const REMOVE_USER_REQUEST = 'REMOVE_USER_REQUEST'
export const REMOVE_USER_SUCCESS = 'REMOVE_USER_SUCCESS'
export const REMOVE_USER_ERROR = 'REMOVE_USER_ERROR'
export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const EDIT_USER_ERROR = 'EDIT_USER_ERROR'

export const COMMENTS_GET_MODERATED_REQUEST = 'COMMENTS_GET_MODERATED_REQUEST'
export const COMMENTS_GET_MODERATED_SUCCESS = 'COMMENTS_GET_MODERATED_SUCCESS'
export const COMMENTS_GET_MODERATED_ERROR = 'COMMENTS_GET_MODERATED_ERROR'
export const COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON = 'COMMENTS_TOGGLE_ACCEPTED_RADIO_BUTTON'
export const COMMENT_POST_MODERATE_REQUEST = 'COMMENT_POST_MODERATE_REQUEST'
export const COMMENT_POST_MODERATE_SUCCESS = 'COMMENT_POST_MODERATE_SUCCESS'
export const COMMENT_TOGGLE_STARRED_REQUEST = 'COMMENT_TOGGLE_STARRED_REQUEST'
export const COMMENT_TOGGLE_STARRED_SUCCESS = 'COMMENT_TOGGLE_STARRED_SUCCESS'
export const COMMENT_POST_MODERATE_ERROR = 'COMMENT_POST_MODERATE_ERROR'

export const IMAGE_ERROR = 'IMAGE_ERROR'

export const BANNED_USERS_REQUEST = 'BANNED_USERS_REQUEST'
export const BANNED_USERS_SUCCESS = 'BANNED_USERS_SUCCESS'
export const BANNED_USERS_ERROR = 'BANNED_USERS_ERROR'
export const UNBAN_USER_REQUEST = 'UNBAN_USER_REQUEST'
export const UNBAN_USER_SUCCESS = 'UNBAN_USER_SUCCESS'
export const UNBAN_USER_ERROR = 'UNBAN_USER_ERROR'

export const EMOJI_REQUEST = 'EMOJI_REQUEST'
export const EMOJI_SUCCESS = 'EMOJI_SUCCESS'
export const EMOJI_ERROR = 'EMOJI_ERROR'
export const EMOJI_ADD = 'EMOJI_ADD'
