/* istanbul ignore file */

import { put, select, takeLatest } from 'redux-saga/effects'
import { configSelector } from '../selectors/config-selector'
import { POLL_STATS_REQUEST } from '../actions/action-types'
import { pollsErrorAction, pollStatsSuccess } from '../actions/polls-actions'
import { getPollStats } from '../api/backend-api'

export function* watchGetPollStats() {
  yield takeLatest([POLL_STATS_REQUEST], fetchPollStatsSaga)
}

function* fetchPollStatsSaga({ chatId }) {
  try {
    const config = yield select(configSelector)
    const resultsResponse = yield getPollStats(config, chatId)
    yield put(pollStatsSuccess({ chatId, resultsResponse }))
  } catch (error) {
    yield put(pollsErrorAction({ error }))
  }
}
