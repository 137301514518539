import {
  POLL_CLOSE_REQUEST,
  POLL_CLOSE_SUCCESS,
  POLL_CREATE_REQUEST,
  POLL_CREATE_UPDATE_SUCCESS,
  POLL_OPEN_REQUEST,
  POLL_OPEN_SUCCESS,
  POLL_RESULTS_REQUEST,
  POLL_RESULTS_SUCCESS,
  POLL_STATS_REQUEST,
  POLL_STATS_SUCCESS,
  POLL_UPDATE_REQUEST,
  POLLS_ERROR,
  POLLS_REQUEST,
  POLLS_SUCCESS,
  POLL_PUBLISH_RESULTS_REQUEST
} from './action-types'

export function pollsRequestAction({ chatId, filter }) {
  return {
    type: POLLS_REQUEST,
    chatId,
    filter
  }
}

export function pollsSuccessAction({ chatId, polls }) {
  return {
    type: POLLS_SUCCESS,
    chatId,
    polls
  }
}

export function pollsErrorAction({ error }) {
  return {
    type: POLLS_ERROR,
    error
  }
}

export function pollCreateRequest({ poll, chatId }) {
  return {
    type: POLL_CREATE_REQUEST,
    poll,
    chatId
  }
}

export function pollUpdateRequest({ poll, chatId }) {
  return {
    type: POLL_UPDATE_REQUEST,
    poll,
    chatId
  }
}

export function pollCreateUpdateSuccess({ poll, chatId }) {
  return {
    type: POLL_CREATE_UPDATE_SUCCESS,
    poll,
    chatId
  }
}

export function pollOpenRequest({ poll, chatId }) {
  return {
    type: POLL_OPEN_REQUEST,
    poll,
    chatId
  }
}

export function pollCloseRequest({ poll, chatId }) {
  return {
    type: POLL_CLOSE_REQUEST,
    poll,
    chatId
  }
}

export function pollOpenSuccess({ chatId, pollId }) {
  return {
    type: POLL_OPEN_SUCCESS,
    chatId,
    pollId
  }
}

export function pollCloseSuccess({ chatId, pollId }) {
  return {
    type: POLL_CLOSE_SUCCESS,
    chatId,
    pollId
  }
}

export function pollStatsRequest({ chatId }) {
  return {
    type: POLL_STATS_REQUEST,
    chatId
  }
}

export function pollStatsSuccess({ chatId, resultsResponse }) {
  return {
    type: POLL_STATS_SUCCESS,
    chatId,
    resultsResponse
  }
}
export function pollResultsRequest({ chatId, pollId }) {
  return {
    type: POLL_RESULTS_REQUEST,
    chatId,
    pollId
  }
}

export function pollResultsSuccess({ chatId, resultsResponse }) {
  return {
    type: POLL_RESULTS_SUCCESS,
    chatId,
    resultsResponse
  }
}

export function pollPublishResultsRequest({ chatId, pollId }) {
  return {
    type: POLL_PUBLISH_RESULTS_REQUEST,
    chatId,
    pollId
  }
}
