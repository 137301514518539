import { put, select, takeLatest } from 'redux-saga/effects'
import { configSelector } from '../selectors/config-selector'
import { POLL_PUBLISH_RESULTS_REQUEST, POLL_RESULTS_REQUEST } from '../actions/action-types'
import { pollResultsSuccess, pollsErrorAction } from '../actions/polls-actions'
import { getPollResults, publishPollResults } from '../api/backend-api'
import {
  enqueueErrorSnackbarAction,
  enqueueSuccessSnackbarAction
} from '../actions/snackbar-actions'

export function* watchGetPollResults() {
  yield takeLatest([POLL_RESULTS_REQUEST], fetchPollResultsSaga)
}

function* fetchPollResultsSaga({ pollId, chatId }) {
  try {
    const config = yield select(configSelector)
    const resultsResponse = yield getPollResults(config, chatId, pollId)
    yield put(pollResultsSuccess({ chatId, resultsResponse }))
  } catch (error) {
    yield put(pollsErrorAction({ error }))
  }
}

export function* watchPublishPollResults() {
  yield takeLatest([POLL_PUBLISH_RESULTS_REQUEST], publishPollResultsSaga)
}

function* publishPollResultsSaga({ pollId, chatId }) {
  try {
    const config = yield select(configSelector)
    yield publishPollResults(config, chatId, pollId)
    yield put(enqueueSuccessSnackbarAction({ message: 'Poll published to chat' }))
  } catch (error) {
    yield put(enqueueErrorSnackbarAction({ error }))
  }
}
