/* istanbul ignore file */

import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension'

import rootReducer from './reducers/root-reducer'
import { rootSaga } from './sagas/root-saga'

function initStore() {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(rootReducer(), composeWithDevTools(applyMiddleware(sagaMiddleware)))
  return {
    ...store,
    runSaga: sagaMiddleware.run(rootSaga)
  }
}

export const store = initStore()
