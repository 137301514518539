import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { StarBorderRounded, StarRounded } from '@mui/icons-material'
import { func } from 'prop-types'
import { palette } from '../../styles/colors'
import { commentProps } from '../prop-types'

const useStyles = makeStyles(() => ({
  starred: {
    color: palette.yellow.normal
  },
  unstarred: {
    color: palette.grey.normal
  }
}))

export function FavoriteButton({ comment, checkIsStarred, onClick }) {
  const classes = useStyles()

  return (
    <IconButton title="Favorite" size="small" onClick={onClick}>
      {checkIsStarred(comment) ? (
        <StarRounded className={classes.starred} />
      ) : (
        <StarBorderRounded className={classes.unstarred} />
      )}
    </IconButton>
  )
}

FavoriteButton.propTypes = {
  comment: commentProps.isRequired,
  onClick: func.isRequired,
  checkIsStarred: func.isRequired
}
