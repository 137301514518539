import React, { useState } from 'react'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material'
import { get } from 'lodash'
import { makeStyles } from '@mui/styles'
import { shape } from 'prop-types'
import { useSelector } from 'react-redux'
import { FormSectionTitle } from './FormSectionTitle'
import { chatThemes } from '../../../constants/chat-settings-options'
import { emojisSelector } from '../../../selectors/emoji-selector'
import { ReactionSelect } from '../ReactionSelect'
import { chatProps } from '../../prop-types'
import { FullWidthContainer } from './FullWidthContainer'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  select: {
    marginBottom: theme.spacing(2)
  }
}))

export function AppearanceFields({ chat, validationErrors }) {
  const toolTipContent = (
    <>
      <b>Chat style: </b>
      Visual style of the chat
      <br />
      <b>Best messages view visible: </b>
      If checked, shows selected best messages in a separate chat tab
      <br />
      <b>Pick emojiset: </b>
      Which reaction emojis are available to users in the chat. Maximum is 5 emojis.
    </>
  )

  const classes = useStyles()
  const emojis = useSelector(emojisSelector)

  const [theme, setTheme] = useState(get(chat, 'settings.theme', chatThemes[0].id))
  const [bestView, setBestView] = useState(get(chat, 'settings.bestView', false))

  const onThemeChange = event => {
    setTheme(event.target.value)
  }

  const onBestViewChange = event => {
    setBestView(event.target.checked)
  }

  return (
    <>
      <FormSectionTitle text="Styling" tooltipContent={toolTipContent} />
      <FullWidthContainer>
        <FormControl className={classes.formControl} variant="filled">
          <InputLabel id="theme-select-label">Chat style</InputLabel>
          <Select
            defaultValue="yle-default"
            name="theme"
            className={classes.select}
            labelId="theme-select-label"
            value={theme}
            onChange={onThemeChange}
          >
            {chatThemes.map(chatTheme => (
              <MenuItem key={chatTheme.id} value={chatTheme.id}>
                {chatTheme.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FullWidthContainer>

      <FormControlLabel
        control={
          <Checkbox id="bestView" checked={bestView} onChange={onBestViewChange} color="primary" />
        }
        label="Best messages view visible"
      />

      {emojis != null && (
        <ReactionSelect chat={chat} emojis={emojis} validationErrors={validationErrors} />
      )}
    </>
  )
}

AppearanceFields.defaultProps = {
  validationErrors: {}
}

AppearanceFields.propTypes = {
  chat: chatProps.isRequired,
  validationErrors: shape({})
}
