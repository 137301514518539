import React, { useState, useEffect } from 'react'
import { styled } from '@mui/system'
import { bool, func, string } from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  TextField,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import { listUserProps, userProps } from '../prop-types'

const Label = styled(FormLabel)(({ theme }) => ({
  marginTop: theme.spacing(2)
}))

export function UserDialog({
  loggedInUser,
  user,
  isOpen,
  onClose,
  onConfirm,
  confirmText,
  isUpdating,
  title
}) {
  const [isSuperOperator, setIsSuperOperator] = useState(false)
  const [isOperator, setIsOperator] = useState(false)
  const [isModerator, setIsModerator] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailOk, setIsEmailOk] = useState(true)

  const onChangeEmail = value => {
    setEmail(value)
    setIsEmailOk(!value || value.endsWith('@yle.fi'))
  }

  const onConfirmDialog = () => {
    onConfirm({ email, isOperator, isModerator })
    setEmail('')
  }

  useEffect(() => {
    if (user) {
      ;[
        [user.isSuperOperator, setIsSuperOperator],
        [user.isOperator, setIsOperator],
        [user.isModerator, setIsModerator],
        [user.nick, setEmail]
      ].forEach(u => u[1](u[0]))
    } else {
      if (loggedInUser.isSuperOperator) {
        setIsOperator(true)
        setIsModerator(true)
      }
      if (loggedInUser.isOperator) {
        setIsModerator(true)
      }
    }
  }, [user])

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          variant="filled"
          value={email}
          onChange={e => onChangeEmail(e.target.value)}
          disabled={Boolean(user)}
          error={!isEmailOk}
          helperText={!isEmailOk ? 'Must end with @yle.fi' : ''}
        />
        <Label component="legend">Roles</Label>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isSuperOperator} disabled />}
            label="Super operator"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => setIsOperator(e.target.checked)}
                disabled={!loggedInUser.isSuperOperator}
                checked={isOperator}
              />
            }
            label="Operator"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={e => setIsModerator(e.target.checked)}
                disabled={!(loggedInUser.isSuperOperator || loggedInUser.isOperator)}
                checked={isModerator}
              />
            }
            label="Moderator"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {isUpdating && <CircularProgress size={20} />}
        <Button onClick={onClose} variant="text" disabled={isUpdating}>
          Cancel
        </Button>
        <Button
          disabled={
            isUpdating ||
            (user
              ? user.isOperator === isOperator && user.isModerator === isModerator
              : !(email && isEmailOk && (isOperator || isModerator)))
          }
          onClick={onConfirmDialog}
          variant="contained"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserDialog.propTypes = {
  loggedInUser: userProps.isRequired,
  user: listUserProps,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  isUpdating: bool.isRequired,
  title: string.isRequired,
  confirmText: string.isRequired
}

UserDialog.defaultProps = {
  user: null
}
