import React from 'react'
import { List, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { pollProps, pollResultProps } from '../prop-types'

const useHeaderStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    marginBottom: '8px'
  },
  grey: {
    color: 'rgba(0, 0, 0, 0.6)'
  }
})

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  fullWidth: {
    width: '512px',
    backgroundColor: theme.palette.primary.light,
    borderRadius: '12px',
    height: '8px'
  },
  meter: {
    borderRadius: '12px',
    height: '8px',
    backgroundColor: theme.palette.primary.main,
    position: 'absolute'
  }
}))

function PollResultHeader({ result }) {
  const classes = useHeaderStyles()
  return (
    <div className={classes.container}>
      <span>
        {result.option} <span className={classes.grey}>({result.count})</span>
      </span>
      <span>{`${result.percentage} %`}</span>
    </div>
  )
}

PollResultHeader.propTypes = {
  result: pollResultProps.isRequired
}

export function PollResultTable({ poll }) {
  const classes = useStyles()
  return (
    <List disablePadding>
      {poll.results &&
        poll.results.map(result => (
          <ListItem key={result.option} className={classes.row} sx={{ padding: '12px 0' }}>
            <PollResultHeader result={result} />
            <div className={classes.fullWidth}>
              <div
                className={classes.meter}
                style={{ width: Math.ceil(512 * (result.percentage / 100)) }}
              />
            </div>
          </ListItem>
        ))}
    </List>
  )
}

PollResultTable.propTypes = {
  poll: pollProps.isRequired
}
