import { take, takeLatest, call, put, select, fork } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import jwtDecode from 'jwt-decode'
import { INITIALIZE_USER, LOGOUT_REQUEST, LOGOUT_SUCCESS } from '../actions/action-types'
import { userErrorAction, userLoginSuccessAction, userLogoutAction } from '../actions/user-actions'
import { checkToken } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'
import { getToken, isExpired, removeToken, setToken } from '../utils/token'

function handleError(err) {
  let realError = err
  if (!(err instanceof Error)) {
    realError = new Error(err.reason || err.details)
    realError.error = err
    realError.reason = err.reason || err.details
  }
  return realError
}

export function* watchInitializeUser() {
  const action = yield take(INITIALIZE_USER)
  yield call(initializeUser, action)
}

export function* watchUserLogout() {
  yield takeLatest(LOGOUT_REQUEST, logoutUser)
}

function initGoogleAuth(config) {
  const tokenChannel = eventChannel(emitter => {
    window.google.accounts.id.initialize({
      client_id: config.googleClientId,
      auto_select: true,
      hd: 'yle.fi',
      callback: ({ credential: token }) => {
        emitter(token)
      }
    })

    return () => {}
  })
  return tokenChannel
}

function* loginListener(tokenChannel) {
  while (true) {
    const token = yield take(tokenChannel)
    yield call(authenticateUser, token)
  }
}

function* initializeUser() {
  const config = yield select(configSelector)
  const tokenChannel = initGoogleAuth(config)
  yield fork(loginListener, tokenChannel)
  const token = getToken()
  if (token && !isExpired(token)) {
    yield call(authenticateUser, token)
  } else {
    removeToken()
    window.google.accounts.id.prompt()
    yield put(userLogoutAction())
  }
}

function* authenticateUser(token) {
  try {
    const { sub, given_name: givenName, family_name: familyName, picture, email } = jwtDecode(token)
    const config = yield select(configSelector)
    const user = yield call(checkToken, config, token)
    setToken(token)
    yield put(
      userLoginSuccessAction({
        id: sub,
        firstName: givenName,
        lastName: familyName,
        email,
        photoUrl: picture,
        isModerator: user.isModerator,
        isOperator: user.isOperator,
        isSuperOperator: user.isSuperOperator
      })
    )
  } catch (err) {
    yield put(userErrorAction({ error: handleError(err) }))
    yield put(userLogoutAction())
  }
}

function* logoutUser() {
  window.google.accounts.id.disableAutoSelect()
  removeToken()
  yield put({
    type: LOGOUT_SUCCESS
  })
}
