import { get } from 'lodash'
import { POLL, SLIDER } from '../../constants/poll-settings-options'

const sliderTextMaxLength = 20
const textTooLongMessage = `Maximum length is ${sliderTextMaxLength} characters`

// Convert poll update form data from a submit event to the format expected by backend call
export function eventToPoll(event, pollId, maxOptions) {
  const poll = {
    id: pollId,
    title: event.currentTarget.title.value,
    pollType: eventToPollType(event)
  }

  if (poll.pollType === SLIDER) {
    poll.sliderOptions = eventToSliderOptions(event)
  } else {
    poll.options = eventToOptions(event, maxOptions)
  }

  return poll
}

export function validate(poll) {
  const validation = {}
  if (!poll.title) {
    validation.title = "Title can't be empty"
  }
  if (poll.pollType === POLL) {
    if (!poll.options || poll.options.length === 0) {
      validation.option_0 = 'Add at least one option'
    }
  } else {
    if (get(poll, 'sliderOptions.leftText.length') > sliderTextMaxLength) {
      validation.leftText = textTooLongMessage
    }
    if (get(poll, 'sliderOptions.rightText.length') > sliderTextMaxLength) {
      validation.rightText = textTooLongMessage
    }
  }
  return validation
}

function eventToPollType(event) {
  return event.currentTarget.typePoll.checked ? POLL : SLIDER
}

function eventToOptions(event, maxOptions) {
  return [...Array(maxOptions)]
    .map((_, i) => event.currentTarget[`option_${i}`].value)
    .filter(option => !!option)
}

function eventToSliderOptions(event) {
  return {
    leftText: event.currentTarget.leftText.value,
    emoji: event.currentTarget.emoji.value,
    rightText: event.currentTarget.rightText.value
  }
}

export function sliderResultsToBarChart(results, nBar) {
  return Object.entries(results).reduce((bins, [key, value]) => {
    const idx = Math.floor((parseInt(key, 10) - 1) / nBar)
    // eslint-disable-next-line no-param-reassign
    bins[idx] += value
    return bins
  }, new Array(nBar).fill(0))
}

export function barLabels(maxValue, nBar) {
  const step = Math.floor(maxValue / nBar)
  return Array(nBar)
    .fill(0)
    .map((_, idx) => idx * step)
}

export function countResults(results) {
  if (Array.isArray(results)) {
    return results.reduce((sum, result) => sum + result.count, 0)
  }
  return results.pollResults.count
}
