import React from 'react'
import { styled } from '@mui/system'
import { CircularProgress } from '@mui/material'

const SpinnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '4em'
})

export function CenteredSpinner() {
  return (
    <SpinnerContainer>
      <CircularProgress />
    </SpinnerContainer>
  )
}
