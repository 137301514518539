import * as actions from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case actions.GET_USERS_REQUEST:
      return Object.freeze({
        ...state,
        users: [],
        isLoading: true
      })
    case actions.GET_USERS_SUCCESS:
      return Object.freeze({
        ...state,
        isLoading: false,
        users: action.users
      })
    case actions.GET_USERS_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false,
        error: action.error
      })
    case actions.ADD_USER_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.ADD_USER_SUCCESS:
      return Object.freeze({
        ...state,
        users: [action.user, ...state.users],
        isUpdating: false
      })
    case actions.ADD_USER_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false,
        error: action.error
      })
    case actions.REMOVE_USER_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.REMOVE_USER_SUCCESS:
      return Object.freeze({
        ...state,
        users: state.users.filter(user => !action.ids.includes(user.id)),
        isUpdating: false
      })
    case actions.REMOVE_USER_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false,
        error: action.error
      })
    case actions.EDIT_USER_REQUEST:
      return Object.freeze({
        ...state,
        isUpdating: true
      })
    case actions.EDIT_USER_SUCCESS:
      return Object.freeze({
        ...state,
        isUpdating: false,
        users: state.users.map(u =>
          u.id === action.id
            ? { ...u, isOperator: action.isOperator, isModerator: action.isModerator }
            : u
        )
      })
    case actions.EDIT_USER_ERROR:
      return Object.freeze({
        ...state,
        isUpdating: false,
        error: action.error
      })
    default:
      return state
  }
}
