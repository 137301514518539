import React, { useEffect } from 'react'
import {
  Tab,
  Tabs,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { PollAddButton } from './PollAddButton'
import { PageInstructions } from '../PageInstructions'
import { PollTableRow } from './PollTableRow'
import {
  isLoadingSelector as isLoadingPollsSelector,
  isUpdatingSelector,
  pollsSelector
} from '../../selectors/polls-selector'
import { pollsRequestAction } from '../../actions/polls-actions'
import { CenteredSpinner } from '../CenteredSpinner'

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  pollTable: {
    marginTop: '16px'
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse'
  },
  headerText: {
    fontWeight: '500'
  },
  wrap: {
    flexWrap: 'wrap'
  }
}))

export function PollList({ chatId }) {
  const dispatch = useDispatch()
  const polls = useSelector(state => pollsSelector(state, chatId))
  const isLoadingPolls = useSelector(isLoadingPollsSelector)
  const isUpdating = useSelector(isUpdatingSelector)
  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(1)
  const [filter, setFilter] = React.useState('')

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    let selected
    switch (newValue) {
      case 2:
        selected = 'ongoing'
        break
      case 3:
        selected = 'ready'
        break
      default:
        selected = ''
    }
    setFilter(selected)
  }

  useEffect(() => {
    dispatch(pollsRequestAction({ chatId, filter }))
  }, [chatId, filter])

  if (isLoadingPolls) {
    return <CenteredSpinner />
  }

  return (
    <div className={classes.container}>
      <div className={classes.topBar}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          classes={{ flexContainer: classes.wrap }}
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="ALL POLLS" value={1} className={classes.tab} />
          <Tab label="ONGOING POLLS" value={2} className={classes.tab} />
          <Tab label="READY POLLS" value={3} className={classes.tab} />
        </Tabs>
        <PollAddButton
          dispatch={dispatch}
          chatId={chatId}
          isUpdating={isUpdating}
          pollsCount={polls.length}
        />
      </div>
      {polls.length === 0 ? (
        <PageInstructions text="No polls found with filter" />
      ) : (
        <TableContainer className={classes.pollTable}>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell className={classes.headerText}>Poll title</TableCell>
                <TableCell className={classes.headerText}>Id</TableCell>
                <TableCell className={classes.headerText}>Type</TableCell>
                <TableCell className={classes.headerText}>Status</TableCell>
                <TableCell align="center" className={classes.headerText}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {polls.map(poll => (
                <PollTableRow
                  key={poll.id}
                  dispatch={dispatch}
                  poll={poll}
                  chatId={chatId}
                  isUpdating={isUpdating}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  )
}

PollList.propTypes = {
  chatId: string.isRequired
}
