import React from 'react'
import { Box, Typography } from '@mui/material'
import { object, string } from 'prop-types'
import { TooltipIcon } from '../../TooltipIcon'

export function FormSectionTitle({ text, tooltipContent }) {
  return (
    <Box
      sx={{
        paddingBottom: 1,
        paddingTop: 2,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Typography
        sx={{
          flexGrow: 1,
          textTransform: 'none',
          fontSize: '16px'
        }}
        variant="body2"
      >
        {text}
      </Typography>
      {tooltipContent && <TooltipIcon content={tooltipContent} />}
    </Box>
  )
}

FormSectionTitle.defaultProps = {
  tooltipContent: undefined
}

FormSectionTitle.propTypes = {
  text: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipContent: object
}
