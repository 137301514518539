import { put, takeLatest } from 'redux-saga/effects'
import { enqueueErrorSnackbarAction } from '../actions/snackbar-actions'

export function* watchNotifyError() {
  yield takeLatest(action => action.type.endsWith('ERROR'), notifyErrorSaga)
}

function* notifyErrorSaga({ error }) {
  yield put(enqueueErrorSnackbarAction({ error }))
}
