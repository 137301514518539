import * as types from '../actions/action-types'

export default function (state, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return Object.freeze({
        user: { ...action.user, loggedIn: true },
        isLoading: false
      })
    case types.LOGOUT_SUCCESS:
      return Object.freeze({
        user: { loggedIn: false },
        isLoading: false
      })
    case types.USER_ERROR:
      return Object.freeze({
        ...state,
        isLoading: false
      })
    default:
      return state
  }
}
