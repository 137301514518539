import { put, select, takeLatest } from 'redux-saga/effects'
import { EMOJI_REQUEST } from '../actions/action-types'
import { emojiSuccessAction, emojiErrorAction } from '../actions/emoji-actions'
import { getAllEmojis } from '../api/backend-api'
import { configSelector } from '../selectors/config-selector'

export function* watchGetEmojis() {
  yield takeLatest(EMOJI_REQUEST, getEmojisSaga)
}

function* getEmojisSaga() {
  try {
    const config = yield select(configSelector)
    const emojis = yield getAllEmojis(config)
    yield put(emojiSuccessAction({ emojis }))
  } catch (error) {
    yield put(emojiErrorAction({ error }))
  }
}
