import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { barLabels, sliderResultsToBarChart } from './utils'
import { pollProps } from '../prop-types'

ChartJS.register(CategoryScale, LinearScale, BarElement)

const BAR_COUNT = 10
const MAX_VALUE = 100

const useStyles = makeStyles(() => ({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  leftLabel: {
    flexGrow: 1
  }
}))

export function BarChart({ poll }) {
  const classes = useStyles()
  const muiTheme = useTheme()

  const options = {
    legend: { display: false }
  }

  const data = {
    labels: barLabels(MAX_VALUE, BAR_COUNT),
    datasets: [
      {
        data: sliderResultsToBarChart(poll.results.pollResults.results, BAR_COUNT),
        backgroundColor: muiTheme.palette.primary.light,
        borderColor: muiTheme.palette.primary.dark,
        hoverBackgroundColor: muiTheme.palette.primary.main,
        hoverBorderColor: muiTheme.palette.primary.dark
      }
    ]
  }

  return (
    <>
      <Bar data={data} options={options} />
      <div className={classes.labelContainer}>
        <Typography variant="caption" className={classes.leftLabel}>
          {poll.sliderOptions.leftText}
        </Typography>
        <Typography variant="caption">{poll.sliderOptions.rightText}</Typography>
      </div>
    </>
  )
}

BarChart.propTypes = {
  poll: pollProps.isRequired
}
