import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@mui/styles'
import { TableCell, TableRow, Typography } from '@mui/material'
import { bool, func, string } from 'prop-types'
import { pollProps } from '../prop-types'
import { PollEditDialog } from './PollEditDialog'
import { pollCloseRequest, pollOpenRequest, pollUpdateRequest } from '../../actions/polls-actions'
import { PollResultsDialog } from './PollResultDialog'
import { palette } from '../../styles/colors'
import { PollActionButton } from './PollActionButton'

const useStyles = makeStyles(() => ({
  row: {
    border: '1px'
  },
  titleText: {
    flexGrow: 0
  }
}))

export function PollTableRow({ poll, dispatch, isUpdating, chatId }) {
  const classes = useStyles()
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isResultsDialogOpen, setIsResultsDialogOpen] = useState(false)
  const [isSpinner, setIsSpinner] = useState(false)

  useEffect(() => {
    if (!isUpdating) {
      setIsSpinner(false)
    }
  }, [isUpdating])

  const onEditSubmit = newPoll => {
    dispatch(pollUpdateRequest({ pollId: poll.id, poll: newPoll, chatId }))
  }

  const onOpenClick = () => {
    setIsSpinner(true)
    dispatch(pollOpenRequest({ poll, chatId }))
  }

  const onCloseClick = () => {
    setIsSpinner(true)
    dispatch(pollCloseRequest({ poll, chatId }))
  }

  const getPollStatus = () => {
    let color
    let text
    if (!poll.isOpened && !poll.isClosed) {
      color = palette.grey.dark
      text = 'Draft'
    } else if (poll.isOpened && !poll.isClosed) {
      color = '#FF9800'
      text = 'Ongoing'
    } else if (poll.isOpened && poll.isClosed) {
      color = palette.green.dark
      text = 'Ready'
    }
    const CustomTypography = withStyles({
      root: {
        color
      }
    })(Typography)

    return <CustomTypography variant="body1">{text}</CustomTypography>
  }

  return (
    <TableRow className={classes.row}>
      <TableCell>{poll.title}</TableCell>
      <TableCell>{poll.id}</TableCell>
      <TableCell>{poll.pollType}</TableCell>
      <TableCell>{getPollStatus()}</TableCell>
      <TableCell align="center">
        {!poll.isClosed && !poll.isOpened && (
          <PollActionButton action={() => onOpenClick()} text="Open" isUpdating={isSpinner} />
        )}
        {poll.isOpened && !poll.isClosed && (
          <PollActionButton text="Close" action={onCloseClick} isUpdating={isSpinner} />
        )}
        {poll.isOpened && (
          <PollActionButton action={() => setIsResultsDialogOpen(true)} text="Results" />
        )}
      </TableCell>
      <PollEditDialog
        handleSubmit={onEditSubmit}
        handleClose={() => setIsEditDialogOpen(false)}
        isOpen={isEditDialogOpen}
        isUpdating={isUpdating}
        poll={poll}
      />
      <PollResultsDialog
        chatId={chatId}
        handleClose={() => setIsResultsDialogOpen(false)}
        isOpen={isResultsDialogOpen}
        poll={poll}
        dispatch={dispatch}
        isUpdating={isUpdating}
      />
    </TableRow>
  )
}

PollTableRow.propTypes = {
  poll: pollProps.isRequired,
  dispatch: func.isRequired,
  isUpdating: bool.isRequired,
  chatId: string.isRequired
}
