import React from 'react'
import { makeStyles } from '@mui/styles'
import { string } from 'prop-types'
import { Divider, Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1)
  }
}))

export function GridItemTitle({ text }) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.title}>
        <Typography variant="subtitle1">{text}</Typography>
      </div>
      <Divider />
    </>
  )
}

GridItemTitle.propTypes = {
  text: string.isRequired
}
