import React, { useState } from 'react'
import { InputAdornment, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { Search } from '@mui/icons-material'
import { func, number, shape, string } from 'prop-types'

const SearchTextField = styled(TextField)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginTop: theme.spacing(0.5)
}))

export function ChatSearchBar({ params, setParams }) {
  const [searchTerm, setSearchTerm] = useState('')

  const search = () => {
    setParams({
      ...params,
      searchTerm,
      offset: 0,
      limit: params.limit
    })
  }

  const onEnterPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      search()
    }
  }
  return (
    <>
      <form>
        <SearchTextField
          id="search_term"
          placeholder="Search"
          value={searchTerm}
          autoFocus
          onChange={event => setSearchTerm(event.target.value)}
          onKeyPress={onEnterPress}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      </form>
    </>
  )
}

ChatSearchBar.propTypes = {
  setParams: func.isRequired,
  params: shape({
    searchTerm: string,
    offset: number,
    limit: number
  }).isRequired
}
