import { withStyles } from '@mui/styles'
import { Tooltip } from '@mui/material'
import { HelpOutline } from '@mui/icons-material'
import React from 'react'
import { object } from 'prop-types'
import { WHITE } from '../styles/colors'

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: WHITE,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    border: '1px solid'
  }
}))(Tooltip)

const HelpIcon = withStyles(theme => {
  theme.spacing(0.8)
})(HelpOutline)

export function TooltipIcon({ content }) {
  return (
    <LightTooltip title={content}>
      <HelpIcon />
    </LightTooltip>
  )
}

TooltipIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  content: object.isRequired
}
