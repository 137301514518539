import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Add } from '@mui/icons-material'
import { ImsImage } from './ImsImage'
import { useImsPicker } from './ImsPicker'
import { imageProps } from '../../prop-types'
import { ChatFormButton } from '../FieldSets/ChatFormButton'

const ImsContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2)
}))

export function ChatImageImsPicker({ image }) {
  const [currentImage, setCurrentImage] = useState({
    id: image && image.id,
    alt: image && image.alt,
    blurhash: image && image.blurhash
  })
  const { dialog, openPicker } = useImsPicker(setCurrentImage)

  return (
    <ImsContainer>
      {currentImage.id ? (
        <ImsImage
          imageId={currentImage.id}
          altTexts={currentImage.alt}
          onDelete={() => setCurrentImage({ id: null, alt: null, blurhash: null })}
        />
      ) : (
        <ChatFormButton variant="contained" onClick={openPicker} startIcon={<Add />}>
          Add cover image from IMS
        </ChatFormButton>
      )}
      <input type="hidden" id="image" value={JSON.stringify(currentImage)} />
      {dialog}
    </ImsContainer>
  )
}

ChatImageImsPicker.defaultProps = {
  image: null
}

ChatImageImsPicker.propTypes = {
  image: imageProps
}
