import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material'
import { node } from 'prop-types'
import { useSelector } from 'react-redux'
import { palette } from '../styles/colors'
import { configSelector } from '../selectors/config-selector'

export function MuiThemeProvider({ children }) {
  const { isProd } = useSelector(configSelector)
  const primaryColor = isProd ? palette.purple : palette.green

  const theme = createTheme({
    drawer: {
      width: 200
    },
    palette: {
      primary: convertYleColorToMuiColor(primaryColor),
      secondary: convertYleColorToMuiColor(palette.turquoise),
      error: convertYleColorToMuiColor(palette.red),
      warning: convertYleColorToMuiColor(palette.yellow),
      info: convertYleColorToMuiColor(palette.blue),
      success: convertYleColorToMuiColor(palette.green)
    },
    typography: {
      fontFamily: ['Roboto', 'sans-serif']
    }
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

MuiThemeProvider.propTypes = {
  children: node.isRequired
}

function convertYleColorToMuiColor(color) {
  return {
    light: color.lighter,
    main: color.normal,
    dark: color.darker,
    contrastText: color.contrast
  }
}
