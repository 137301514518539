import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { chatsSelector, isLoadingSelector, isUpdatingSelector } from '../selectors/chats-selector'
import { Navigation } from '../components/Navigation/Navigation'
import { ChatList } from '../components/ChatList/ChatList'
import { isModeratorSelector, isOperatorSelector } from '../selectors/user-selector'
import { routes } from '../constants/routes'
import { configSelector } from '../selectors/config-selector'

export function ChatListPage() {
  const chats = useSelector(chatsSelector)
  const isLoading = useSelector(isLoadingSelector)
  const isUpdating = useSelector(isUpdatingSelector)
  const isOperator = useSelector(isOperatorSelector)
  const isModerator = useSelector(isModeratorSelector)
  const { imagesApiUrl, yleLogoId } = useSelector(configSelector)

  return isModerator ? (
    <Navigation titleText="Chat Admin Pro">
      <ChatList
        chats={chats}
        isLoading={isLoading}
        isUpdating={isUpdating}
        isOperator={isOperator}
        imagesApiUrl={imagesApiUrl}
        yleLogoId={yleLogoId}
      />
    </Navigation>
  ) : (
    <Redirect to={routes.users} />
  )
}
