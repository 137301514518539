/* istanbul ignore file */

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { number, shape, string } from 'prop-types'
import { Navigation } from '../components/Navigation/Navigation'
import { pollsRequestAction, pollStatsRequest, pollUpdateRequest } from '../actions/polls-actions'
import { pollsSelector, pollStatsSelector } from '../selectors/polls-selector'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '4em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    padding: theme.spacing(2),
    marginTop: '1em',
    minWidth: '14em'
  },
  countryRow: {
    marginTop: '1em'
  }
}))

export function PollStats({ poll }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const stats = useSelector(state => pollStatsSelector(state, poll.id))

  const toggleFilterStatus = countFinnishVotesOnly => {
    const updatedPoll = { ...poll, countFinnishVotesOnly }
    dispatch(pollUpdateRequest({ pollId: poll.id, poll: updatedPoll, chatId: poll.topicId }))
  }

  return (
    <div>
      <h2>{`${poll.id} ${poll.title}`}</h2>
      {stats && (
        <>
          <div className={classes.countryRow}>{`Suomi: ${stats.fi}`}</div>
          <div className={classes.countryRow}>{`Muut: ${stats.other}`}</div>
          <div className={classes.countryRow}>{`Yhteensä: ${stats.all}`}</div>
          <div className={classes.countryRow}>
            {stats.countFinnishVotesOnly ? 'Vain Suomen äänet lasketaan' : 'Kaikki äänet lasketaan'}
          </div>
        </>
      )}
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => toggleFilterStatus(true)}
        >
          Vain Suomen äänet
        </Button>
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => toggleFilterStatus(false)}
        >
          Kaikki äänet
        </Button>
      </div>
    </div>
  )
}

export function UmkPage() {
  const classes = useStyles()
  const { chatId } = useParams()
  const polls = useSelector(state => pollsSelector(state, chatId))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pollStatsRequest({ chatId }))
    dispatch(pollsRequestAction({ chatId }))
  }, [])

  return (
    <Navigation titleText="UMK 2021 tulokset">
      <div className={classes.container}>
        {polls.map(poll => (
          <PollStats key={poll.id} poll={poll} />
        ))}
      </div>
    </Navigation>
  )
}

PollStats.propTypes = {
  poll: shape({
    id: number,
    title: string,
    chatId: string
  }).isRequired
}
