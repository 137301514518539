import React, { useState } from 'react'
import { Avatar, IconButton, ListItem, MenuItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { string } from 'prop-types'
import { useSelector } from 'react-redux'
import { imageProps } from '../../prop-types'
import { configSelector } from '../../../selectors/config-selector'
import { buildAvatarUrl, buildMenuAvatarUrl } from './utils'
import { useImsPicker } from './ImsPicker'
import { PopupMenu } from '../../PopupMenu'

const useStyles = makeStyles({
  avatarButton: {
    padding: 0
  },
  menuImg: {
    width: 108,
    height: 108,
    borderRadius: 54
  }
})

export function AvatarImsPicker({ image, id, alt }) {
  const [currentImage, setCurrentImage] = useState(image)
  const classes = useStyles()
  const imageId = currentImage && currentImage.id
  const { imagesApiUrl } = useSelector(configSelector)
  const { dialog, openPicker } = useImsPicker(setCurrentImage)
  const unsavedMenuItems = [
    <MenuItem key="popupmenu-add" onClick={openPicker}>
      Add avatar
    </MenuItem>
  ]
  const savedMenuItems = [
    <MenuItem key="popupmenu-change" onClick={openPicker}>
      Change avatar
    </MenuItem>,
    <MenuItem key="popupmenu-delete" onClick={() => setCurrentImage(null)}>
      Delete avatar
    </MenuItem>,
    <ListItem key="popupmenu-img" dense>
      <img className={classes.menuImg} src={buildMenuAvatarUrl(imagesApiUrl, imageId)} alt={alt} />
    </ListItem>
  ]
  return (
    <>
      <PopupMenu id={`avatar-${id}-menu`} items={imageId ? savedMenuItems : unsavedMenuItems}>
        <IconButton classes={{ root: classes.avatarButton }}>
          {imageId ? (
            <Avatar alt={alt} src={buildAvatarUrl(imagesApiUrl, imageId)} />
          ) : (
            <Avatar>?</Avatar>
          )}
        </IconButton>
      </PopupMenu>
      <input type="hidden" id={`avatar-${id}`} value={imageId || ''} />
      {dialog}
    </>
  )
}

AvatarImsPicker.defaultProps = {
  image: null
}

AvatarImsPicker.propTypes = {
  image: imageProps,
  id: string.isRequired,
  alt: string.isRequired
}
