import * as types from './action-types'

export function userLoginSuccessAction(user) {
  return {
    type: types.LOGIN_SUCCESS,
    user
  }
}

export function userLogoutRequestAction() {
  return {
    type: types.LOGOUT_REQUEST
  }
}

export function userLogoutAction() {
  return {
    type: types.LOGOUT_SUCCESS
  }
}

export function userErrorAction({ error }) {
  return {
    type: types.USER_ERROR,
    error
  }
}

export function initializeUserAction() {
  return {
    type: types.INITIALIZE_USER
  }
}
