import { get } from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Box, FormControl, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { arrayOf, shape } from 'prop-types'
import { EmojiImsPicker } from './ImsPicker/EmojiImsPicker'
import { chatProps, emojiProps } from '../prop-types'
import { ErrorTypography } from '../ErrorTypography'
import { Emoji } from './FieldSets/Emoji'
import { configSelector } from '../../selectors/config-selector'
import { FormSectionTitle } from './FieldSets/FormSectionTitle'

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    overflowX: 'auto'
  },
  emojiContainer: {
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'repeat(6, 1fr)'
  },
  iconButton: {
    justifySelf: 'center'
  },
  selectedAvatar: {
    opacity: 1
  },
  unselectedAvatar: {
    opacity: 0.2
  }
}))

function reorder(list, start, end) {
  const result = [...list]
  const [item] = result.splice(start, 1)
  result.splice(end, 0, item)
  return result
}

export function ReactionSelect({ chat, emojis, validationErrors }) {
  const classes = useStyles()
  const { imagesApiUrl } = useSelector(configSelector)
  const [search, setSearch] = useState('')

  const emojiExists = id => emojis.find(e => e.id === id) !== undefined

  const [selected, setSelected] = useState(
    get(chat, 'settings.feedReactions', []).filter(emojiExists)
  )

  const toggleEmoji = emojiId => {
    setSelected(oldSelected => {
      if (oldSelected.includes(emojiId)) {
        return oldSelected.filter(id => id !== emojiId)
      }
      if (oldSelected.length < 5) {
        return [...oldSelected, emojiId]
      }
      return oldSelected
    })
  }

  const onClick = emojiId => event => {
    event.preventDefault()
    toggleEmoji(emojiId)
  }

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    setSelected(oldSelected => reorder(oldSelected, result.source.index, result.destination.index))
  }

  const searchFilter = emoji =>
    search === '' || emoji.name.toLowerCase().includes(search.toLowerCase())

  return (
    <FormControl error={!!validationErrors.feedReactions} className={classes.container} fullWidth>
      <FormSectionTitle text={`Pick emojiset (${selected.length}/5)`} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {droppableProvided => (
            <Box
              ref={droppableProvided.innerRef}
              sx={{ display: 'flex', gap: 1, py: 1 }}
              {...droppableProvided.droppableProps}
            >
              {selected.map((emojiId, index) => {
                const emoji = emojis.find(({ id }) => id === emojiId)
                return (
                  emoji && (
                    <Draggable key={emoji.id} draggableId={emoji.id} index={index}>
                      {draggableProvided => (
                        <Emoji
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          emoji={emoji}
                          imagesApiUrl={imagesApiUrl}
                          onDelete={() => toggleEmoji(emoji.id)}
                        />
                      )}
                    </Draggable>
                  )
                )
              })}
              {droppableProvided.placeholder}
              {[...Array(5 - selected.length)].map((_, i) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={`placeholder-${i}`}
                  sx={{
                    p: '2px',
                    width: '40px',
                    height: '40px',
                    borderRadius: '8px',
                    border: '2px dashed #979797'
                  }}
                />
              ))}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <TextField
        sx={{ mt: 1 }}
        label="Search all emoji"
        value={search}
        onChange={e => setSearch(e.currentTarget.value)}
      />
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, my: 2 }}>
        {emojis.filter(searchFilter).map(emoji => (
          <Emoji
            key={emoji.id}
            emoji={emoji}
            imagesApiUrl={imagesApiUrl}
            onClick={onClick(emoji.id)}
          />
        ))}
      </Box>
      <EmojiImsPicker selectEmoji={toggleEmoji} />
      <input type="text" id="feedReactions" value={selected} hidden readOnly />
      {!!validationErrors.feedReactions && (
        <ErrorTypography>{validationErrors.feedReactions}</ErrorTypography>
      )}
    </FormControl>
  )
}

ReactionSelect.defaultProps = {
  chat: {},
  validationErrors: {}
}

ReactionSelect.propTypes = {
  chat: chatProps,
  emojis: arrayOf(emojiProps).isRequired,
  validationErrors: shape({})
}
