export async function getImageMetadata(config, imageId) {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  }

  const queryParams = `app_id=${config.jsonStorageApiAppId}&app_key=${config.jsonStorageApiAppKey}`

  const url = `${config.jsonStorageApiUrl}/36-${imageId}?${queryParams}`

  const response = await fetch(url, options)

  if (response.status >= 400) {
    return {
      error: `Call to JSON storage API failed with code ${response.status}`
    }
  }

  const json = await response.json()

  const blurhash = json.data.additionalProperties.find(elem => elem.blurhash)
  const crop = json.data.crops.find(({ name }) => name === '1:1')
  return {
    alt: json.data.alt,
    version: json.data.version || '1',
    crop: crop && crop.coordinates,
    blurhash: blurhash ? blurhash.blurhash : ''
  }
}
