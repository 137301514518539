import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Add, Chat } from '@mui/icons-material'

import { makeStyles } from '@mui/styles'
import { arrayOf, bool, func, string } from 'prop-types'
import { TableHeaderCell } from '../TableHeaderCell'
import { commentProps } from '../prop-types'
import { PageInstructions } from '../PageInstructions'
import { ConfirmationModal } from './ConfirmationModal'
import { QuoteModal } from './QuoteModal'
import {
  getPostModerateCommentRequestAction,
  getToggleStarredCommentRequestAction
} from '../../actions/comments-actions'
import { starredLabel } from '../../constants/comment-labels'
import { FavoriteButton } from './FavoriteButton'
import { formatTimestamp } from '../../utils/date-utils'
import { moderateStateRequestAction } from '../../actions/moderate-actions'

import CopyToClipboard from './CopyToClipboard'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  loadMoreButton: {
    padding: theme.spacing(2)
  },
  quoteButton: {
    color: theme.palette.action
  },
  contentCell: {
    'overflow-wrap': 'anywhere'
  }
}))

export function CommentList({
  chatId,
  comments,
  isLoading,
  updatingIds,
  accepted,
  dispatch,
  onLoadMore
}) {
  const classes = useStyles()

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false)
  const [modalComment, setModalComment] = useState(null)
  const [quoteModalComment, setQuoteModalComment] = useState(null)

  const checkIsStarred = comment => comment.labels && comment.labels.includes(starredLabel)

  useEffect(() => {
    setIsConfirmationModalOpen(false)
  }, [updatingIds])

  const onOpenModal = comment => () => {
    setModalComment(comment)
    setIsConfirmationModalOpen(true)
  }

  const onOpenQuoteModal = comment => () => {
    setQuoteModalComment(comment)
    setIsQuoteModalOpen(true)
  }

  const onConfirmReject = () => {
    dispatch(
      getPostModerateCommentRequestAction({ chatId, commentId: modalComment.id, action: 'reject' })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  const onConfirmAccept = () => {
    dispatch(
      getPostModerateCommentRequestAction({ chatId, commentId: modalComment.id, action: 'accept' })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  const onStarClick = comment => () => {
    dispatch(
      getToggleStarredCommentRequestAction({
        chatId,
        commentId: comment.id,
        isStarred: !checkIsStarred(comment)
      })
    )
    setTimeout(() => dispatch(moderateStateRequestAction({ chatId })), 1500)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (!comments.length) {
    return <PageInstructions text="No comments found" />
  }

  const getAcceptOrRejectButton = comment => {
    if (accepted) {
      return (
        <Button variant="contained" color="primary" size="small" onClick={onOpenModal(comment)}>
          Reject
        </Button>
      )
    }
    return (
      <Button variant="contained" color="primary" size="small" onClick={onOpenModal(comment)}>
        Accept
      </Button>
    )
  }

  const getQuoteButton = comment => {
    return (
      <IconButton
        title="Show quote"
        size="small"
        className={classes.quoteButton}
        onClick={onOpenQuoteModal(comment)}
      >
        <Chat />
      </IconButton>
    )
  }

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Id</TableHeaderCell>
            <TableHeaderCell>Author</TableHeaderCell>
            <TableHeaderCell>Message time</TableHeaderCell>
            <TableHeaderCell>Moderated by</TableHeaderCell>
            <TableHeaderCell>Content</TableHeaderCell>
            <TableHeaderCell />
            {accepted && <TableHeaderCell />}
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.map(comment => (
            <TableRow key={comment.id}>
              <TableCell>
                <CopyToClipboard text={comment.id} />
              </TableCell>
              <TableCell>{comment.author}</TableCell>
              <TableCell>
                {accepted
                  ? formatTimestamp(comment.acceptedAt, { weekday: undefined, second: '2-digit' })
                  : formatTimestamp(comment.rejectedAt, { weekday: undefined, second: '2-digit' })}
              </TableCell>
              <TableCell>{comment.moderatedBy}</TableCell>
              <TableCell className={classes.contentCell}>{comment.content}</TableCell>
              <TableCell align="center">{comment.quote && getQuoteButton(comment)}</TableCell>
              {updatingIds.includes(comment.id) ? (
                <TableCell colSpan={2} align="center">
                  <CircularProgress size={20} />
                </TableCell>
              ) : (
                <>
                  {accepted && (
                    <TableCell align="center">
                      <FavoriteButton
                        comment={comment}
                        isUpdating={updatingIds.includes(comment.id)}
                        onClick={onStarClick(comment)}
                        checkIsStarred={checkIsStarred}
                      />
                    </TableCell>
                  )}
                  <TableCell align="center">{getAcceptOrRejectButton(comment)}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        className={classes.loadMoreButton}
        disabled={isLoading}
        color="primary"
        startIcon={<Add />}
        onClick={onLoadMore}
      >
        Load more
      </Button>
      <ConfirmationModal
        reject={accepted}
        isOpen={isConfirmationModalOpen}
        handleSubmit={accepted ? onConfirmReject : onConfirmAccept}
        handleClose={() => setIsConfirmationModalOpen(false)}
        comment={modalComment}
      />
      <QuoteModal
        isOpen={isQuoteModalOpen}
        handleClose={() => setIsQuoteModalOpen(false)}
        comment={quoteModalComment}
      />
    </TableContainer>
  )
}

CommentList.defaultProps = {
  comments: [],
  isLoading: false,
  updatingIds: []
}

CommentList.propTypes = {
  chatId: string.isRequired,
  comments: arrayOf(commentProps),
  isLoading: bool,
  updatingIds: arrayOf(string),
  accepted: bool.isRequired,
  dispatch: func.isRequired,
  onLoadMore: func.isRequired
}
