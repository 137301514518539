import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField
} from '@mui/material'
import { bool, func } from 'prop-types'
import { isEmpty } from 'lodash'
import { makeStyles } from '@mui/styles'
import { pollProps } from '../prop-types'
import { eventToPoll, validate } from './utils'
import { ButtonWithSpinner } from '../ButtonWithSpinner'
import { PollOptions } from './PollOptions'
import { SliderOptions } from './SliderOptions'
import { POLL, SLIDER } from '../../constants/poll-settings-options'

const MAX_OPTIONS = 7

const useStyles = makeStyles(theme => ({
  withPadding: {
    paddingBottom: theme.spacing(3)
  }
}))

export function PollEditDialog({ isOpen, handleClose, handleSubmit, poll, isUpdating }) {
  const classes = useStyles()

  const [validationErrors, setValidationErrors] = useState({})
  const [pollType, setIsPollType] = useState(poll.pollType || POLL)

  const submit = event => {
    event.preventDefault()
    const newPoll = eventToPoll(event, poll.id, MAX_OPTIONS)
    const validation = validate(newPoll)
    setValidationErrors(validation)
    if (isEmpty(validation)) {
      handleSubmit(newPoll)
    }
  }

  const isNew = !poll.id

  const handleTypeChange = event => {
    setIsPollType(event.target.value)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{isNew ? 'Add poll' : 'Edit poll'}</DialogTitle>
      <DialogContent>
        <form onSubmit={submit}>
          <FormControl component="fieldset" className={classes.withPadding}>
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup onChange={handleTypeChange} value={pollType}>
              <FormControlLabel
                control={<Radio color="primary" id="typePoll" />}
                label="Poll"
                value="poll"
              />
              <FormControlLabel
                control={<Radio color="primary" id="typeSlider" />}
                label="Slider"
                value="slider"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            className={classes.withPadding}
            autoFocus
            id="title"
            label="Title"
            fullWidth
            multiline
            defaultValue={poll.title}
            error={!!validationErrors.title}
            helperText={validationErrors.title}
          />
          {pollType === SLIDER ? (
            <SliderOptions poll={poll} validationErrors={validationErrors} />
          ) : (
            <PollOptions maxOptions={MAX_OPTIONS} validationErrors={validationErrors} poll={poll} />
          )}
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <ButtonWithSpinner text="Save" isUpdating={isUpdating} type="submit" />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

PollEditDialog.defaultProps = {
  poll: {}
}

PollEditDialog.propTypes = {
  isOpen: bool.isRequired,
  handleClose: func.isRequired,
  handleSubmit: func.isRequired,
  isUpdating: bool.isRequired,
  poll: pollProps
}
