import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import { Block, Check, StarBorder } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
  moderateAcceptCommentAction,
  moderateRejectCommentAction,
  moderateBanUserAction
} from '../../actions/moderate-actions'
import { commentProps } from '../prop-types'
import { ConfirmDialog, ConfirmDialogFields } from '../ConfirmDialog/ConfirmDialog'
import { RejectButton } from './RejectButton'
import { palette as ylePalette } from '../../styles/colors'
import { formatRelativeTimestamp } from '../../utils/date-utils'

const useStyles = makeStyles(({ palette, spacing }) => ({
  card: {
    marginBottom: spacing(1),
    width: '100%'
  },
  quote: {
    borderLeft: [[spacing(0.5), 'solid', palette.primary.main]],
    paddingLeft: spacing(2),
    marginBottom: spacing(1)
  },
  blockButton: {
    textTransform: 'none',
    paddingLeft: '11px'
  },
  starIcon: {
    color: ylePalette.green.dark,
    marginBottom: '3px'
  },
  authorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  authorText: {
    fontWeight: 600,
    paddingRight: '8px'
  },
  contentText: {
    'overflow-wrap': 'anywhere'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '1em',
    marginBottom: '1em',
    padding: '0px'
  },
  buttonIcon: {
    marginBottom: '3px'
  }
}))

export function Comment({ comment }) {
  const { author, content, createdAt, id, quote, topicId } = comment
  const dispatch = useDispatch()
  const classes = useStyles()
  const [dialogOpen, setDialogOpen] = useState(false)
  const accept = () => dispatch(moderateAcceptCommentAction({ chatId: topicId, commentId: id }))
  const reject = () => dispatch(moderateRejectCommentAction({ chatId: topicId, commentId: id }))
  const acceptAndStar = () =>
    dispatch(moderateAcceptCommentAction({ chatId: topicId, commentId: id, isStarred: true }))
  const openDialog = () => setDialogOpen(true)
  const onCancelDialog = () => {
    setDialogOpen(false)
  }
  const onConfirmDialog = ({ reason }) => {
    setDialogOpen(false)
    dispatch(moderateBanUserAction({ chatId: topicId, commentId: id, reason }))
  }

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent>
        {quote && (
          <div className={classes.quote}>
            <Typography variant="body1" className={classes.authorText}>
              {quote.author}
            </Typography>
            <Typography variant="body1" className={classes.contentText}>
              {quote.content}
            </Typography>
          </div>
        )}
        <div className={classes.authorContainer}>
          <Typography variant="body1" className={classes.authorText}>
            {author}
          </Typography>
          <Typography variant="body1">{formatRelativeTimestamp(createdAt)}</Typography>
          <Button
            variant="text"
            classes={{ root: classes.blockButton }}
            endIcon={<Block />}
            onClick={openDialog}
          >
            Ban user
          </Button>
        </div>
        <Typography variant="body1" className={classes.contentText}>
          {content}
        </Typography>
      </CardContent>
      <CardActions className={classes.buttonContainer}>
        <RejectButton text="Reject" buttonProps={{ onClick: reject }} />
        <Button
          variant="text"
          className="acceptAndStar"
          color="primary"
          onClick={acceptAndStar}
          startIcon={<StarBorder />}
          classes={{ startIcon: classes.starIcon }}
          style={{ fontSize: '16px', color: ylePalette.green.dark }}
        >
          Star
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Check />}
          onClick={accept}
          classes={{ startIcon: classes.buttonIcon }}
          style={{
            fontSize: '16px',
            marginHorizontal: '16px',
            backgroundColor: ylePalette.green.dark,
            color: ylePalette.white.normal
          }}
        >
          Accept
        </Button>
      </CardActions>
      <ConfirmDialog
        open={dialogOpen}
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
        title={`Ban user ${author}?`}
        confirmText="Ban user"
        cancelText="Cancel"
        fields={[ConfirmDialogFields.textArea({ title: 'Reason', key: 'reason', maxLength: 280 })]}
      />
    </Card>
  )
}

Comment.propTypes = {
  comment: commentProps.isRequired
}
