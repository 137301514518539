import { styled } from '@mui/system'
import { Button } from '@mui/material'
import { palette } from '../../../styles/colors'

export const ChatFormButton = styled(Button)({
  color: palette.blue.normal,
  backgroundColor: palette.blue.lighter,
  padding: '1em',
  height: '36px',
  '&:hover': {
    color: palette.blue.dark,
    backgroundColor: palette.blue.darker
  }
})
