import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { APP_LOCALE } from './localization'

export function dateToApiFormat(date) {
  const d = date.toISOString().split('.')[0]
  return `${d}+00:00`
}

/**
 * Example
 * input: { bestParameter: 3, myDate: new Date() }
 * output: { bestParameter: 3, myDate: '2022-03-31T11:10:12+00:00' }
 */
export function datesToApiFormat(params) {
  return Object.entries(params).reduce(
    (newParams, [param, value]) => ({
      ...newParams,
      [param]: value instanceof Date ? dateToApiFormat(value) : value
    }),
    {}
  )
}

export function formatTimestamp(date, formatOptions = {}) {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Europe/Helsinki',
    ...formatOptions
  }
  try {
    return date ? new Intl.DateTimeFormat(APP_LOCALE, options).format(parseISO(date)) : ''
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('This browser does not support Intl.DateTimeFormat options')
    // eslint-disable-next-line no-console
    console.error(e)
    return date
  }
}

/**
 * Format timestamp relatively, e.g. "23 seconds ago", "1 minutes ago", "3 days ago" etc.
 */
export function formatRelativeTimestamp(date) {
  try {
    // https://date-fns.org/v2.15.0/docs/formatDistanceToNowStrict
    return `${formatDistanceToNowStrict(parseISO(date))} ago`
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return date
  }
}
