/* istanbul ignore file */
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useSelector } from 'react-redux'
import { snackbarSelector } from '../../selectors/snackbar-selector'

/**
 * @return {null}
 */
export function Snackbar() {
  const { enqueueSnackbar } = useSnackbar()
  const snackbarData = useSelector(snackbarSelector)
  useEffect(() => {
    if (snackbarData.message) {
      enqueueSnackbar(snackbarData.message, snackbarData.options)
    }
  })
  return null
}
