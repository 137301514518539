import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  TableHead,
  TableCell,
  TableRow
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { chatProps } from '../prop-types'
import { ChatSearchBar } from './ChatSearchBar'
import { ChatTableRow } from './ChatTableRow'
import { ChatAddButton } from './ChatAddButton'
import { chatsRequestAction } from '../../actions/chats-actions'
import { buildImageUrl, parseAltText } from '../ChatEditor/ImsPicker/utils'

const useStyles = makeStyles(theme => ({
  utilsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap-reverse',
    width: '100%',
    paddingTop: theme.spacing(2)
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap-reverse'
  },
  loadMoreButton: {
    padding: theme.spacing(2)
  },
  tableHeader: {
    padding: '16px 16px 8px',
    borderRadius: '4px',
    color: theme.palette.secondary.light
  },
  headerText: {
    color: '#757575'
  },
  tab: {
    minWidth: '116px'
  },
  withBorder: {
    border: '1px'
  },
  wrap: {
    flexWrap: 'wrap'
  }
}))

export function ChatList({ chats, isLoading, isUpdating, isOperator, imagesApiUrl, yleLogoId }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [tabValue, setTabValue] = React.useState(1)

  const [params, setParams] = useState({
    filter: '',
    searchTerm: '',
    offset: 0,
    limit: 50
  })

  const handleChange = (event, newValue) => {
    setTabValue(newValue)
    let filter
    switch (newValue) {
      case 2:
        filter = 'ongoing'
        break
      case 3:
        filter = 'upcoming'
        break
      case 4:
        filter = 'closed'
        break
      default:
        filter = ''
    }
    setParams({
      ...params,
      filter
    })
  }

  const onLoadMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit
    })
  }

  useEffect(() => {
    dispatch(chatsRequestAction({ params }))
  }, [params])

  return (
    <div>
      <div className={classes.utilsContainer}>
        <Tabs value={tabValue} onChange={handleChange} classes={{ flexContainer: classes.wrap }}>
          <Tab label="ALL CHATS" value={1} className={classes.tab} />
          <Tab label="ONGOING" value={2} className={classes.tab} />
          <Tab label="COMING UP" value={3} className={classes.tab} />
          <Tab label="CLOSED" value={4} className={classes.tab} />
        </Tabs>
        <div className={classes.rightContainer}>
          <ChatSearchBar params={params} setParams={setParams} />
          {isOperator && (
            <ChatAddButton dispatch={dispatch} isUpdating={isUpdating} chatsCount={chats.length} />
          )}
        </div>
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <ChatTable
            chats={chats}
            dispatch={dispatch}
            isUpdating={isUpdating}
            isLoading={isLoading}
            onLoadMore={onLoadMore}
            limit={params.limit}
            imagesApiUrl={imagesApiUrl}
            yleLogoId={yleLogoId}
          />
        </>
      )}
    </div>
  )
}

function ChatTable({
  chats,
  isUpdating,
  isLoading,
  dispatch,
  onLoadMore,
  limit,
  imagesApiUrl,
  yleLogoId
}) {
  const classes = useStyles()

  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.headerText} align="left">
              Chat title
            </TableCell>
            <TableCell className={classes.headerText} align="left">
              Schedule
            </TableCell>
            <TableCell className={classes.headerText} align="left">
              Created by
            </TableCell>
            <TableCell className={classes.headerText} align="right">
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.withBorder}>
          {chats.map(chat => (
            <ChatTableRow
              key={chat.id}
              chat={chat}
              imageUrl={buildImageUrl(imagesApiUrl, chat.image ? chat.image.id : yleLogoId)}
              imageAlt={chat.image ? parseAltText(chat.image.alt) : 'Yle logo'}
              dispatch={dispatch}
              isUpdating={isUpdating}
            />
          ))}
        </TableBody>
      </Table>
      {chats.length >= limit && (
        <Button
          className={classes.loadMoreButton}
          disabled={isLoading}
          color="primary"
          startIcon={<Add />}
          onClick={onLoadMore}
        >
          Load more
        </Button>
      )}
    </TableContainer>
  )
}

ChatTable.propTypes = {
  chats: PropTypes.arrayOf(chatProps).isRequired,
  isUpdating: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  imagesApiUrl: PropTypes.string.isRequired,
  yleLogoId: PropTypes.string.isRequired
}

ChatList.defaultProps = {
  isLoading: false,
  isUpdating: false,
  isOperator: false,
  chats: [],
  imagesApiUrl: '',
  yleLogoId: ''
}

ChatList.propTypes = {
  isLoading: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isOperator: PropTypes.bool,
  chats: PropTypes.arrayOf(chatProps),
  imagesApiUrl: PropTypes.string,
  yleLogoId: PropTypes.string
}
