import React from 'react'
import { useDispatch } from 'react-redux'
import { string, bool, func } from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { moderateResetModerationAction } from '../../actions/moderate-actions'

export function ResetButton({ setIsModalOpen }) {
  return (
    <Button onClick={() => setIsModalOpen(true)} variant="outlined" color="primary">
      Reset all moderation queues
    </Button>
  )
}

ResetButton.propTypes = {
  setIsModalOpen: func.isRequired
}

export function ResetDialog({ isModalOpen, setIsModalOpen, chatId }) {
  const dispatch = useDispatch()

  const returnAllComments = () => {
    dispatch(moderateResetModerationAction({ chatId }))
    setIsModalOpen(false)
  }

  return (
    <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <DialogTitle>Reset moderation?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Warning: This will return all unmoderated comments to the queue and remove all active
          moderators. This is intended for use when comments have been lost for some reason.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setIsModalOpen(false)}>
          Cancel
        </Button>
        <Button color="primary" onClick={returnAllComments}>
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ResetDialog.propTypes = {
  isModalOpen: bool.isRequired,
  chatId: string.isRequired,
  setIsModalOpen: func.isRequired
}
